import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import { LOGIN_USER } from "../service/types";
import { loadingStart, loadingStop } from "./loader";

export const loginUser = (data) => (dispatch) => {
  dispatch(loadingStart());
  localStorage.clear();
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.login(),
      data,
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        dispatch({
          type: LOGIN_USER,
          payload: response.data,
        });
        resolve(response && response.data && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const socialLoginUser = (data, navigate) => (dispatch) => {
  dispatch(loadingStart());
  localStorage.clear();
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.auth.socialLogin(),
      data: data,
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        dispatch({
          type: LOGIN_USER,
          payload: response.data,
        });
        localStorage.setItem("pms_login", true);
        navigate("/dashboard");
        resolve(response && response.data && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
