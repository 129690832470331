import { Col, Row } from "antd";
import React from "react";
import { When } from "../../../components/common";
import "./SectionView.scss";

function SectionView({
  data = [],
  title,
  separateView = false,
  horizontal = true,
  vertical = false,
  childList = [],
}) {
  return (
    <>
      <When condition={title}>
        <div>
          <div className="titleView bg-whi-2 border-radius-top">{title}</div>
        </div>
      </When>
      <div className=" bg-whi-2 pb-3 mb-3 border-radius-btm">
        <div className="row tab-brd">
          <Row>
            {data?.map((item) => {
              if (item?.view === false) {
                return <></>;
              }
              return (
                <>
                  <When condition={!separateView}>
                    <When condition={horizontal}>
                      <Col span={item?.span}>
                        <div className="phoneTitle">
                          <b>{item?.label} :</b> {item?.value}
                        </div>
                      </Col>
                    </When>
                  </When>
                  <When condition={separateView}>
                    <Col span={12}>
                      <div className="phoneTitle">
                        <b>{item?.label} :</b>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="phoneTitle">{item?.value}</div>
                    </Col>
                  </When>
                </>
              );
            })}
          </Row>
          {childList?.map((el, i) => {
            return (
              <Row key={i}>
                {data?.map((item) => {
                  if (item?.view === false) {
                    return <></>;
                  }
                  return (
                    <Col span={item.span}>
                      <p style={{ fontWeight: "normal" }}>{el[item?.key]}</p>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SectionView;
