import moment from "moment";
import { useEffect, useState } from "react";
import "./ChatPage.scss";

export default function ChatPage({ remarksArray = [], width = "60%" }) {
  useEffect(() => {
    const sortedMessagesList =
      (remarksArray &&
        remarksArray?.length > 0 &&
        remarksArray?.sort((a, b) => new Date(a.date) - new Date(b.date))) ||
      [];
    setSortedMessages(sortedMessagesList);
  }, [remarksArray]);
  const [sortedMessages, setSortedMessages] = useState([]);

  return (
    <div className="chat-box" style={{ width: width }}>
      {sortedMessages &&
        sortedMessages?.length > 0 &&
        sortedMessages?.map((message, index) => (
          <div
            key={index}
            className="chat-message"
            style={{
              margin:
                sortedMessages &&
                sortedMessages[index > 0 ? index - 1 : -1]?.person ===
                  message?.type
                  ? `0px 0 10px 0`
                  : `6px 0`,
            }}
          >
            <span className="chat-person">
              <strong>
                {sortedMessages &&
                sortedMessages[index > 0 ? index - 1 : -1]?.type ===
                  message?.type ? (
                  ""
                ) : (
                  <>
                    {message?.type}
                    {/* <span style={{ color: "#000" }}>:{message?.name}</span> */}
                  </>
                )}
              </strong>
            </span>
            <p>
              <i>Remarks:</i> {message?.remarks}
            </p>
            <div className="message-date">
              {moment(message?.date).format("DD-MM-yyyy  h:mma")}
            </div>
          </div>
        ))}
    </div>
  );
}
