import { Button, Col, Form, notification, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputNumber, Select, When } from "../../components/common";
import { ROLE_TYPE, sources } from "../../constants";
import {
  getProjectPreloadData,
  updateProjectPartial,
} from "../../redux/action/projectForm";
import { renderAmount, roleIdFind } from "../../utils/commonFunction";
import { requiredRule } from "../../utils/validator";
const ReviseAmount = ({
  data,
  viewFormHandler,
  handleCancel,
  totalProjectCost,
  totalDepartmentCost,
  reviseAmount,
}) => {
  const [qardan, setqardan] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const editId = data?.id;
  const dispatch = useDispatch();
  const funding_sources_watch = Form.useWatch("funding_sources", reviseAmount);
  const qardan_financial_watch = Form.useWatch(
    "qardan_financial",
    reviseAmount
  );
  const isDepartment = roleIdFind(ROLE_TYPE.DEPARTMENT);
  const isUmmal = roleIdFind(ROLE_TYPE.UMMAL);
  const isBoardA = roleIdFind(ROLE_TYPE.BOARD_A);
  const isBoardB = roleIdFind(ROLE_TYPE.BOARD_B);
  const isBoard = isBoardA || isBoardB;
  const currency =data?.jamaat?.currency
  const isDepartmentCreated = data?.is_department_created;

  const rankingList = useSelector(
    (state) =>
      state &&
      state.projectPreDataReducer &&
      state.projectPreDataReducer.projectPreData &&
      state.projectPreDataReducer.projectPreData.rankings
  );

  const updatePartialHandler = (data, id) => {
    dispatch(updateProjectPartial(data, id))
      .then((res) => {
        openNotificationWithIcon("success", res?.messageText);
        viewFormHandler(id);
        handleCancel();
      })
      .catch(() => {
        openNotificationWithIcon("error", "Error");
      });
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description,
    });
  };

  const FundingHandler = (value) => {
    const funding_sources = {};
    Object.keys(value.funding_sources)?.map((el) => {
      const resource = value.funding_sources[el];
      const obj = isDepartment
        ? resource?.department_amount ?? ""
        : resource?.board_amount ?? "";
      funding_sources[el] = obj;
    });
    return funding_sources;
  };

  const QardanHandler = (value) => {
    const qardan_hasanah = {};
    Object.keys(value.qardan_financial).map((el) => {
      const resource = value.qardan_financial[el];
      const obj = isDepartment
        ? resource?.department_amount ?? ""
        : resource?.board_amount ?? "";
      qardan_hasanah[el] = obj;
    });
    return qardan_hasanah;
  };

  const rankingListOptions =
    rankingList &&
    rankingList?.map(({ id }) => {
      return {
        label: id,
        value: id,
      };
    });
  const evaluation = (value) => {
    const res = Object.values(value.evaluation);
    let CurrentValue = 0;
    res.forEach((el) => {
      CurrentValue = el;
    });
    return CurrentValue;
  };

  const onFinalSubmit = (value) => {
    const financialData = {
      funding_sources: FundingHandler(value),
      qardan_financial: qardan ? QardanHandler(value) : {},
      evaluation_rating: isDepartment ? evaluation(value) : {},
    };
    updatePartialHandler(financialData, editId);
    return;
  };
  const totalProjectCostHandler = useCallback(
    (key) => {
      return (
        ((funding_sources_watch &&
          Object.values(funding_sources_watch).reduce(
            (accumulator = 0, curValue) => {
              return accumulator + Number(curValue[key] ?? 0);
            },
            0
          )) ||
          0) +
        ((qardan_financial_watch &&
          Object.values(qardan_financial_watch).reduce(
            (accumulator = 0, curValue) => {
              return accumulator + Number(curValue[key] ?? 0);
            },
            0
          )) ||
          0)
      );
    },
    [funding_sources_watch, qardan_financial_watch]
  );

  useEffect(() => {
    if (
      Array.isArray(data?.qardan_hasanah_details?.qardan_financial) &&
      data?.qardan_hasanah_details?.qardan_financial?.length > 0
    ) {
      setqardan(true);
    }
  }, []);

  useEffect(() => {
    isDepartment && dispatch(getProjectPreloadData());
  }, []);

  return (
    <>
      {contextHolder}
      <div>
        <Form
          className="reviseForm"
          scrollToFirstError
          name="basic"
          layout="vertical"
          onFinish={onFinalSubmit}
          form={reviseAmount}
          autoComplete="off"
          style={{ overflow: "auto", width: "100%" }}
        >
          <Row className="flex-row-2">
            <div style={{ width: 218 }}>
              <Col>
                {" "}
                <h6 className="text-center py-2">
                  <b>Sources</b>
                </h6>
              </Col>
            </div>
            <When condition={!isDepartmentCreated}>
              <div style={{ width: 150 }}>
                <Col>
                  <h6 className="text-center py-2">
                    <b>Amil araz</b>
                  </h6>
                </Col>
              </div>
            </When>
            <div style={{ width: 150 }}>
              <Col>
                <h6 className="text-center py-2">
                  <b>Department Araz</b>
                </h6>
              </Col>
            </div>
            {isBoard && (
              <div style={{ width: 150 }}>
                <Col>
                  <h6 className="text-center py-2">
                    <b>Board Araz</b>
                  </h6>
                </Col>
              </div>
            )}
          </Row>
          <Form.List name="funding_sources">
            {(fields, { add, remove }) => {
              return (
                <>
                  {data?.funding_sources &&
                    data?.funding_sources?.map(
                      ({
                        department_amount,
                        id,
                        ummal_amount,
                        value,
                        ...restField
                      }) => (
                        <Row key={id} className="flex-row-2">
                          <div style={{ width: 218, padding: "10px 20px" }}>
                            <Col>
                              {" "}
                              <b className="phoneTitle">{sources[id]}</b>{" "}
                            </Col>
                          </div>

                          <When condition={!isDepartmentCreated}>
                            <div style={{ width: 150, marginBottom: 5 }}>
                              <Col>
                                <InputNumber
                                  readOnly={true}
                                  value={ummal_amount}
                                  curr ={currency}
                                />
                              </Col>
                            </div>
                          </When>

                          <div style={{ width: 150 }}>
                            <Col>
                            {isDepartment?
                            <InputNumber
                            rules={[isDepartment ? requiredRule("") : {}]}
                            name={[id, "department_amount"]}
                            readOnly={(isUmmal, isBoard)}
                            curr ={currency}
                            formatterValue= {
                              !isDepartment && department_amount
                            }
                          /> : <InputNumber
                          readOnly={true}
                          value={department_amount}
                          curr ={currency}
                        />
                          }
                              {/* <InputNumber
                                rules={[isDepartment ? requiredRule("") : {}]}
                                name={[id, "department_amount"]}
                                readOnly={(isUmmal, isBoard)}
                                curr ={currency}
                                formatterValue= {
                                  !isDepartment && department_amount
                                }
                              /> */}
                            </Col>
                          </div>
                          {isBoard && (
                            <div style={{ width: 150 }}>
                              <Col>
                                <InputNumber
                                  name={[id, "board_amount"]}
                                  rules={[isBoard ? requiredRule("") : {}]}
                                  readOnly={(isUmmal, isDepartment)}
                                  curr ={currency}
                                />
                              </Col>
                            </div>
                          )}
                        </Row>
                      )
                    )}
                </>
              );
            }}
          </Form.List>

          <Form.List name="qardan_financial">
            {(fields, { add, remove }) => {
              return (
                <>
                  {data?.qardan_hasanah_details?.qardan_financial &&
                    data?.qardan_hasanah_details?.qardan_financial?.map(
                      ({
                        department_amount,
                        ummal_amount,
                        id,
                        value,
                        ...restField
                      }) => (
                        <Row key={id} className="flex-row-2">
                          <div style={{ width: 218, padding: "10px 20px" }}>
                            <Col>
                              {" "}
                              <b>{sources[id]}</b>{" "}
                            </Col>
                          </div>
                          <When condition={!isDepartmentCreated}>
                            <div style={{ width: 150, marginBottom: 5 }}>
                              <Col>
                                <InputNumber
                                  readOnly={true}
                                  value={ummal_amount}
                                  curr ={currency}
                                />
                              </Col>
                            </div>
                          </When>
                          <div style={{ width: 150 }}>
                            <Col>
                            {isDepartment?
                             <InputNumber
                             rules={[isDepartment ? requiredRule("") : {}]}
                             name={[id, "department_amount"]}
                             readOnly={(isUmmal, isBoard)}
                             curr ={currency}
                             formatterValue={
                               !isDepartment && department_amount
                             }
                           />:
                           <InputNumber
                                  readOnly={true}
                                  value={department_amount}
                                  curr ={currency}
                                />
                          }
                              {/* <InputNumber
                                rules={[isDepartment ? requiredRule("") : {}]}
                                name={[id, "department_amount"]}
                                readOnly={(isUmmal, isBoard)}
                                curr ={currency}
                                formatterValue={
                                  !isDepartment && department_amount
                                }
                              /> */}
                            </Col>
                          </div>
                          <When condition={isBoard}>
                            <div style={{ width: 150 }}>
                              <Col>
                                <InputNumber
                                  rules={[isBoard ? requiredRule("") : {}]}
                                  name={[id, "board_amount"]}
                                  readOnly={(isUmmal, isDepartment)}
                                  curr ={currency}
                                />
                              </Col>
                            </div>
                          </When>
                        </Row>
                      )
                    )}
                </>
              );
            }}
          </Form.List>
          <Row className="flex-row-2">
            <div style={{ width: 218, padding: "10px 20px" }}>
              <Col>
                {" "}
                <b className="phoneTitle">Total project cost</b>{" "}
              </Col>
            </div>
            <When condition={!isDepartmentCreated}>
              <div style={{ width: 150 }} className="Total">
                <Col>{renderAmount(totalProjectCost,currency)}</Col>
              </div>
            </When>
            <div style={{ width: 150 }} className="Total">
              <Col>
                <When condition={isDepartment}>
                  {renderAmount(totalProjectCostHandler("department_amount"),currency)}
                </When>
                <When condition={!isDepartment}>
                  {renderAmount(totalDepartmentCost,currency)}
                </When>
              </Col>
            </div>
            <When condition={isBoard}>
              <div style={{ width: 150 }} className="Total">
                <Col>
                  {renderAmount(totalProjectCostHandler("board_amount"),currency)}
                </Col>
              </div>
            </When>
          </Row>
          <Form.List name="evaluation">
            {(fields, { add, remove }) => {
              return (
                <>
                  {isDepartment && (
                    <Col span={12} style={{ marginTop: 17 }}>
                      <Select
                        rules={[requiredRule("")]}
                        name="evaluation_rating"
                        label="Evaluation Rating"
                        className="select-category"
                        placeholder="Select Rating"
                        options={rankingListOptions}
                      />
                    </Col>
                  )}
                </>
              );
            }}
          </Form.List>
          <div className="error-message">{errorMessage}</div>
          <div className="btn" style={{ marginTop: 10 }}>
            <Button type="primary" htmlType="submit" className="submitbtn mb-3">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
export default ReviseAmount;
