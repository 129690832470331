export function requiredRule(message) {
  return { required: true, message: `${message} Required` };
}
function selectRequiredRule(message) {
  return { required: true, message: `Please select ${message}` };
}
function validateText(message) {
  return {
    pattern: new RegExp(/^[a-zA-Z ]*$/),
    message: `Please enter valid ${message}`,
  };
}

export const CREATE_FORM_VALIDATIONS = {
  //process A
  PROJECT_NAME: [requiredRule("Project Name"), validateText("project Name")],
  PROJECT_CATEGORY: [selectRequiredRule("project category")],
  TIER_1_CATEGORY: [selectRequiredRule("Tier 1 category")],
  TIER_2_CATEGORY: [selectRequiredRule("Tier 2 category")],
  STAKEHOLDER: [requiredRule("Stakeholder")],
  JAMAAT: [requiredRule("Jamaat")],
  PROJECT_RANKING: [requiredRule("Project Ranking ")],
  UMOOR_IMPACTED: [requiredRule("Umoor Impacted")],
  PROJECT_PURPOSE: [requiredRule("Project Purpose")],
  DELIVERABLES: [requiredRule("Deliverables")],
  SCOPE_EXCLUSIONS: [requiredRule("Scope Exclusions")],
  PEOPLE_IMPACTED: [requiredRule("People Impacted")],
  SITE_ASSESSMENT: [requiredRule("Site Assessment")],

  //process B
  UMMAL_AMOUNT: [requiredRule("")],
  DEPT_AMOUNT: [requiredRule("")],
  YEARS_VALIDATION: [requiredRule("Years")],
  MONTHS_VALIDATION: [requiredRule("Months")],
  RISK_TYPE: [requiredRule("Risk Type")],
  MAJOR_KNOW_RISK: [requiredRule("Major Known Risk")],
  MITIGATION_PLAN: [requiredRule("Mitigation plan")],
  EVALUATION_RATING: [requiredRule("")],
};

export const PROJECT_VIEW_VALIDATIONS = {
  NOC_REQUIRED: [requiredRule("NOC")],
  STAKEHOLDER: [requiredRule("Stakeholder")],
  OWNER_DEPARTMENT: [requiredRule("Owner department ")],
};

export const SPLIT_AMOUNT_VALIDATIONS = {
  UMMAL_AMOUNT_VALIDATION: (msg = "") => [requiredRule(msg)],
  YEAR_AMOUNT_VALIDATION: (msg = "") => [requiredRule(msg)],
};
