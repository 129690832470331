import {
  AppstoreOutlined,
  FileDoneOutlined,
  FolderOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo-white.png";
import { REPORT_TYPES, ROLE_TYPE } from "../../constants";
import { findCommonElements, roleIdFind } from "../../utils/commonFunction";
import Header from "../Header/Header";
import "./LayoutMain.scss";
const { Sider, Content } = Layout;

const LayoutMain = ({ children }) => {
  const [collapsed, setCollapsed] = useState(
    window.innerWidth > 480 ? false : true
  );
  const [width, setWidth] = React.useState(window.innerWidth);
  const location = useLocation();

  const [selectedSidebar, setSelectedSidebar] = useState("/dashboard");
  useEffect(() => {
    const route = location?.pathname?.split("/");
    setSelectedSidebar(`/${route?.slice(1)?.join("/")}` || "/dashboard");
  }, [location?.pathname]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  const breakpoint = 1200;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  const style1 = {
    minHeight: 280,
    padding: "30px 7px",
  };
  const style2 = {
    padding: "30px 30px 40px 30px",
    minHeight: 280,
    background: "#eaf5ff",
  };
  const handleLogout = () => {
    const logout = localStorage.getItem("logout_redirect");
    localStorage.clear();
    window.location.reload();
    window.location.replace(logout);
  };
  const items = [
    {
      key: "/dashboard",
      icon: <AppstoreOutlined />,
      label: "Dashboard",
      show: true,
    },
    {
      key: "/project-view",
      icon: <FolderOutlined />,
      label: "Project view",
      show: true,
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: "Logout",
      show: width > 480 ? false : true,
    },
  ];
  const reportsNameArray = Object?.values(REPORT_TYPES);

  const isDepartment = roleIdFind(ROLE_TYPE.DEPARTMENT);
  const isAdmin = roleIdFind(ROLE_TYPE.ADMIN);

  const reportsList = useMemo(() => {
    const filterKeyPermission =
      localStorage.getItem("permissions") &&
      JSON.parse(localStorage.getItem("permissions"))?.map(
        (el) => el?.split(".")[1]
      );
    const data = findCommonElements(reportsNameArray, filterKeyPermission);
    const result = data?.map((el) => {
      return { label: el?.replace("_", " "), key: `/reports/${el}` };
    });
    return result;
  }, [JSON.parse(localStorage.getItem("permissions"))]);

  const footerMessage = localStorage.getItem("support_message") ?? "";
  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <div
          className="sider"
          style={
            width <= breakpoint && collapsed
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <Sider
            trigger={null}
            collapsible
            breakpoint={"lg"}
            collapsed={collapsed}
            theme="light"
            className="sidebar-bg"
          >
            <div className="dashboard-logo ">
              <img
                src={Logo}
                className="logo"
                style={
                  width < 1000 ? { display: "none" } : { display: "block" }
                }
                alt="logo"
              />
            </div>

            <Menu
              theme="light"
              mode="inline"
              selectedKeys={[selectedSidebar]}
              onClick={(e) => {
                if (e.key === "logout") {
                  handleLogout();
                } else {
                  width < 480 && setCollapsed(true);
                  navigate(e.key);
                }
              }}
            >
              {items?.map((el) => {
                if (!el?.show) {
                  return <></>;
                }
                return (
                  <Menu.Item icon={el?.icon} key={el?.key}>
                    {el.label}
                  </Menu.Item>
                );
              })}
              {(isDepartment || isAdmin) && reportsList?.length > 0 && (
                <Menu.SubMenu menu icon={<FileDoneOutlined />} title="Reports">
                  {reportsList?.map((el, i) => {
                    return (
                      <Menu.Item
                        icon={el?.icon}
                        key={el?.key}
                        className="reports-submenu"
                      >
                        {el?.label}
                      </Menu.Item>
                    );
                  })}
                </Menu.SubMenu>
              )}
            </Menu>
          </Sider>
        </div>

        <Layout className="site-layout">
          <div className="header2">
            <Header
              style={{
                padding: 10,
                background: colorBgContainer,
              }}
            >
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: () => setCollapsed(!collapsed),
                }
              )}
            </Header>
          </div>
          <Content
            className="main-content-area"
            style={width > breakpoint ? style2 : style1}
          >
            {children}
          </Content>
          <div
            className="footer-message"
            dangerouslySetInnerHTML={{ __html: footerMessage }}
          ></div>
        </Layout>
      </Layout>
    </>
  );
};

export default LayoutMain;
