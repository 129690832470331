export const createProjectConstants = {
  title: "Process A: Project Submission",
  categoryTitle: "Select the classification/category of the project",
  financialsTitle: "Financials 3A Source of Funding",
  timePeriod: "Project Time Period",
  qardan: {
    required: `Qardan Hasana Required? If "yes" click here`,
    financial: "Financials 3B Qardan Hasanah",
  },
  rating: "Evaluation Rating",
  amil: "Amil Araz",
  dept: "Dept",
  attachments: {
    title: "Attachments",
    label: "Please Choose File",
  },
  generalProjectInfo: {
    title: "General Project Information",
  },
  risks: {
    title: "Known Risks",
    type: {
      label: "Risk Type",
    },
    majorRisk: {
      label: "Major Known Risks",
    },
    mitigation: {
      label: "Mitigation Plan",
    },
  },
  projectScope: {
    title: "Project Scope Statement",
    purpose: {
      label:
        "2.A-Project Purpose / Justification Describe the need this project addresses",
    },
    deliverables: {
      label:
        "B-Deliverables (e.g. Masjid with XXX capacity, MSB with XXX seats etc.) ",
    },
    scope_exclusions: {
      label: "2.C- Scope Exclusions (What will project not address)",
    },
    people_impacted: {
      label: "2.E- People Impacted (positively and/or negatively)",
    },
    site_assessment: {
      label:
        "2.F- Site Assessment (Site and Location Overview Location Assessment and Accessibility,)",
    },
  },
  isUmmalViewable: "Can ummal view this application ?",
};
