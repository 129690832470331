import { Col, Form, notification, Row } from "antd";
import React, { useEffect } from "react";
import GoogleLogin from "react-google-login";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../assets/image/logo-white.png";
import { loginUser, socialLoginUser } from "../../redux/action/auth";
export const LoginForm = () => {
  const navigate = useNavigate();
  const handleSubmit = (formValues) => {};
  const responseGoogle = (response) => {
    const data = {
      profileObj: response?.profileObj,
    };
    const res = dispatch(socialLoginUser(data, navigate)).then(() => {});
    navigate("/dashboard");
  };

  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  let id = searchParams.get("id");
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description,
    });
  };
  useEffect(() => {
    if (id) {
      const data = {
        payload: id.replace(/ /g, "+"),
      };
      dispatch(loginUser(data))
        .then((res) => {
          localStorage.setItem("pms_login", true);
          const rolesList = res?.roles;
          if (rolesList?.length > 1) {
            navigate("/Profile");
          } else {
            localStorage.setItem("rid", String(rolesList[0].id));
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          openNotificationWithIcon("error", "Login failed");
          // navigate("/dashboard");
        });
    }
  }, [id]);

  return (
    <>
      {contextHolder}
      <Form
        scrollToFirstError
        name="normal_login"
        className="form-custom"
        initialValues={{
          remember: true,
        }}
        onFinish={handleSubmit}
      >
        <Row justify="center">
          <Col span={22}>
            <div className="login-logo">
              <img src={Logo} className="App-logo" alt="logo" />
            </div>
          </Col>
        </Row>

        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            justifyContent: "center",
          }}
        >
          <GoogleLogin
            render={(renderProps) => (
              <>
                <div className="">
                  <button
                    disabled={false}
                    onClick={renderProps.onClick}
                    className="google-btn"
                  >
                    Sign in with Google
                  </button>
                </div>
              </>
            )}
            clientId={
              "974676959990-qoas288c4k83nml0a7spt067370bn52b.apps.googleusercontent.com"
            }
            buttonText="Login with Google"
            onSuccess={responseGoogle}
            // onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      </Form>
    </>
  );
};
export default LoginForm;
