const Base_URL =
  process.env.NODE_ENV === "!development"
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_URL;

export const API_ENDPOINT = `${Base_URL}/api`;
export const PATH = {
  auth: {
    login: (data) => `${API_ENDPOINT}/login/`,
    socialLogin: () => `${API_ENDPOINT}/social-login/`,
  },
  upload: {
    uploadImage: `${API_ENDPOINT}/oauth/token`,
  },
  lookups: {
    departmentList: (project_category, owner_department = "", limit = 25) =>
      `${API_ENDPOINT}/lookup/departments/?offset=0&limit=${limit}${
        project_category ? `&project_category=` + project_category : ""
      }${owner_department ? `&owner_department=` + owner_department : ""}`,
    umoorList: (limit = 25) =>
      `${API_ENDPOINT}/lookup/umoors/?offset=0&limit=${limit}`,
    projectCategoryList: (limit = 100) =>
      `${API_ENDPOINT}/lookup/project-categories/?offset=0&limit=${limit}`,
    subCategories: (project_category, limit = 10) =>
      `${API_ENDPOINT}/lookup/sub-categories/?offset=0&limit=${limit}&project_category=${project_category}`,
    fileManagment: () => `${API_ENDPOINT}/application/project/attachments/`,
    deleteAttachments: (id) =>
      `${API_ENDPOINT}/application/project/attachments/${id}/`,
    nocList: (id) => `${API_ENDPOINT}/application/project/noc-status/`,
    jamaatList: (limit = 10, offset = 0, search = "") =>
      `${API_ENDPOINT}/core/jamaats/?offset=${offset}&limit=${limit}&search=${search}`,
    downloadCategories: `${API_ENDPOINT}/lookup/project-categories/export/`,
  },
  Dashboard: {
    dashboardCounts: () => `${API_ENDPOINT}/dashboard/`,
  },
  Project: {
    PreloadData: () => `${API_ENDPOINT}/application/project/preload-details/`,
    listProject: (offset = 0, limit = 10, filter, search, stage, sorter) =>
      `${API_ENDPOINT}/application/projects/${filter ? "" : "?"}${
        filter && `${filter}&`
      }offset=${offset}&limit=${limit}${search}${stage}${sorter}`,
    listProjectDownload: (filter, search, stage, sorter) =>
      `${API_ENDPOINT}/application/projects/${filter ? "" : "?"}${
        filter && `${filter}&`
      }${search}${stage}${sorter}&response_format=csv`,
    createProject: () => `${API_ENDPOINT}/application/projects/`,
    updateProject: (id) => `${API_ENDPOINT}/application/projects/${id}/`,
    getProjectDetails: (id) => `${API_ENDPOINT}/application/projects/${id}/`,
    updateStatus: (id) =>
      `${API_ENDPOINT}/application/projects/${id}/update-status/`,
    manageDepartment: (id) =>
      `${API_ENDPOINT}/application/projects/${id}/manage-departments/`,
    getDownloadReport: (id) =>
      `${API_ENDPOINT}/application/projects/${id}/report/`,
    getUmmalKagaz: (id) =>
      `${API_ENDPOINT}/application/projects/${id}/ummal-kagaz/`,
    getReportByName: (report_name) =>
      `${API_ENDPOINT}/application/projects/reports/?report_name=${report_name}`,
    getBudget: `${API_ENDPOINT}/application/projects/budget-export/`,
    addRemarks: (id, role_id) =>
      `${API_ENDPOINT}/application/projects/${id}/add-remarks/?${
        role_id ? `role_id=${role_id}` : ""
      }`,
  },
};
