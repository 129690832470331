import { gapi } from "gapi-script";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import RouterList from "./route";

const clientId =
  "974676959990-qoas288c4k83nml0a7spt067370bn52b.apps.googleusercontent.com";
function App() {
  useEffect(() => {
    function start() {
      gapi?.client?.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  return (
    <>
      <BrowserRouter>
        <RouterList />
      </BrowserRouter>
    </>
  );
}

export default App;
