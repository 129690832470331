import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import { PROJECT_LIST, PROJECT_PRE_DATA } from "../service/types";
import { loadingStart, loadingStop } from "./loader";

export const getProjectPreloadData = (limit) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.Project.PreloadData(limit),
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        dispatch({
          type: PROJECT_PRE_DATA,
          payload: response && response.data,
        });
        resolve(response && response.data && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const getProjectDetails = (id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.Project.getProjectDetails(id),
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        resolve(response && response.data && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const getListOfProject =
  (offset, filter, search, limit, stage, sorter) => (dispatch) => {
    dispatch(loadingStart());
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: PATH.Project.listProject(
          offset,
          limit,
          filter,
          search,
          stage,
          sorter
        ),
        headers: { "Content-type": "application/json" },
      })
        .then(async (response) => {
          dispatch({
            type: PROJECT_LIST,
            payload: response && response.data && response.data,
          });
          resolve(response && response.data && response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };
export const downloadReport = (id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.Project.getDownloadReport(id),
      // headers: { "Content-type": "application/pdf" },
      headers: {
        "Content-Type": "application/pdf",
        Accept: "application/pdf",
      },
    })
      .then(async (response) => {
        dispatch({
          type: PROJECT_LIST,
          payload: response && response.data && response.data,
        });
        resolve(response && response.data && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const viewReportByName = (reportName) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.Project.getReportByName(reportName),
    })
      .then(async (response) => {
        resolve(response && response.data && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const addProject = (data) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.Project.createProject(),
      data,
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        resolve(response && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const updateProject = (data, id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PUT",
      url: PATH.Project.updateProject(id),
      data,
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        resolve(response && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const updateProjectPartial = (data, id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "PATCH",
      url: PATH.Project.updateProject(id),
      data,
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        resolve(response && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const updateProjectStatus = (data, id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.Project.updateStatus(id),
      data,
      headers: { "Content-type": "application/json" },
    })
      .then((response) => {
        resolve(response && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const manageDepartmentStatus = (data, id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.Project.manageDepartment(id),
      data,
      headers: { "Content-type": "application/json" },
    })
      .then((response) => {
        resolve(response && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const addRemarks = (data, id, role_id) => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.Project.addRemarks(id, role_id),
      data,
      role: false,
    })
      .then(async (response) => {
        resolve(response && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
