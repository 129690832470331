import React from "react";

const UserProfileSVG = ({ fill = "white", fillCircle = "blue", ...rest }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 700 550"
      {...rest}
    >
      <title>Abstract user icon</title>

      <defs>
        <clipPath id="circular-border">
          <circle cx="300" cy="300" r="280" />
        </clipPath>
        <clipPath id="avoid-antialiasing-bugs">
          <rect width="100%" height="498" />
        </clipPath>
      </defs>

      <circle
        cx="300"
        cy="300"
        r="280"
        fill={fillCircle}
        clipPath="url(#avoid-antialiasing-bugs)"
      />
      <circle cx="300" cy="230" r="115" />
      <circle cx="300" cy="550" r="205" clip-path="url(#circular-border)" />
    </svg>
  );
};

export default UserProfileSVG;
