import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import {
  DEPARTMENT_LIST,
  PROJECT_CATEGORIES,
  SUB_CATEGORIES,
  UMOOR_LIST,
} from "../service/types";
import { loadingStop } from "./loader";

export const getUmoorList = (limit) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.lookups.umoorList(limit),
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        dispatch({
          type: UMOOR_LIST,
          payload: response.data.results,
        });
        resolve(response && response.data && response.data.results);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const getDepartmentList =
  (project_category, owner_department, limit) => (dispatch) => {
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: PATH.lookups.departmentList(
          project_category,
          owner_department,
          limit
        ),
        headers: { "Content-type": "application/json" },
      })
        .then(async (response) => {
          dispatch({
            type: DEPARTMENT_LIST,
            payload: response.data.results,
          });
          resolve(response && response.data && response.data.results);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => dispatch(loadingStop()));
    });
  };

export const getProjectCategory = (limit) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.lookups.projectCategoryList(limit),
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        dispatch({
          type: PROJECT_CATEGORIES,
          payload: response.data.results,
        });
        resolve(response && response.data && response.data.results);
      })
      .catch((error) => {
        reject(error);
      })
      .finally();
  });
};
export const getSubCategory = (project_category, limit) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.lookups.subCategories(project_category, limit),
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        dispatch({
          type: SUB_CATEGORIES,
          payload: response.data.results,
        });
        resolve(response && response.data && response.data.results);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const getJamaatList = (search, limit, offset) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.lookups.jamaatList(limit, offset, search),
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        dispatch({
          type: SUB_CATEGORIES,
          payload: response.data.results,
        });
        resolve(response && response.data && response.data.results);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const fileUploads = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: PATH.lookups.fileManagment(),
      data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (response) => {
        resolve(response && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const deleteUploads = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "DELETE",
      url: PATH.lookups.deleteAttachments(id),
    })
      .then(async (response) => {
        resolve(response && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
export const getNOCList = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.lookups.nocList,
    })
      .then(async (response) => {
        resolve(response && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
