import { CaretDownOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Menu, Row } from "antd";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo-white.png";
import { parsedLocalStorage } from "../../utils/commonFunction";
import { When } from "../common";
import "./Header.scss";

const Header = ({ children }) => {
  const navigate = useNavigate();
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 1000;
  const userName = localStorage.getItem("name");
  const roleId = localStorage.getItem("rid");
  const role_arr = parsedLocalStorage("roles");

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  const Item = useMemo(() => {
    const tempArr = [
      {
        label: "Switch Profile",
        key: "./Profile",
        view: Array.isArray(role_arr) && role_arr?.length > 1,
      },
      {
        label: "Logout",
        key: "logout",
        view: true,
      },
    ];
    return tempArr?.filter((el) => el?.view === true);
  }, [role_arr]);

  const handleLogout = () => {
    const logout = localStorage.getItem("logout_redirect");
    localStorage.clear();
    window.location.reload();
    window.location.replace(logout);
  };

  const profileMenu = (
    <Menu
      onClick={(e) => {
        if (e.key === "logout") {
          handleLogout();
        } else {
          navigate(`/${e.key}`);
        }
      }}
      items={Item}
    />
  );
  return (
    <>
      <Row align="middle" className="header-wrapper">
        <Col span={2}>{children}</Col>
        <div className="projectLogo">
          <Col>
            <When condition={width < breakpoint}>
              <img
                src={Logo}
                className="logo"
                style={{ width: "59px" }}
                alt="logo"
              />
            </When>
          </Col>
        </div>
        <div className="user">
          <Col className="header-avatar">
            <div className="user-details d-flex">
              <Avatar size={width < 500 ? 36 : 50} icon={<UserOutlined />} />
              <div className="mx-2">
                <div className="username">{userName}</div>
                <div className="user-role">
                  {role_arr?.map((el, i, arr) => {
                    if (el?.id == roleId) {
                      return el?.name;
                    }
                  })}
                </div>
              </div>
              <div className="userIconDiv">
                <Dropdown trigger={"hover"} overlay={profileMenu}>
                  <div>
                    <div className="user-profile-dropdown">
                      <CaretDownOutlined />
                    </div>
                  </div>
                </Dropdown>
              </div>
            </div>
          </Col>
        </div>
      </Row>
    </>
  );
};

export default Header;
