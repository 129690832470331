import { memo } from "react";

function Icon({ iconName, className, ...rest }) {
  return (
    <i className={`pms-material-icons material-icons ${className}`} {...rest}>
      {iconName}
    </i>
  );
}

export default memo(Icon);
