import axios from "axios";

export function apiClient({
  url,
  data = {},
  method = "",
  headers = {},
  noHeaders,
  role = true,
  ...rest
}) {
  return new Promise(async (resolve, reject) => {
    const accessToken = localStorage.getItem("access_token");
    const role_id = localStorage.getItem("rid");
    // toast.configure();
    let RequestUrl = url;
    if (role_id && role) {
      if (data instanceof FormData) {
        data.append("role_id", role_id);
      } else {
        data["role_id"] = role_id;
      }
      if (method === "GET") {
        // if (RequestUrl?.charAt(RequestUrl?.length - 1) === "/") {
        //   RequestUrl = RequestUrl?.slice(0, -1);
        // }
        RequestUrl =
          RequestUrl +
          (RequestUrl?.indexOf("?") !== -1
            ? `&role_id=${role_id}`
            : `?role_id=${role_id}`);
      }
    }
    axios({
      method,
      url: RequestUrl,
      headers: {
        // ...(noHeaders ? {} : defaultHeaders),
        ...headers,
      },
      data: data,
      ...rest,
    })
      .then(async (res) => {
        if (accessToken) {
          try {
          } catch (e) {}
        }
        if (
          res?.response?.status === 401 ||
          res?.data?.message === "Unauthenticated"
        ) {
          localStorage.clear();
        }
        if (res) {
          resolve(res);
        } else {
          if (res.data && res.data.error) {
            reject(res.data.error);
            if (res.data.error.code === "ER_UNAUTHORIZED_CLIENT") {
              localStorage.clear();
              delete axios.defaults.headers.common["Authorozation"];
            } else if (res.data.statusCode === 400) {
            }
          } else {
            reject(res.data.error);
          }
        }
      })
      .catch(async (err) => {
        if (err?.response?.status === 401) {
          localStorage.clear();
        }
        reject(err);
      });
  });
}
