import {
  DownloadOutlined,
  FileTextOutlined,
  FormOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ChatPage from "../../components/Chat/ChatPage";
import { Select, When, Button as CommonButton } from "../../components/common";
import {
  APPLICATION_STAGES,
  APPLICATION_STATUS,
  PERMISSION_TYPES,
  ROLE_TYPE,
} from "../../constants";
import { useDebouncedEffect } from "../../customHook/useDebouncedEffect";
import useNonInitialRender from "../../customHook/useNonInitialRender";
import {
  getListOfProject,
  getProjectDetails,
} from "../../redux/action/projectForm";
import { PATH } from "../../redux/service/apiConstant";
import { CURRENT_PAGE } from "../../redux/service/types";
import {
  download,
  generateEncodedSearchURI,
  generateEncodedStageURI,
  permissionCheck,
  renderAmount,
  roleIdFind,
} from "../../utils/commonFunction";
import ProjectView from "../ProjectView/ProjectView";
import "./ProjectList.scss";

const ProjectList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const viewProjectId = location?.state?.viewProjectId;
  // const filterPath = searchParams.get("status"); // 'name'
  const filterPath = location?.search; // 'name'
  const filterStagePath = searchParams.get("stage"); // 'name'
  const [active, setActive] = useState("ProjectList");
  const [isReviewModalOpen, setIsReviewModalOpen] = useState("");
  const [width, setWidth] = React.useState(window.innerWidth);
  const [data, setData] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [TablePage, setTablePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setsearchTerm] = useState("");
  const [filterStage, setFilterStage] = useState("");
  const [sorter, setSorter] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const searchUrl = generateEncodedSearchURI(searchTerm);
  const stage = generateEncodedStageURI(filterStage);

  const isDepartment = roleIdFind(ROLE_TYPE.DEPARTMENT);
  const isUmmal = roleIdFind(ROLE_TYPE.UMMAL);
  const isSuperUser = roleIdFind(ROLE_TYPE.SUPER_USER);
  const createProject =
    permissionCheck(PERMISSION_TYPES.ADD_PROJECTS) && (isUmmal || isDepartment);

  const ProjectListData = useSelector(
    (state) =>
      state &&
      state.projectPreDataReducer &&
      state.projectPreDataReducer.projectList
  );

  const totalListItem =
    useSelector(
      (state) =>
        state &&
        state.projectPreDataReducer &&
        state.projectPreDataReducer.projectListCount
    ) || 0;

  useEffect(() => {
    if (viewProjectId) {
      viewFormHandler(viewProjectId);
      setActive("viewFormDetails");
    }
  }, [viewProjectId]);

  function viewFormHandler(id) {
    dispatch(getProjectDetails(id))
      .then((res) => {
        setSelectedProject(res);
        setActive("viewFormDetails");
      })
      .catch(() => {
        setActive("ProjectList");
      });
  }
  function BadgeHandler(type, status_obj) {
    return (
      <Badge
        status={type}
        text={
          <>
            {status_obj?.status}

            {status_obj?.stage && (
              <Tag style={{ backgroundColor: "#e6f4ff", color: "#003366" }}>
                {status_obj?.stage}
              </Tag>
            )}
          </>
        }
      />
    );
  }

  function pageChangeToOne() {
    dispatch({
      type: CURRENT_PAGE,
      payload: 1,
    });
    setTableParams((prev) => {
      return { ...prev, pagination: { ...prev.pagination, current: 1 } };
    });
    setCurrentPage(1);
  }

  const StatusHandler = (status_obj) => {
    switch (status_obj?.status) {
      case APPLICATION_STATUS.PENDING:
        return BadgeHandler("warning", status_obj);

      case APPLICATION_STATUS.APPROVE:
        return BadgeHandler("success", status_obj);

      case APPLICATION_STATUS.REJECTED:
        return BadgeHandler("error", status_obj);

      case APPLICATION_STATUS.REVIEW:
        return BadgeHandler("processing", status_obj);

      case APPLICATION_STATUS.FORWARDED_TO_DEPARTMENT:
        return BadgeHandler("processing", status_obj);

      default:
        return BadgeHandler("default", status_obj);
    }
  };

  const Items = (data) => [
    {
      label: "View",
      key: "view",
      show: true,
    },
    {
      label: "Edit",
      key: "edit",
      show: data?.can_edit,
    },
  ];
  const actionMenu = (id, data) => {
    return (
      <Menu
        onClick={(e) => {
          if (e.key === "view") {
            viewFormHandler(id);
          }
          if (e.key === "edit") {
            navigate(`/edit-project/`, {
              state: { id },
            });
          }
        }}
      >
        {Items(data).map((el) => {
          if (!el?.show) {
            return <></>;
          }
          return <Menu.Item key={el?.key}>{el?.label}</Menu.Item>;
        })}
      </Menu>
    );
  };
  function countDaysFrom(startDate) {
    const startDateTime = new Date(startDate);
    const currentDateTime = new Date();
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const daysPassed = Math.floor(
      (currentDateTime - startDateTime) / millisecondsPerDay
    );
    return daysPassed;
  }

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  const columnMobile = [
    {
      title: "Sr No.",
      dataIndex: "index",
      width: "8%",
      align: "center",
      render: (value, item, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "20%",
      align: "center",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status_obj",
      key: "status__status",
      sorter: true,
      render: (status_obj) => (
        <Space className="badge-status">{StatusHandler(status_obj)}</Space>
      ),

      width: "14%",
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      sorter: true,
    },

    {
      title: "Action",
      align: "center",
      dataIndex: "id",
      render: (id, data) => (
        <Dropdown trigger={"hover"} overlay={() => actionMenu(id, data)}>
          <div>
            <span className="view-icon" style={{ margin: "auto" }}>
              <FileTextOutlined />
            </span>
          </div>
        </Dropdown>
      ),
    },
  ];
  const columnsWeb = [
    {
      title: "Sr No.",
      dataIndex: "index",
      width: "8%",
      align: "center",
      render: (value, item, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "center",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status_obj",
      key: "status__status",
      sorter: true,
      render: (status_obj) => (
        <Space className="badge-status">{StatusHandler(status_obj)}</Space>
      ),
      width: "14%",
    },
    {
      title: "Jamiat",
      dataIndex: "jamiat",
      render: (jamiat) => `${jamiat}`,
      sorter: true,
      key: "jamaat__jamiat__name",
      width: "10%",
    },
    {
      title: "Mauze",
      dataIndex: "jamaat",
      render: (jamaat) => `${jamaat}`,
      sorter: true,
      key: "jamaat__name",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: "20%",
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: true,
      key: "category__name",
      render: (Category) => `${Category}`,
      width: "20%",
    },
    {
      title: "Days",
      dataIndex: "since_days",
      sorter: true,
      key: "since_days",
      render: (since_days) => since_days,
      width: "10%",
    },
    {
      title: "Owner Dept",
      dataIndex: "owner_department",
      sorter: true,
      key: "owner_department__name",
      render: (owner_department) => `${owner_department}`,
      width: "15%",
    },

    {
      title: "Enayat Amount",
      dataIndex: "enayat_amount",
      render: (enayat_amount,data) => `${renderAmount(enayat_amount,data?.currency)}`,
      // sorter: true,
      // key: "jamaat__jamiat__name",
      width: "20%",
    },
    {
      title: "Duration ",
      dataIndex: "time_period",
      render: (time_period) =>
        `${time_period?.years} year ${
          time_period?.months ? time_period?.months : ""
        }  ${time_period?.months > 1 ? "months" : "month"}`,
      align: "center",
      width: "8%",
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "id",
      render: (id, data) => (
        <Dropdown trigger={"hover"} overlay={() => actionMenu(id, data)}>
          <div>
            <span className="view-icon" style={{ margin: "auto" }}>
              <FileTextOutlined />
            </span>
          </div>
        </Dropdown>
      ),
    },
  ];
  const dispatch = useDispatch();
  const getData = (
    current_page,
    filter = "",
    search = "",
    perPageCount,
    stage,
    sorter = ""
  ) => {
    dispatch(
      getListOfProject(
        current_page * pageSize - pageSize,
        filter,
        search,
        perPageCount,
        stage,
        sorter
      )
    );
    setDownloadUrl(
      PATH.Project.listProjectDownload(filter, search, stage, sorter)
    );
  };
  useEffect(() => {
    const stage = generateEncodedStageURI(filterStage);
    const searchUrl = generateEncodedSearchURI(searchTerm);
    getData(1, filterPath ?? "", searchUrl, pageSize, stage, sorter);
    pageChangeToOne();
  }, [filterPath, pageSize, filterStage, sorter]);

  useEffect(() => {
    if (currentPage && currentPage !== 1) {
      // console.log("inside 2");
      const stage = generateEncodedStageURI(filterStage);
      const searchUrl = generateEncodedSearchURI(searchTerm);
      getData(
        currentPage,
        filterPath ?? "",
        searchUrl,
        pageSize,
        stage,
        sorter
      );
      dispatch({
        type: CURRENT_PAGE,
        payload: currentPage,
      });
    }
  }, [currentPage]);

  useNonInitialRender(() => {
    if (TablePage && TablePage === 1) {
      const stage = generateEncodedStageURI(filterStage);
      const searchUrl = generateEncodedSearchURI(searchTerm);
      getData(1, filterPath ?? "", searchUrl, pageSize, stage, sorter);
      dispatch({
        type: CURRENT_PAGE,
        payload: 1,
      });
    }
  }, [TablePage]);

  useEffect(() => {
    if (ProjectListData) {
      setData(ProjectListData);
    }
  }, [ProjectListData]);

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setTablePage(pagination.current);
    setPageSize(pagination.pageSize);
    const sort_data = {
      order_type:
        sorter?.order === "ascend"
          ? "ASC"
          : sorter?.order === "descend"
          ? "DESC"
          : "",
      order_by: sorter?.columnKey ?? null,
    };
    const sorterUrl = sorter?.order
      ? `&order_by=${sort_data?.order_by}&order_type=${sort_data?.order_type}`
      : "";
    setSorter(sorterUrl);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  const navigate = useNavigate();

  function searchArrayOfObjects(searchTerm) {
    // console.log("inside search");
    const searchUrl = generateEncodedSearchURI(searchTerm);
    const stage = generateEncodedStageURI(filterStage);

    getData(1, filterPath ?? "", searchUrl, pageSize, stage, sorter);

    pageChangeToOne();
  }
  useDebouncedEffect(
    () => {
      searchArrayOfObjects(searchTerm);
    },
    1000,
    [searchTerm]
  );

  function getMultiplesOfTen(number) {
    const multiples = [];

    for (let i = 10; i <= number; i += 5) {
      multiples.push(i);
    }

    return multiples;
  }
  const stageList = Object.values(APPLICATION_STAGES)?.map((stages) => {
    return { label: stages, value: stages };
  });

  const stageFilterHandler = (e) => {
    if (e) {
      setFilterStage(e);
    } else {
      setFilterStage("");
    }
  };
  function rowClassNameHandler(record) {
    if (record?.is_stakeholder_department && record?.is_owner_department) {
      return "owner-stack-color";
    } else if (record?.is_owner_department) {
      return "owner-color";
    } else if (record?.is_stakeholder_department) {
      return "stack-color";
    }
  }

  return (
    <>
      <Modal
        title="Remarks"
        open={isReviewModalOpen}
        onCancel={() => setIsReviewModalOpen("")}
        footer={null}
        centered
      >
        <ChatPage width={"100%"} remarksArray={[{ ...isReviewModalOpen }]} />
      </Modal>
      <When condition={active === "ProjectList"}>
        <div className="create-search">
          <When condition={createProject}>
            <div className="btn pl-0">
              <Button
                className="button"
                style={
                  width < 500
                    ? { height: "36px", fontSize: "0.9rem", marginLeft: 0 }
                    : { marginLeft: 0 }
                }
                type="primary"
                onClick={() => navigate("/create-project")}
                shape="square"
                size="large"
              >
                <FormOutlined /> Create Project
              </Button>
            </div>
          </When>
          <div className="search">
            <When condition={isSuperUser}>
              <div className="select-stage-wrapper">
                <CommonButton
                  name={"Budget Export"}
                  type="primary"
                  className={"primary-button"}
                  icon={<DownloadOutlined />}
                  onClick={() =>
                    download(PATH.Project.getBudget, `budget`, false)
                  }
                />
              </div>
            </When>
            <div className="select-stage-wrapper">
              <CommonButton
                name={"Download list"}
                type="primary"
                className={"primary-button"}
                icon={<DownloadOutlined />}
                onClick={() => download(downloadUrl, `project list`)}
              />
            </div>
            <div className="select-stage-wrapper">
              <Select
                className="select-category"
                placeholder="Select project stage"
                optionFilterProp="children"
                options={stageList}
                onChange={stageFilterHandler}
              />
            </div>
            <Input
              type={"search"}
              placeholder="Search...."
              onChange={(e) => setsearchTerm(e.target.value)}
              allowClear
            />
          </div>
        </div>
      </When>
      <When condition={active === "ProjectList"}>
        <Table
          className="project-view-list"
          columns={width < 800 ? columnMobile : columnsWeb}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={{
            ...tableParams.pagination,
            total: totalListItem,
            pageSizeOptions: getMultiplesOfTen(
              totalListItem > 25 ? 25 : totalListItem
            ),
          }}
          loading={loading}
          scroll
          onChange={handleTableChange}
          rowClassName={(record, index) =>
            isDepartment && rowClassNameHandler(record, index)
          }
        />
        <When condition={isDepartment}>
          <div className="indicator-container">
            <Badge color="#c9e4ca" status="processing" text="Owner" />
            <Badge color="#fdd6b5" status="processing" text="Stakeholder" />
            <Badge color="#fff" status="processing" text="Owner/Stakeholder" />
          </div>
        </When>
      </When>
      <When
        condition={
          active === "viewFormDetails" &&
          Object.keys(selectedProject).length > 0
        }
      >
        {" "}
        <ProjectView
          data={selectedProject}
          setActive={setActive}
          viewFormHandler={viewFormHandler}
          listCall={() =>
            getData(
              currentPage,
              filterPath ?? "",
              searchUrl,
              pageSize,
              stage,
              sorter
            )
          }
        />
      </When>
    </>
  );
};
export default ProjectList;
