import { Checkbox as ACheckbox, Form } from "antd";
import React, { memo } from "react";
import "./Checkbox.scss";

function Checkbox({
  name,
  label,
  className,
  style,
  rules,
  validateTrigger,
  defaultChecked,
  ...rest
}) {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      validateTrigger={validateTrigger}
      style={style}
    >
      <ACheckbox
        className={`pms-checkbox ${className}`}
        defaultChecked={defaultChecked}
        {...rest}
      />{" "}
    </Form.Item>
  );
}

export default memo(Checkbox);
