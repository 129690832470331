import { DEPARTMENT_LIST, LOGIN_USER, LOGOUT_USER, PROJECT_CATEGORIES, UMOOR_LIST } from "../service/types";

const initialState = {
  umoor_list: [],
  department_list: [],
  project_categories_list: [],
};

export default function lookupsReducer(state = initialState, action) {

  switch (action.type) {
    case UMOOR_LIST:
      return {...state,umoor_list:action.payload};
    case DEPARTMENT_LIST:
      return {...state,department_list:action.payload};
    case PROJECT_CATEGORIES:
      return {...state,project_categories_list:action.payload};
    default:
      return state;
  }
}
