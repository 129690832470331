import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../redux/action/dashboard";
import CountsDashboard from "../Dashboard/Counts";
import "./Dashboard.css";

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardData());
  }, []);
  const counters = useSelector(
    (state) =>
      state &&
      state?.dashboardDataReducer &&
      state?.dashboardDataReducer?.counts
  );
  return (
    <>
      <div className="dashboard">
        <div className="row margin-btm">
          <div className="col-md-12">
            <h1 className="dashboard-heading">Dashboard</h1>
            <p className="welcome">Welcome to Project Management System</p>
          </div>

          <CountsDashboard data={counters} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
