import { Tag as ATag } from "antd";
import { memo } from "react";
import { APPLICATION_STATUS } from "../../../constants";

function Tag({ title, color, children, type, ...rest }) {
  const isStage = type === "stage";
  function colorHandler(text) {
    if (
      text
        ?.toLowerCase()
        ?.includes(APPLICATION_STATUS.PENDING?.toLowerCase()) ||
      text?.toLowerCase()?.includes(APPLICATION_STATUS.REVIEW?.toLowerCase()) ||
      text?.toLowerCase()?.includes(APPLICATION_STATUS.CHANGE?.toLowerCase())
    ) {
      return "warning";
    } else if (isStage) {
      return "green";
    }
    if (
      text?.toLowerCase()?.includes(APPLICATION_STATUS.REJECTED?.toLowerCase())
    ) {
      return "red";
    }
    if (
      text?.toLowerCase()?.includes(APPLICATION_STATUS.APPROVE?.toLowerCase())
    ) {
      return "green";
    }
    return "blue";
  }
  return (
    <ATag color={color || colorHandler(title)} {...rest}>
      {title ?? children}
    </ATag>
  );
}

export default memo(Tag);
