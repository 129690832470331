import { useRef } from "react";
import { useEffect } from "react";

export const useDebouncedEffect = (effect, delay, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      const handler = setTimeout(() => effect(), delay);

      return () => clearTimeout(handler);
    } else didMount.current = true;
    // using || operator because
    // if its optional then it can be undefined.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};
