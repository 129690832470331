import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { Form, notification } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, When } from "../../components/common";
import { ROLE_NAME, ROLE_TYPE } from "../../constants";
import {
  fileUploads,
  getDepartmentList,
  getProjectCategory,
  getUmoorList,
} from "../../redux/action/lookups";
import {
  addProject,
  getProjectDetails,
  getProjectPreloadData,
  updateProject,
  updateProjectPartial,
} from "../../redux/action/projectForm";
import { PATH } from "../../redux/service/apiConstant";
import {
  compareArraysOfObjects,
  divideAmount,
  revertFundingHandler,
  roleIdFind,
} from "../../utils/commonFunction";
import "./CreateProject.scss";
import ProcessA from "./ProcessA";
import ProcessB from "./ProcessB";
const CreateProject = () => {
  //params
  const location = useLocation();
  const editId = location?.state?.id;
  //forms
  const dispatch = useDispatch();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [splitForm] = Form.useForm();
  const years_watch = Form.useWatch("years", form2);
  const qardan_financial_watch = Form.useWatch("qardan_financial", form2);
  const source_funding_watch = Form.useWatch("funding_sources", form2);
  const repayment_plan_watch = Form.useWatch("repayment_plan", form2);

  const [step, setStep] = useState(1);
  const [deptCurrency, setDeptCurrency] = useState();
  const navigate = useNavigate();

  //lists
  const [repaymentAmount, setRepaymentAmount] = useState({});
  const [qardanAmount, setQardanAmount] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [qardanRequired, setQardanRequired] = useState(false);
  const [showDeptInput, setshowDeptInput] = useState(false);
  const [editData, setEditData] = useState({});
  const [breakpointEmpty, setbreakpointEmpty] = useState(false);
 
  const [step1Data, setStep1Data] = useState({});
  const [TierIList, setTierIList] = useState([]);
  const [TierIIList, setTierIIList] = useState([]);
  const [splitAmountData, setSplitAmountData] = useState("");
  const [nocRequired, setNocRequired] = useState(false);

  const isDepartment = roleIdFind(ROLE_TYPE.DEPARTMENT);

  const project_created_by =
    localStorage.getItem("its_id") + " " + localStorage.getItem("name");

  const totalSourceOfFunding = useMemo(() => {
    return (
      (source_funding_watch &&
        Object?.values(source_funding_watch) &&
        Object?.values(source_funding_watch).length > 0 &&
        Object?.values(source_funding_watch)?.reduce(
          (accumulator = 0, curValue) => {
            return accumulator + Number(curValue?.ummal_amount);
          },
          0
        )) ||
      0
    );
  }, [source_funding_watch]);
  const totalSourceOfFundingDept = useMemo(() => {
    return (
      (source_funding_watch &&
        Object?.values(source_funding_watch) &&
        Object?.values(source_funding_watch).length > 0 &&
        Object?.values(source_funding_watch)?.reduce(
          (accumulator = 0, curValue) => {
            return accumulator + Number(curValue?.department_amount);
          },
          0
        )) ||
      0
    );
  }, [source_funding_watch]);
  const totalQardanAmount = useMemo(() => {
    return (
      (qardan_financial_watch &&
        Object?.values(qardan_financial_watch) &&
        Object?.values(qardan_financial_watch).length > 0 &&
        Object?.values(qardan_financial_watch)?.reduce(
          (accumulator = 0, curValue) => {
            return accumulator + Number(curValue?.ummal_amount);
          },
          0
        )) ||
      0
    );
  }, [qardan_financial_watch]);
  const totalQardanAmountDept = useMemo(() => {
    return (
      (qardan_financial_watch &&
        Object?.values(qardan_financial_watch) &&
        Object?.values(qardan_financial_watch).length > 0 &&
        Object?.values(qardan_financial_watch)?.reduce(
          (accumulator = 0, curValue) => {
            return accumulator + Number(curValue?.department_amount);
          },
          0
        )) ||
      0
    );
  }, [qardan_financial_watch]);
  
  useEffect(() => {
    if (years_watch || years_watch === 0) {
      const tAmount = qardanRequired
        ? totalSourceOfFunding + totalQardanAmount
        : totalSourceOfFunding;
      const splittedAmount = divideAmount(tAmount, years_watch)[0];
      const remainingAmount = divideAmount(tAmount, years_watch)[1];
      const arr = [];
      for (let index = 0; index < years_watch; index++) {
        arr?.push({
          yearly_amount: splittedAmount + (index ? 0 : remainingAmount),
        });
      }
      form2.setFieldsValue({ qardan_split: arr });
    }
  }, [years_watch, totalSourceOfFunding, totalQardanAmount, qardanRequired]);
  const totalRepaymentAmount = useMemo(() => {
    return (
      (repayment_plan_watch &&
        Object?.values(repayment_plan_watch) &&
        Object?.values(repayment_plan_watch).length > 0 &&
        Object?.values(repayment_plan_watch)?.reduce(
          (accumulator = 0, curValue) => {
            return accumulator + Number(curValue?.ummal_amount);
          },
          0
        )) ||
      0
    );
  }, [repayment_plan_watch]);

  useMemo(async () => {
    if (editId) {
      const editData = await dispatch(getProjectDetails(editId)).then(
        (res) => res
      );
      setEditData(editData);
      // console.log(editData)
      form1.setFieldsValue({
        category: editData?.category?.id,
        property_id: editData?.property_id || "",
        name: editData?.name || "",
        ranking: editData?.ranking || "",
        impacted_umoor: editData?.impacted_umoor_obj?.id || "",
        purpose: editData?.purpose || "",
        deliverables: editData?.deliverables || "",
        scope_exclusions: editData?.scope_exclusions || "",
        people_impacted: editData?.people_impacted || "",
        site_assessment: editData?.site_assessment || "",
        is_noc_required: editData?.is_noc_required || "",
        stakeholder_departments: editData?.stakeholder_departments || [],
        jamaat_id:
          { label: editData?.jamaat?.name, value: editData?.jamaat?.id } || "",
      });
      setStep1Data({
        category: editData?.category?.id,
        tier1: editData?.sub_category_1?.id,
        tier2: editData?.sub_category_2?.id,
        property_id: editData?.property_id || "",
        name: editData?.name || "",
        ranking: editData?.ranking || "",
        impacted_umoor: editData?.impacted_umoor_obj?.id || "",
        purpose: editData?.purpose || "",
        deliverables: editData?.deliverables || "",
        scope_exclusions: editData?.scope_exclusions || "",
        people_impacted: editData?.people_impacted || "",
        site_assessment: editData?.site_assessment || "",
        is_noc_required: editData?.is_noc_required || "",
        stakeholder_departments: editData?.stakeholder_departments || [],
        jamaat_id:
          { label: editData?.jamaat?.name, value: editData?.jamaat?.id } || "",
      });
      setNocRequired(editData?.is_noc_required || false);
      setshowDeptInput(isDepartment);
      form2.setFieldsValue({
        funding_sources: revertFundingHandler(editData?.funding_sources),
        qardan_financial: revertFundingHandler(
          editData?.qardan_hasanah_details?.qardan_financial
        ),
        repayment_plan: revertFundingHandler(
          editData?.qardan_hasanah_details?.repayment_plan
        ),
        repayment_description:
          editData?.qardan_hasanah_details?.repayment_description,
        risks: editData?.risks,
        years: editData?.time_period?.years,
        months: editData?.time_period?.months,
        financial_parameters: editData?.financial_parameters,
        remarks: DEPARTMENTS_PROJECT
          ? editData?.department_remarks.length > 0
            ? editData?.remarks?.filter(
                (el) => el?.type === ROLE_NAME.DEPARTMENT
              )[0].remarks
            : ""
          : editData?.remarks?.length > 0
          ? editData?.remarks?.filter((el) => el?.type === ROLE_NAME.UMMAL)[0]
              .remarks
          : "",
        is_ummal_viewable: editData?.is_ummal_viewable,
      });
      splitForm.setFieldsValue({
        split_form: editData?.time_period?.breakpoints,
      });
      setSplitAmountData(editData?.time_period?.breakpoints);
      setQardanRequired(
        editData?.qardan_hasanah_details?.qardan_financial?.length > 0
      );
      setSelectedImages(editData?.attachment_objs);
    } else {
      if (location?.pathname === "/edit-project") {
        navigate("/dashboard");
      }
    }
  }, [editId]);

  useEffect(() => {
    dispatch(getProjectCategory()).then((res) => {});
  }, []);

  const DEPARTMENTS_PROJECT =
    (isDepartment && !editId) ||
    (isDepartment && editId && editData?.created_by === project_created_by);
  useEffect(() => {
    dispatch(getUmoorList());
    dispatch(getDepartmentList());
    dispatch(getProjectPreloadData()).then((res) => {
      form1.setFieldsValue({
        mauze: res?.mauze_info?.name,
        mumineen_children_count: res?.mauze_info?.mumineen_children_count,
        gents: res?.mauze_info?.mumineen_gents_count,
        jamiat: res?.mauze_info?.jamiat.name,
        ladies: res?.mauze_info?.mumineen_ladies_count,
        total: res?.mauze_info?.mumineen_house_count,
        ExecutiveSponsors: localStorage.getItem("name"),
      });
    });
  }, []);

  const onSelectFile = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    dispatch(fileUploads(formData)).then((res) => {
      setSelectedImages((previousImages) => previousImages.concat(res));
      const fileIDExist = localStorage.getItem("fileId")
        ? JSON.parse(localStorage.getItem("fileId"))
        : [];
      fileIDExist.push(res?.id);
      localStorage.setItem("fileId", JSON.stringify(fileIDExist));
    });
    event.target.value = "";
  };

  const FundingHandler = (value) => {
    const funding_sources_arr = [];
    Object.keys(value.funding_sources)?.map((el) => {
      const resource = value.funding_sources[el];
      var obj
       if(isDepartment){
         obj = {
          id: el,
          ummal_amount: resource?.ummal_amount,
          department_amount: resource?.department_amount ?? "",
        };
       }
       else{
         obj = {
          id: el,
          ummal_amount: resource?.ummal_amount,
          // department_amount: resource?.department_amount ?? "",
        };
       }
     
      funding_sources_arr.push(obj);
    });
    return funding_sources_arr;
  };

  const RepaymentFundingHandler = (value) => {
    const repayment_plan_arr = [];
    value?.repayment_plan &&
      Object.keys(value?.repayment_plan)?.map((el) => {
        const resource = value?.repayment_plan[el];
         const  obj = {
            id: el,
            ummal_amount: resource?.ummal_amount,
            department_amount: resource?.department_amount ?? "",
          };

        repayment_plan_arr?.push(obj);
      });
    return repayment_plan_arr;
  };

  const QardanHandler = (value) => {
    const qardan_hasanah_arr = [];
    Object.keys(value.qardan_financial).map((el) => {
      const resource = value.qardan_financial[el];
      var obj
      if(isDepartment){
        obj = {
          id: el,
          ummal_amount: resource?.ummal_amount,
          department_amount: resource?.department_amount ?? "",
        };
      }
      else{
        obj = {
          id: el,
          ummal_amount: resource?.ummal_amount,
        };
      } 
      qardan_hasanah_arr.push(obj);
    });
    return qardan_hasanah_arr;
  };

  const createHandler = (data) => {
    dispatch(addProject(data))
      .then((res) => {
        openNotificationWithIcon("success", res?.messageText);
        navigate("/project-view", {
          state: { viewProjectId: res.id },
        });
      })
      .catch(() => {
        openNotificationWithIcon("error", "Error");
      })
      .finally(() => {
        setSelectedImages([]);
        localStorage.removeItem("fileId");
      });
  };
  const updateHandler = (data, id) => {
    dispatch(updateProject(data, id))
      .then((res) => {
        openNotificationWithIcon("success", res?.messageText);
        navigate("/project-view", {
          state: { viewProjectId: id },
        });
      })
      .catch(() => {
        openNotificationWithIcon("error", "Error");
      })
      .finally(() => {
        setSelectedImages([]);
        localStorage.removeItem("fileId");
      });
  };
  const updatePartialHandler = (data, id) => {
    dispatch(updateProjectPartial(data, id))
      .then((res) => {
        openNotificationWithIcon("success", res?.messageText);
        navigate("/project-view");
      })
      .catch(() => {
        openNotificationWithIcon("error", "Error");
      })
      .finally(() => {
        setSelectedImages([]);
        localStorage.removeItem("fileId");
      });
  };
  const onFinalSubmit = (value) => {
    if (showDeptInput) {
      const data = {
        funding_sources: FundingHandler(value)?.map(
          ({ id, department_amount }) => {
            return { [id]: department_amount };
          }
        ),
        qardan_financial: qardanRequired
          ? QardanHandler(value)?.map(({ id, department_amount }) => {
              return { [id]: department_amount };
            })
          : [],

        evaluation_rating: value?.evaluation_rating,
      };
      updatePartialHandler(data, editId);
      return;
    }

    if (
      (DEPARTMENTS_PROJECT ? totalQardanAmountDept : totalQardanAmount) !==
      totalRepaymentAmount
    ) {
      openNotificationWithIcon(
        "error",
        "Total qardan amount not match total repayment amount"
      );
      return;
    }
    const data = {
      // category: step1Data.tier2, // Choice Field
      sub_category: step1Data.tier2,
      property_id: step1Data.property_id,
      name: step1Data.name, // Text Field
      // owner_department: step1Data.owner_department || 1, // Choice Field
      ranking: step1Data.ranking, // Choice Field (0 - 10)
      impacted_umoor: step1Data.impacted_umoor, // Multiple Choice Field
      purpose: step1Data.purpose, // Text Field
      deliverables: step1Data.deliverables, // Text Field
      scope_exclusions: step1Data.scope_exclusions, // Text Field
      people_impacted: step1Data.people_impacted, // Text Field
      site_assessment: step1Data.site_assessment, // Text Field
      time_period: {
        years: value?.years,
        months: value?.months,
        breakpoints: breakpointEmpty ? null : splitAmountData,
      },
      funding_sources: FundingHandler(value), // [{"id: "", "ummal_amount: 0.0, "department_amount: 0.0}]
      qardan_hasanah_details: {
        qardan_financial: qardanRequired ? QardanHandler(value) : [],
        repayment_description: value.repayment_description,
        repayment_plan: qardanRequired ? RepaymentFundingHandler(value) : [],
      }, // [{"id: "", "ummal_amount: 0.0, "department_amount: 0.0}]
      financial_parameters: value.financial_parameters, // Text Field
      risks: value.risks,
      remarks: value.remarks, // "abc"
    };
    step1Data?.stakeholder_departments &&
      (data["stakeholder_departments"] =
        step1Data?.stakeholder_departments || []);
    localStorage.getItem("fileId") &&
      JSON.parse(localStorage.getItem("fileId")).length > 0 &&
      (data["attachments"] = localStorage.getItem("fileId")
        ? JSON.parse(localStorage.getItem("fileId"))
        : []); // Multiple Choice Field

    DEPARTMENTS_PROJECT && (data["jamaat_id"] = step1Data?.jamaat_id);
    DEPARTMENTS_PROJECT && (data["is_noc_required"] = nocRequired);

    value?.is_ummal_viewable &&
      (data["is_ummal_viewable"] = value?.is_ummal_viewable);
    editId ? updateHandler(data, editId) : createHandler(data);
  };
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description,
    });
  };
  const currency= step1Data?.jamaat?.currency
  const backHandler = () => {
    setStep(1);
    form1.setFieldsValue({
      category: step1Data?.category || "",
      property_id: step1Data?.property_id || "",
      name: step1Data?.name || "",
      ranking: step1Data?.ranking || "",
      impacted_umoor: step1Data?.impacted_umoor || "",
      purpose: step1Data?.purpose || "",
      deliverables: step1Data?.deliverables || "",
      scope_exclusions: step1Data?.scope_exclusions || "",
      people_impacted: step1Data?.people_impacted || "",
      site_assessment: step1Data?.site_assessment || "",
      stakeholder_departments: step1Data?.stakeholder_departments || "",
      is_noc_required: step1Data?.is_noc_required || "",
    });
  };

  useEffect(() => {
    if (editId) {
      const sourceValueChanged =
        source_funding_watch &&
        editData?.funding_sources &&
        compareArraysOfObjects(
          FundingHandler({
            funding_sources: source_funding_watch,
          }),
          editData?.funding_sources
        );
      const qardanFinancialValueChanged =
        qardan_financial_watch &&
        editData?.qardan_hasanah_details?.qardan_financial &&
        compareArraysOfObjects(
          QardanHandler({
            qardan_financial: qardan_financial_watch,
          }),
          editData?.qardan_hasanah_details?.qardan_financial
        );
      const requiredCheckboxChanged =
        editData?.qardan_hasanah_details?.qardan_financial?.length > 0 ===
        qardanRequired;
      const yearValueChanged = years_watch === editData?.time_period?.years;
      setbreakpointEmpty(
        !(
          qardanFinancialValueChanged &&
          sourceValueChanged &&
          requiredCheckboxChanged &&
          yearValueChanged
        )
      );
    }
  }, [
    source_funding_watch,
    qardan_financial_watch,
    editData?.funding_sources,
    editData?.qardan_hasanah_details?.qardan_financial,
    qardanRequired,
    years_watch,
    editData?.time_period?.years,
  ]);

  const downloadCategoryList = () => {
    let headers = new Headers();
    const accessToken = localStorage.getItem("access_token");
    headers.append("Authorization", "Bearer " + accessToken);
    fetch(PATH.lookups.downloadCategories, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);
        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = "catagories";
        link.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };

  return (
    <>
      {contextHolder}
      <When condition={step === 1}>
        <>
          <div className="box-container">
            <Button
              onClick={() => navigate("/project-view")}
              type="primary"
              shape="circle"
              className="ant-custom-btn"
              icon={<ArrowLeftOutlined />}
            />
            <Button
              name="Download Category"
              type="primary"
              className={"primary-button"}
              icon={<DownloadOutlined />}
              onClick={() => downloadCategoryList()}
            />
          </div>
          <ProcessA
            form1={form1}
           setDeptCurrency={setDeptCurrency}
            step1Data={step1Data}
            setStep1Data={setStep1Data}
            setStep={setStep}
            editData={editData}
            editId={editId}
            showDeptInput={showDeptInput}
            setTierIList={setTierIList}
            setTierIIList={setTierIIList}
            TierIList={TierIList}
            TierIIList={TierIIList}
            created_by_dept={DEPARTMENTS_PROJECT}
            setNocRequired={setNocRequired}
            nocRequired={nocRequired}
            deptCurrency={deptCurrency}
            currency={currency}
          />
        </>
      </When>
      <When condition={step === 2}>
        <>
          <Button
            onClick={backHandler}
            type="primary"
            shape="circle"
            className="ant-custom-btn"
            icon={<ArrowLeftOutlined />}
          />
          <ProcessB
            form2={form2}
            step={step}
            deptCurrency={localStorage.getItem("currency")}
            onFinalSubmit={onFinalSubmit}
            showDeptInput={showDeptInput}
            totalSourceOfFunding={totalSourceOfFunding}
            totalSourceOfFundingDept={totalSourceOfFundingDept}
            totalRepaymentAmount={totalRepaymentAmount}
            setQardanRequired={setQardanRequired}
            qardanRequired={qardanRequired}
            setQardanAmount={setQardanAmount}
            qardanAmount={qardanAmount}
            repaymentAmount={repaymentAmount}
            setRepaymentAmount={setRepaymentAmount}
            totalQardanAmount={totalQardanAmount}
            totalQardanAmountDept={totalQardanAmountDept}
            setSelectedImages={setSelectedImages}
            selectedImages={selectedImages}
            onSelectFile={onSelectFile}
            editId={editId}
            editData={editData}
            created_by_dept={DEPARTMENTS_PROJECT}
           
          />
        </>
      </When>
    </>
  );
};

export default CreateProject;
