import { CURRENT_PAGE, PROJECT_LIST, PROJECT_PRE_DATA } from "../service/types";

const initialState = {
  projectPreData: [],
  projectList: [],
  projectListCount: "",
  currentPage: 1,
};

export default function projectPreDataReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECT_PRE_DATA:
      return { ...state, projectPreData: action.payload };
    case CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case PROJECT_LIST:
      return {
        ...state,
        projectList: action.payload.results,
        projectListCount: action.payload.count,
      };
    default:
      return state;
  }
}
