import { combineReducers } from 'redux';
import { LOGOUT_USER } from '../service/types';
import auth from './auth';
import dashboardDataReducer from './dashboard';
import loader from './loader';
import lookupsReducer from './lookups';
import projectPreDataReducer from './projectForm';

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }
  return allReducers(state, action);
}
const allReducers = combineReducers({
  auth,
  lookupsReducer,
  loader,
  projectPreDataReducer,
  dashboardDataReducer
});

export default rootReducer;