import {
  ArrowLeftOutlined,
  DownloadOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Col, Form, Modal, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Tag, When } from "../../components/common";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import StatusHistory from "../../components/StatusHistory";
import {
  APPLICATION_STAGES,
  APPLICATION_STATUS,
  BUTTON_DETAILS,
  PERMISSION_TYPES,
  projectViewConstants,
  ROLE_TYPE,
} from "../../constants";
import { getDepartmentList, getNOCList } from "../../redux/action/lookups";

import {
  checkAllObjectsWithValidValue,
  parsedLocalStorage,
  permissionCheck,
  reportHandler,
  revertFundingHandler,
  roleIdFind,
  totalAmountDept,
  totalAmountUmmal,
  YearMapper,
  ummalKagazHandler,
} from "../../utils/commonFunction";
import ReviseAmount from "../ReviseAmount/ReviseAmount";
import SplitForm from "../SplitAmount";
import ActionContainer from "./ActionContainer";
import NOCForm from "./NOCForm";
import PanelSection from "./PanelSection";
import "./ProjectView.scss";

export default function ProjectView({
  data,
  setActive,
  viewFormHandler,
  listCall,
}) {
  const dispatch = useDispatch();

  //forms
  const [formView] = Form.useForm();
  const [nocForm] = Form.useForm();
  const [splitForm] = Form.useForm();
  const [reviseAmount] = Form.useForm();

  //states
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stackDeptDropdown, setStakeDeptDropdown] = useState(false);
  const [stackHolderListNOC, setStakeholderListNOC] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);
  const [splitAmountData, setSplitAmountData] = useState("");
  const [isReviseModalOpen, setIsReviseModalOpen] = useState(false);

  //form watch
  const stakeholder_departments_watch = Form.useWatch(
    "stakeholder_departments",
    formView
  );
  const status_watch = Form.useWatch("status", nocForm);
  const department_watch = Form.useWatch("department", nocForm);
  const owner_departments_watch = Form.useWatch("owner_department", formView);
  const split_form_watch = Form.useWatch("split_form", splitForm);

  //role check
  const isDepartment = roleIdFind(ROLE_TYPE.DEPARTMENT);
  const isAdmin = roleIdFind(ROLE_TYPE.ADMIN);
  const isUmmal = roleIdFind(ROLE_TYPE.UMMAL);
  const isMasool = roleIdFind(ROLE_TYPE.MASOOL) || roleIdFind(ROLE_TYPE.MUSAID);
  const isBoardA = roleIdFind(ROLE_TYPE.BOARD_A);
  const isBoardB = roleIdFind(ROLE_TYPE.BOARD_B);

  //constants type1
  const {
    addRemarks,
    splitFormConstants,
    reviseForm,
    generalInfo,
    downloadReport,
    ummalKagaz,
  } = projectViewConstants;
  const isCurrentUserIsOwner = data?.is_owner_department;
  const SourceOfFunding = data?.funding_sources || [];
  const qardan_sources = data?.qardan_hasanah_details?.qardan_financial || [];
  const splitList = [...SourceOfFunding, ...qardan_sources];
  const dept_and_board_user = isDepartment && isBoardA;
  const project_created_by =
    localStorage.getItem("its_id") + " " + localStorage.getItem("name");
  const isProjectRejected =
    data?.status_obj?.status === APPLICATION_STATUS.REJECTED;

  data?.can_grant_noc && dispatch(getNOCList());

  useEffect(() => {
    dispatch(getDepartmentList())
      .then((res) => {
        setOwnerList(res ?? []);
      })
      .catch((err) => {
        setOwnerList([]);
      });
  }, []);

  useEffect(() => {
    data?.time_period?.breakpoints &&
      setSplitAmountData(data?.time_period?.breakpoints);
  }, [data?.time_period?.breakpoints]);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  useEffect(() => {
    const dept_list = parsedLocalStorage("departments");
    const list = data?.stakeholder_department_objs?.filter((el) =>
      dept_list?.includes(el?.id)
    );
    const dept_show = list?.length > 1;
    setStakeholderListNOC(list);
    setStakeDeptDropdown(isDepartment && dept_show);
  }, [data]);

  useEffect(() => {
    formView.setFieldsValue({
      owner_department: data?.owner_department_obj?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.owner_department_obj?.id, ownerList]);

  const forwardButtonCondition =
    isAdmin &&
    data?.status_obj?.stage === APPLICATION_STAGES.DATA_VERIFICATION &&
    (data?.status_obj?.status === APPLICATION_STATUS.PENDING ||
      data?.status_obj?.status === APPLICATION_STATUS.CHANGE);

  const changeButtonCondition =
    isCurrentUserIsOwner &&
    data?.status_obj?.stage === APPLICATION_STAGES.DATA_VERIFICATION &&
    data?.status_obj?.status === APPLICATION_STATUS.FORWARDED_TO_DEPARTMENT;

  const approveButtonCondition =
    permissionCheck(PERMISSION_TYPES.APPROVE_PROJECTS) &&
    ((isCurrentUserIsOwner &&
      data?.status_obj?.stage === APPLICATION_STAGES.DATA_VERIFICATION &&
      data?.status_obj?.status ===
        APPLICATION_STATUS.FORWARDED_TO_DEPARTMENT) ||
      (isCurrentUserIsOwner &&
        data?.status_obj?.stage === APPLICATION_STAGES.DEPARTMENT_APPROVAL &&
        data?.status_obj?.status === APPLICATION_STATUS.PENDING) ||
      (isMasool &&
        data?.status_obj?.stage === APPLICATION_STAGES.MASOOL_APPROVAL &&
        data?.status_obj?.status === APPLICATION_STATUS.PENDING) ||
      (isBoardA &&
        data?.status_obj?.stage === APPLICATION_STAGES.BOARD_APPROVAL &&
        data?.status_obj?.status === APPLICATION_STATUS.PENDING) ||
      (isBoardB &&
        data?.status_obj?.stage === APPLICATION_STAGES.BOARD_APPROVAL &&
        data?.status_obj?.status === APPLICATION_STATUS.PENDING) ||
      (isUmmal &&
        data?.status_obj?.stage === APPLICATION_STAGES.BOARD_APPROVAL &&
        data?.status_obj?.status === APPLICATION_STATUS.APPROVE));

  const approveButtonTextCondition = (isDepartment && data?.status_obj?.stage === APPLICATION_STAGES.DEPARTMENT_APPROVAL)

  const rejectButtonCondition =
    (isCurrentUserIsOwner &&
      data?.status_obj?.stage === APPLICATION_STAGES.DEPARTMENT_APPROVAL &&
      data?.status_obj?.status === APPLICATION_STATUS.PENDING) ||
    (isMasool &&
      data?.status_obj?.stage === APPLICATION_STAGES.MASOOL_APPROVAL &&
      data?.status_obj?.status === APPLICATION_STATUS.PENDING) ||
    (isBoardA &&
      data?.status_obj?.stage === APPLICATION_STAGES.BOARD_APPROVAL &&
      data?.status_obj?.status === APPLICATION_STATUS.PENDING) ||
    (isBoardB &&
      data?.status_obj?.stage === APPLICATION_STAGES.BOARD_APPROVAL &&
      data?.status_obj?.status === APPLICATION_STATUS.PENDING) ||
    (isUmmal &&
      data?.status_obj?.stage === APPLICATION_STAGES.BOARD_APPROVAL &&
      data?.status_obj?.status === APPLICATION_STATUS.APPROVE);

  const reviewButtonCondition =
    (isCurrentUserIsOwner &&
      data?.status_obj?.stage === APPLICATION_STAGES.DEPARTMENT_APPROVAL &&
      data?.status_obj?.status === APPLICATION_STATUS.PENDING) ||
    (isMasool &&
      data?.status_obj?.stage === APPLICATION_STAGES.MASOOL_APPROVAL &&
      data?.status_obj?.status === APPLICATION_STATUS.PENDING);

  const holdButtonCondition =
    data?.can_hold && !(data?.status_obj?.status === APPLICATION_STATUS.HOLD);
  const unHoldButtonCondition =
    data?.can_hold && data?.status_obj?.status === APPLICATION_STATUS.HOLD;

  const ummalKagazCondition = 
    (data?.status_obj?.stage === APPLICATION_STAGES.COMPLETE) ||
    (
      data?.status_obj?.stage === APPLICATION_STAGES.BOARD_APPROVAL &&
      data?.status_obj?.status === APPLICATION_STATUS.APPROVE
    )

  const splitHandler = () => {
    const SData = {
      ...revertFundingHandler(data?.funding_sources),
      ...revertFundingHandler(data?.qardan_hasanah_details?.qardan_financial),
    };
    const newObj = { ...SData };
    Object.keys(SData)?.map((el) => {
      newObj[el] = {
        ummal_amount: SData[el]?.ummal_amount || 0,
        ...YearMapper(data?.time_period?.years, SData[el]?.ummal_amount),
      };
    });

    splitForm.setFieldsValue({
      split_form:
        splitAmountData && Object.keys(splitAmountData).length !== 0
          ? splitAmountData
          : newObj,
    });
  };

  const SplitModalHandler = () => {
    setIsSplitModalOpen(true);
    splitHandler();
  };

  const showSplitForm = useMemo(
    () =>
      isUmmal &&
      data?.time_period?.years &&
      Number(data?.time_period?.years) > 1 &&
      !(
        data?.time_period?.breakpoints &&
        Object.keys(data?.time_period?.breakpoints).length !== 0
      ) &&
      !isProjectRejected,
    [data?.time_period]
  );

  const whenBothDeptBoard =
    dept_and_board_user &&
    !(
      checkAllObjectsWithValidValue(
        data?.funding_sources,
        "department_amount"
      ) || checkAllObjectsWithValidValue(data?.funding_sources, "board_amount")
    );

  const whenDept =
    isDepartment &&
    isCurrentUserIsOwner &&
    !checkAllObjectsWithValidValue(
      data?.funding_sources,
      "department_amount"
    ) &&
    (data?.status_obj?.stage === APPLICATION_STAGES.DEPARTMENT_APPROVAL ||
      project_created_by === data?.created_by);

  const whenBoard =
    isBoardA &&
    !checkAllObjectsWithValidValue(data?.funding_sources, "board_amount");

  const showRevisedForm =
    (whenBothDeptBoard || whenDept || whenBoard) && !isProjectRejected;

  const showReviseModal = () => {
    setIsReviseModalOpen(true);
  };
  const handleCancel = () => {
    setIsReviseModalOpen(false);
  };

  const totalProjectCost =
    totalAmountUmmal(
      data?.qardan_hasanah_details &&
        data?.qardan_hasanah_details?.qardan_financial
    ) + totalAmountUmmal(data?.funding_sources && data?.funding_sources);

  const totalDepartmentCost =
    totalAmountDept(
      data?.qardan_hasanah_details &&
        data?.qardan_hasanah_details?.qardan_financial
    ) + totalAmountDept(data?.funding_sources && data?.funding_sources);

  function addRemarksHandler() {
    setIsModalOpen(BUTTON_DETAILS.ADD_REMARKS);
  }
  
  const currency= data?.jamaat?.currency
  return (
    <>
      <div className="project-view">
        <div className="project-view__top-container">
          {" "}
          <Button
            onClick={() => setActive("ProjectList")}
            shape="circle"
            className="ant-custom-btn"
            icon={<ArrowLeftOutlined />}
          />
          <When condition={showSplitForm || showRevisedForm}>
            <div className="project-view__warning-container">
              <When condition={showSplitForm}>
                <div className="message-box box-view view-1 m-auto">
                  {splitFormConstants.error}{" "}
                </div>
              </When>
              <When condition={showRevisedForm}>
                <div className="message-box box-view view-1 m-auto">
                  {reviseForm.error}{" "}
                </div>
              </When>
            </div>
          </When>
          <When condition={showSplitForm && !isProjectRejected}>
            <Button
              name={splitFormConstants.title}
              className="btn-2"
              onClick={() => SplitModalHandler()}
              icon={<FileTextOutlined />}
            />
          </When>
          <When condition={!isProjectRejected}>
            <div className="remark-download-container">
              <Button
                name={addRemarks.title}
                className="btn-2"
                onClick={() => addRemarksHandler()}
                icon={<FileTextOutlined />}
              />
              <When condition={ummalKagazCondition}>
              <Button
                name={ummalKagaz}
                type="primary"
                className={"primary-button"}
                onClick={() => ummalKagazHandler(data)}
                icon={<DownloadOutlined />}
              />
              </When>
              <Button
                name={downloadReport}
                type="primary"
                className={"primary-button"}
                onClick={() => reportHandler(data)}
                icon={<DownloadOutlined />}
              />
            </div>
          </When>
        </div>

        <When condition={!isProjectRejected}>
          <ActionContainer
            formView={formView}
            data={data}
            setIsModalOpen={setIsModalOpen}
            stakeholder_departments_watch={stakeholder_departments_watch}
            owner_departments_watch={owner_departments_watch}
            forwardButtonCondition={forwardButtonCondition}
            changeButtonCondition={changeButtonCondition}
            approveButtonCondition={approveButtonCondition}
            isBoardB={isBoardB}
            reviewButtonCondition={reviewButtonCondition}
            rejectButtonCondition={rejectButtonCondition}
            holdButtonCondition={holdButtonCondition}
            unHoldButtonCondition={unHoldButtonCondition}
            ownerList={ownerList}
            approveButtonTextCondition={approveButtonTextCondition}
          />
        </When>

        <div className="pro-details mb-3">
        
          <div className="w-50 ">
            {" "}
            <h3 className="project-heading projectName">
              {generalInfo.name}: {data?.name}
            </h3>
          </div>
          <div className={"w-50 d-flex"} style={{ justifyContent: "end" }}>
            <Tag
              className="statusTag"
              title={`${data?.status_obj?.status} at ${data?.status_obj?.stage}`}
            />
            <span className="pro-id">Project ID : {data?.id}</span>
          </div>
        </div>

        <PanelSection
          data={data}
          width={width}
          totalProjectCost={totalProjectCost}
          totalDepartmentCost={totalDepartmentCost}
        />

        <When condition={showRevisedForm}>
          <Row>
            <Col span={24}>
              <div className="btn split-btn-2">
                <div className="btn">
                  <Button
                    onClick={() => showReviseModal()}
                    type="primary"
                    name="Revise Amount"
                  />
                </div>
              </div>
              <Modal
                title={reviseForm.title}
                open={isReviseModalOpen}
                centered
                footer=""
                closable
                onCancel={handleCancel}
                width={800}
              >
                <ReviseAmount
                  data={data}
                  totalProjectCost={totalProjectCost}
                  totalDepartmentCost={totalDepartmentCost}
                  reviseAmount={reviseAmount}
                  setIsModalOpen={setIsModalOpen}
                  viewFormHandler={viewFormHandler}
                  handleCancel={handleCancel}
                />
              </Modal>
            </Col>
          </Row>
        </When>
        <When condition={data?.can_grant_noc}>
          <div className="section-wrapper">
            <NOCForm
              nocForm={nocForm}
              stackDeptDropdown={stackDeptDropdown}
              stackHolderListNOC={stackHolderListNOC}
              status_watch={status_watch}
              department_watch={department_watch}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        </When>
  
        <ConfirmModal
          confirm={isModalOpen}
          setConfirm={setIsModalOpen}
          appId={data?.id}
          setActive={setActive}
          reloadCurrentProject={viewFormHandler}
          data={{
            owner_department: owner_departments_watch,
            stakeholder_departments: stakeholder_departments_watch,
            department: department_watch,
            status: status_watch,
          }}
          listCall={listCall}
        />
        <div className="status-card-wrapper">
          <div className="status-card">
            <h3 className="status">Project Status </h3>

            <Tag
              title={`${data?.status_obj?.status} at ${data?.status_obj?.stage}`}
            />
          </div>
        </div>
        <StatusHistory data={data} />
      </div>
      <Modal
        open={isSplitModalOpen}
        centered
        width={"1000px"}
        footer=""
        closable
        onCancel={() => setIsSplitModalOpen(false)}
      >
        <strong> Split Amount (yearly)</strong>
        <SplitForm
          splitForm={splitForm}
          years={data?.time_period?.years}
          timePeriod={data?.time_period}
          splitList={splitList}
          split_form_watch={split_form_watch}
          setSplitAmountData={setSplitAmountData}
          setIsSplitModalOpen={setIsSplitModalOpen}
          editId={data?.id}
          partial={true}
          viewFormHandler={viewFormHandler}
          currency={currency}
        />
      </Modal>
    </>
  );
}
