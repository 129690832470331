import { Typography } from "antd";
import React from "react";

export default function Title({ title, className }) {
  const { Title } = Typography;
  return (
    <Title className={`${className} title`} level={4}>
      {title}
    </Title>
  );
}
