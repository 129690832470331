import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox as QCheckBox, Col, Form, Row, Space } from "antd";
// import ImagesGallery from "./ImagesGallery"
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parsedLocalStorage } from "../../utils/commonFunction";
import {
  Checkbox,
  Input,
  InputNumber,
  Select,
  Title,
  When,
} from "../../components/common";
import FilesListView from "../../components/FileManagment/FilesListView";
import { createProjectConstants } from "../../constants";
import { deleteUploads } from "../../redux/action/lookups";
import { renderAmount } from "../../utils/commonFunction";
import { CREATE_FORM_VALIDATIONS } from "../../utils/validator";
import "./CreateProject.scss";
import "./Phone.scss";

const ProcessB = ({
  form2,
  step,
  onFinalSubmit,
  showDeptInput,
  totalSourceOfFunding,
  totalRepaymentAmount,
  setQardanRequired,
  qardanRequired,
  setQardanAmount,
  qardanAmount,
  repaymentAmount,
  setRepaymentAmount,
  totalQardanAmount,
  totalQardanAmountDept,
  setSelectedImages,
  selectedImages,
  onSelectFile,
  editId,
  created_by_dept,
  totalSourceOfFundingDept,
  editData,
  
}) => {
 
  const dispatch = useDispatch();
  const monthList = useSelector(
    (state) =>
      state &&
      state.projectPreDataReducer &&
      state.projectPreDataReducer.projectPreData &&
      state.projectPreDataReducer.projectPreData.months
  );
  const yearsList = useSelector(
    (state) =>
      state &&
      state.projectPreDataReducer &&
      state.projectPreDataReducer.projectPreData &&
      state.projectPreDataReducer.projectPreData.years
  );
  const rankingList = useSelector(
    (state) =>
      state &&
      state.projectPreDataReducer &&
      state.projectPreDataReducer.projectPreData &&
      state.projectPreDataReducer.projectPreData.rankings
  );
  const SourceOfFunding = useSelector(
    (state) =>
      state &&
      state.projectPreDataReducer &&
      state.projectPreDataReducer.projectPreData &&
      state.projectPreDataReducer.projectPreData.funding_sources
  );
  const qardan_sources = useSelector(
    (state) =>
      state &&
      state.projectPreDataReducer &&
      state.projectPreDataReducer.projectPreData &&
      state.projectPreDataReducer.projectPreData.qardan_sources
  );

  function deleteHandler(imageID, e) {
    if (e && e.stopPropagation) e.stopPropagation();
    dispatch(deleteUploads(imageID));
    setSelectedImages(selectedImages?.filter((e) => e.id !== imageID));
    const fileIDExist = localStorage.getItem("fileId")
      ? JSON.parse(localStorage.getItem("fileId"))?.filter((e) => e !== imageID)
      : [];
    localStorage.setItem("fileId", JSON.stringify(fileIDExist));
  }

  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  const yearListOptions =
    yearsList &&
    yearsList?.map(({ id, value }) => {
      return {
        label: `${value} ${Number(value) > 1 ? "year" : "years"}`,
        value: id,
      };
    });

  const monthListOptions =
    monthList &&
    monthList?.map(({ id, value }) => {
      return {
        label: value,
        value: id,
      };
    });

  const rankingListOptions =
    monthList &&
    monthList?.map(({ id, value }) => {
      return {
        label: id,
        value: id,
      };
    });

  const riskTypeOptions = [
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  const totalProjectCost = created_by_dept
    ? totalSourceOfFundingDept + totalQardanAmountDept
    : totalSourceOfFunding + totalQardanAmount;
  const width16to18 = width < 500 ? 16 : 18;
  const {
    financialsTitle,
    qardan,
    attachments,
    timePeriod,
    rating,
    risks,
    amil,
    dept,
    deptCurrency,
    isUmmalViewable,
  } = createProjectConstants;
  const {
    UMMAL_AMOUNT,
    DEPT_AMOUNT,
    YEARS_VALIDATION,
    MONTHS_VALIDATION,
    RISK_TYPE,
    MAJOR_KNOW_RISK,
    MITIGATION_PLAN,
    EVALUATION_RATING,
  } = CREATE_FORM_VALIDATIONS;
  var currency;
  var curr;
  if(!created_by_dept){
  const jamaat = parsedLocalStorage("jamaat");
  Object.keys(jamaat)?.map((key)=>{
    //  console.log(key)
    if(key === "currency"){
       currency = Object.values(jamaat?.currency)[0]
    }
  })}
  
  if(created_by_dept && localStorage.getItem("currency") !== null){
    // console.log(localStorage.getItem("currency"))
    curr = localStorage.getItem("currency")
  }

  return (
    <>
      <Form
        scrollToFirstError
        name="basic"
        form={form2}
        labelCol={{
          span: 24,
        }}
        wrappercol={{
          span: 24,
        }}
        layout="vertical"
        onFinish={onFinalSubmit}
        autoComplete="off"
        className="approval-form-wrapper"
      >
        <div className=""></div>
        <div className="section-wrapper">
          <Title title={financialsTitle} />
          <Row>
            <Col
              span={
                showDeptInput && !created_by_dept
                  ? 12
                  : width < 500 && showDeptInput && created_by_dept
                  ? 14
                  : width < 500
                  ? 16
                  : 18
              }
            ></Col>
            {!created_by_dept && (
              <Col span={width < 500 ? 8 : 6}>
                {" "}
                <h6 className="text-center py-2">
                  <b>{amil}</b>
                </h6>
              </Col>
            )}
            {(showDeptInput || created_by_dept) && (
              <Col span={width < 500 ? 8 : 6}>
                <h6 className="text-center py-2 ">
                  <b>{dept}</b>
                </h6>
              </Col>
            )}
          </Row>
          <Form.List name="funding_sources">
            {(fields, { add, remove }) => {
              return (
                <>
                  {SourceOfFunding &&
                    SourceOfFunding?.map(({ id, value, ...restField }) => (
                      <Row key={id}>
                        <Col
                          span={
                            showDeptInput && !created_by_dept
                              ? 12
                              : width < 500 && showDeptInput && created_by_dept
                              ? 14
                              : width < 500
                              ? 16
                              : 18
                          }
                        >
                          {" "}
                          <b className="phoneTitle">{value}</b>{" "}
                        </Col>
                        <When condition={!created_by_dept}>
                          <Col span={width < 500 ? 8 : 6} sm={6}>
                            <InputNumber
                              rules={UMMAL_AMOUNT}
                              name={[id, "ummal_amount"]}
                              readOnly={showDeptInput}
                              curr={currency}
                            />
                          </Col>
                        </When>
                        <When condition={showDeptInput || created_by_dept}>
                          <Col span={width < 500 ? 8 : 6} sm={6}>
                            <InputNumber
                              rules={DEPT_AMOUNT}
                              name={[id, "department_amount"]}
                              created_by_dept={created_by_dept}
                              curr ={curr}
                            />
                          </Col>
                        </When>
                      </Row>
                    ))}
                </>
              );
            }}
          </Form.List>

          <Row>
            <Col span={width16to18}>
              <h3>Total Amount</h3>
            </Col>
            <Col span={width < 500 ? 8 : 6}>
              <div className="Total">
                <h4>
                  <b>
                    {renderAmount(
                      created_by_dept
                        ? totalSourceOfFundingDept
                        : totalSourceOfFunding , created_by_dept? curr:currency
                    )
                    }
                  </b>
                </h4>
              </div>
            </Col>
            <Col span={6}></Col>
          </Row>
        </div>
        <div className="">
          <QCheckBox
            disabled={showDeptInput}
            onChange={(value) => setQardanRequired(value.target.checked)}
            checked={qardanRequired}
          >
            {qardan.required}
          </QCheckBox>
        </div>
        {qardanRequired && (
          <div className="section-wrapper">
            <Title title={qardan.financial} />

            <Row>
              <Col
                span={
                  showDeptInput && !created_by_dept
                    ? 12
                    : width < 500 && showDeptInput && created_by_dept
                    ? 14
                    : width < 500
                    ? 16
                    : 18
                }
              ></Col>
              <When condition={!created_by_dept}>
                <Col span={width < 500 ? 8 : 6}>
                  {" "}
                  <h6 className="text-center py-3">
                    <b>{amil}</b>
                  </h6>
                </Col>
              </When>
              <When condition={showDeptInput || created_by_dept}>
                <Col span={width < 500 ? 8 : 6}>
                  <h6 className="text-center py-3">
                    <b>{dept}</b>
                  </h6>
                </Col>
              </When>
            </Row>
            <Form.List name="qardan_financial">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {qardan_sources &&
                      qardan_sources?.map(({ id, value, ...restField }) => (
                        <Row key={id}>
                          <Col
                            span={
                              showDeptInput && !created_by_dept
                                ? 12
                                : width < 500 &&
                                  showDeptInput &&
                                  created_by_dept
                                ? 14
                                : width < 500
                                ? 16
                                : 18
                            }
                          >
                            {" "}
                            <b>{value}</b>{" "}
                          </Col>
                          <When condition={!created_by_dept}>
                            <Col span={width < 500 ? 8 : 6} sm={6}>
                              <InputNumber
                                rules={UMMAL_AMOUNT}
                                name={[id, "ummal_amount"]}
                                readOnly={showDeptInput}
                                curr={currency}
                                onChange={(e) =>
                                  setQardanAmount({
                                    ...qardanAmount,
                                    [id]: e,
                                  })
                                }
                              />
                            </Col>
                          </When>
                          <When condition={showDeptInput || created_by_dept}>
                            <Col span={width < 500 ? 8 : 6} sm={6}>
                              <InputNumber
                                rules={DEPT_AMOUNT}
                                name={[id, "department_amount"]}
                                curr={curr}
                              />
                            </Col>
                          </When>
                        </Row>
                      ))}
                  </>
                );
              }}
            </Form.List>

            <Row>
              <Col span={width16to18}>
                <h3>Total QH Amount</h3>
              </Col>
              <Col span={width < 500 ? 8 : 6}>
                <div className="Total">
                  <h4>
                    <b>
                      {renderAmount(
                        created_by_dept
                          ? totalQardanAmountDept
                          : totalQardanAmount, created_by_dept?curr: currency
                      )}
                    </b>
                  </h4>
                </div>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={width16to18}>
                <h3>Total project cost</h3>
              </Col>
              <Col span={width < 500 ? 8 : 6}>
                <div className="Total">
                  <h4>
                    <b>{renderAmount(totalProjectCost , created_by_dept?curr: currency)}</b>
                  </h4>
                </div>
              </Col>
              <Col span={6}></Col>
            </Row>

            <div>
              <h6 className="mt-5">Repayment Plan of Qardan Hasanah</h6>

              <Row>
                <Col span={24}>
                  <Input
                    isTextArea={true}
                    label="Description"
                    name="repayment_description"
                    readOnly={showDeptInput}
                    rows={4}
                  />
                </Col>
              </Row>
              <h6 className="mt-4">Repayment Amount Details</h6>
              <Row>
                <Col span={width16to18}> </Col>

                <Col span={width < 500 ? 8 : 6} lg={6} className="text-center">
                  <b className="my-2 d-block">Amount</b>
                </Col>
              </Row>
              <Form.List name="repayment_plan">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {SourceOfFunding &&
                        SourceOfFunding?.map(({ id, value, ...restField }) => {
                          if (id === "Enayat") {
                            return <></>;
                          }
                          return (
                            <Row key={id}>
                              <Col span={width16to18}>
                                {" "}
                                <b className="phoneTitle">{value}</b>{" "}
                              </Col>

                              <Col span={width < 500 ? 8 : 6} sm={6}>
                                <InputNumber
                                  rules={UMMAL_AMOUNT}
                                  name={[id, "ummal_amount"]}
                                  readOnly={showDeptInput}
                                  curr={created_by_dept?curr:currency}
                                  onChange={(e) =>
                                    setRepaymentAmount({
                                      ...repaymentAmount,
                                      [id]: e,
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          );
                        })}
                    </>
                  );
                }}
              </Form.List>
            </div>
            <div>
              <Row>
                <Col span={width16to18}>
                  <h3>Total Amount</h3>
                </Col>
                <Col span={width < 500 ? 8 : 6}>
                  <div className="Total">
                    <h4>
                      <b> {renderAmount(totalRepaymentAmount, created_by_dept?curr: currency)}</b>
                    </h4>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col span={24}>
                <Input
                  isTextArea={true}
                  label="Financial Parameters"
                  name="financial_parameters"
                  readOnly={showDeptInput}
                  rows={2}
                />
              </Col>
            </Row>
          </div>
        )}

        <div className="section-wrapper">
          <Title title={timePeriod} />
          <Row style={{ flexDirection: "row" }}>
            <Col span={width < 500 ? 12 : 6}>
              <Select
                rules={YEARS_VALIDATION}
                name="years"
                disabled={showDeptInput}
                className="select-category"
                placeholder="Select Years"
                options={yearListOptions}
              />
            </Col>
            <Col span={width < 500 ? 12 : 6}>
              <Select
                rules={MONTHS_VALIDATION}
                name="months"
                disabled={showDeptInput}
                className="select-category"
                placeholder="Select months"
                options={monthListOptions}
              />
            </Col>
          </Row>
        </div>

        <div
          className="section-wrapper"
          style={width < 500 ? { overflow: "auto" } : {}}
        >
          <Title title={risks.title} />
          <Form.List
            name="risks"
            initialValue={[{ type: null, risk: "", mitigation_plan: "" }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, i) => (
                  <Space
                    key={key}
                    style={
                      width < 500
                        ? { display: "flex", marginBottom: 8, width: 500 }
                        : {
                            display: "flex",
                            marginBottom: 8,
                          }
                    }
                    align="baseline"
                  >
                    <Row>
                      <Col span={6}>
                        <Select
                          {...restField}
                          rules={RISK_TYPE}
                          label={risks.type.label}
                          name={[i, "type"]}
                          disabled={showDeptInput}
                          defaultValue="Select Risk Type"
                          style={{ width: 130 }}
                          options={riskTypeOptions}
                        />
                      </Col>
                      <Col span={10}>
                        <Input
                          {...restField}
                          rules={MAJOR_KNOW_RISK}
                          label={risks.majorRisk.label}
                          name={[i, "risk"]}
                          readOnly={showDeptInput}
                          className="inputField"
                        />
                      </Col>
                      <Col span={8}>
                        <Input
                          {...restField}
                          rules={MITIGATION_PLAN}
                          label={risks.mitigation.label}
                          name={[i, "mitigation_plan"]}
                          readOnly={showDeptInput}
                          className="inputField"
                        />
                      </Col>
                    </Row>

                    <span style={{ width: "20px" }}>
                      <When condition={i !== 0}>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </When>
                    </span>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    className="risk-btn"
                    type="dashed"
                    onClick={() => add()}
                    style={
                      width < 500
                        ? { width: 121, height: 30 }
                        : {
                            width: 170,
                            height: 40,
                            border: "solid",
                            borderColor: "#0c0c33",
                            borderWidth: 1,
                          }
                    }
                  >
                    Add Risks
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
        <div className="section-wrapper">
          <Title title={attachments.title} />
          <p style={{marginLeft: '1vw', color: 'red' }}>
            Kindly upload the required documents before the final submission:
          <ol>
            <li>Aamilsaheb Araz</li>
            <li>Jamaat resolution(if required)</li>
            <li>Khidmat karnar araz (if required)</li>
            <li>Existing photos</li>
          </ol>
          </p>
          <Row>
            <Col span={24}>
              <div>
                <div className="ant-upload-text">
                  <input
                    id="imageSelect"
                    type="file"
                    name="images"
                    onChange={onSelectFile}
                    // multiple
                    // accept="image/png , image/jpeg ,.png, .jpg, .jpeg, application/pdf,"
                    // style={{borderRadius:5, backgroundColor:"#003366", color:"rgb(255, 255, 255)" }}
                  />
                  <label className="lbl-file phoneTitle" htmlFor="imageSelect">
                    {attachments.label}
                  </label>
                  {/* <div className="change">
                    Please upload only PDF , JPG and PNG files.
                  </div> */}

                  <div className="images">
                    <FilesListView
                      data={selectedImages}
                      deleteHandler={deleteHandler}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="section-wrapper">
          <Row>
            <Col span={24}>
              <Input
                isTextArea={true}
                label={`Remark ${
                  created_by_dept ? "from department " : "by amil saheb"
                }`}
                name="remarks"
                readOnly={showDeptInput}
                rows={4}
              />
            </Col>
            <When condition={showDeptInput || created_by_dept}>
              <Col span={12}>
                <Select
                  rules={EVALUATION_RATING}
                  name="evaluation_rating"
                  label={rating}
                  className="select-category"
                  placeholder="Select Rating"
                  options={rankingListOptions}
                />
              </Col>
            </When>
            <When condition={editData?.is_department_created}>
              <Col>
                <Checkbox label={isUmmalViewable} name="is_ummal_viewable" />
              </Col>
            </When>
          </Row>
        </div>
        <div className="btn">
          <Button type="primary" htmlType="submit" className="submitbtn mb-3">
            {editId ? "Update" : "Submit"}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ProcessB;
