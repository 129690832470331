import { Button, Col, Form, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  DebounceSelect,
  Input,
  Select,
  When,
} from "../../components/common";
import Title from "../../components/common/Title";
import { createProjectConstants, ROLE_TYPE } from "../../constants";
import {
  getDepartmentList,
  getJamaatList,
  getSubCategory,
} from "../../redux/action/lookups";
import { roleIdFind } from "../../utils/commonFunction";
import { CREATE_FORM_VALIDATIONS } from "../../utils/validator";
import "./Phone.scss";

const ProcessA = ({
  form1,
  setDeptCurrency,
  deptCurrency,
  step1Data,
  setStep1Data,
  setStep,
  editData,
  editId,
  showDeptInput,
  setTierIList,
  setTierIIList,
  TierIList,
  TierIIList,
  setNocRequired,
  nocRequired,
}) => {
  // const { Title } = Typography;
  const dispatch = useDispatch();
  const tier1_watch = Form.useWatch("tier1", form1);
  const projectCategory_watch = Form.useWatch("category", form1);
  const [TierIListLoader, setTierIListLoader] = useState(false);
  const [StackHolderLoader, setStackHolderLoader] = useState(false);
  const [stakeholderList, setStakeholderList] = useState([]);

  const isDepartment = roleIdFind(ROLE_TYPE.DEPARTMENT);

  const onFinish = (values) => {
    const data = { ...values, jamaat_id: values?.jamaat_id?.value };
    if(isDepartment){
      const promise = new Promise((resolve, reject) => {
        resolve(fetchUserCurrency(values?.jamaat_id?.label));
      });
      promise.then((value) => {
        Object.values(value)?.map((hd) => {
          localStorage.setItem("currency", hd.currency);
        });
      });
    }
     setStep1Data(data);
     setStep(2);
  };
  //seelctors

  const categoryList = useSelector(
    (state) =>
      state &&
      state.lookupsReducer &&
      state.lookupsReducer.project_categories_list
  );
  const rankingList = useSelector(
    (state) =>
      state &&
      state.projectPreDataReducer &&
      state.projectPreDataReducer.projectPreData &&
      state.projectPreDataReducer.projectPreData.rankings
  );
  const umoorList = useSelector(
    (state) => state && state.lookupsReducer && state.lookupsReducer.umoor_list
  );
  const tierHandler = (arr, stepper = 1) => {
    if (arr && arr?.subcategories?.length > 0) {
      stepper === 1 && setTierIList(arr?.subcategories);
      stepper === 2 && setTierIIList(arr?.subcategories);
      tierHandler(arr?.subcategories, stepper + 1);
    } else {
      stepper === 1 && setTierIList([]);
      stepper === 2 && setTierIIList([]);
    }
  };

  useMemo(() => {
    if (projectCategory_watch) {
      if (
        projectCategory_watch === step1Data?.category &&
        TierIList?.length !== 0
      ) {
        return;
      }
      setTierIList([]);
      setTierIIList([]);
      (!editId || projectCategory_watch !== step1Data?.category) &&
        form1.setFieldsValue({
          tier1: null,
          tier2: null,
        });
      setTierIListLoader(true);
      dispatch(getSubCategory(projectCategory_watch))
        .then((res) => {
          setTierIList(res);
        })
        .catch()
        .finally(() => setTierIListLoader(false));
    }
  }, [projectCategory_watch, editData]);
  useMemo(() => {
    // if (projectCategory_watch) {
    //   if (
    //     projectCategory_watch === step1Data?.category &&
    //     stakeholderList?.length !== 0
    //   ) {
    //     return;
    //   }
    //   setStakeholderList([]);
    //   (!editId || projectCategory_watch !== step1Data?.category) &&
    //     form1.setFieldsValue({
    //       stakeholder_departments: null,
    //     });
    setStackHolderLoader(true);
    // dispatch(getDepartmentList(projectCategory_watch))
    dispatch(getDepartmentList())
      .then((res) => {
        setStakeholderList(res);
      })
      .catch()
      .finally(() => setStackHolderLoader(false));
    // }
  }, []);

  useEffect(() => {
    const slectedCategory = TierIList?.find((el) => el?.id === tier1_watch);
    if (tier1_watch && TierIList?.length > 0) {
      tierHandler(slectedCategory, 2);
    }
  }, [TierIList, tier1_watch]);

  useEffect(() => {
    if (editData?.sub_category_1?.id) {
      form1.setFieldsValue({
        tier1: editData?.sub_category_1?.id,
      });
    }
  }, [editData?.sub_category_1?.id]);

  useEffect(() => {
    if (editData?.sub_category_2?.id) {
      form1.setFieldsValue({
        tier2: editData?.sub_category_2?.id,
      });
    }
  }, [editData?.sub_category_2?.id]);

  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });
  const {
    PROJECT_NAME,
    PROJECT_CATEGORY,
    TIER_1_CATEGORY,
    TIER_2_CATEGORY,
    STAKEHOLDER,
    JAMAAT,
    PROJECT_RANKING,
    UMOOR_IMPACTED,
    PROJECT_PURPOSE,
    DELIVERABLES,
    SCOPE_EXCLUSIONS,
    PEOPLE_IMPACTED,
    SITE_ASSESSMENT,
  } = CREATE_FORM_VALIDATIONS;
  const categoryListOptions =
    categoryList &&
    categoryList?.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });
  const stackHolderListOptions =
    stakeholderList &&
    stakeholderList?.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });
  const tierIListOptions =
    TierIList &&
    TierIList?.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });
  const tierIIListOptions =
    TierIIList &&
    TierIIList?.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });
  const rankingListOptions =
    rankingList &&
    rankingList?.map(({ id }) => {
      return {
        label: id,
        value: id,
      };
    });
  const umoorListOptions =
    umoorList &&
    umoorList?.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });
  const width12to8 = width < 500 ? 12 : 8;
  const width24to8 = width < 500 ? 24 : 8;
  const { generalProjectInfo, title, categoryTitle, projectScope } =
    createProjectConstants;

  const fetchUserCurrency = async (jamaatName) => {
    return await dispatch(getJamaatList(jamaatName)).then((body) => {
      return body.map((user) => ({
        currency: user.currency,
      }));
    });
  };

  // console.log(currency);

  //     const promise1 = Promise.resolve(currency);
  //       promise1.then((value) => {

  //       Object.values(value)?.map((hd)=>{
  //         console.log(hd.currency)
  //         localStorage.setItem("currency", hd.currency);

  //       })
  //       console.log(value);
  // });

  async function fetchUserList(jamaatName) {
    //   setCurrency(fetchUserCurrency(jamaatName))
    //    console.log(currency)
    //     const promise1 = Promise.resolve(currency);
    //     promise1.then((value) => {
    //     if(value !== null){
    //       console.log(value)
    //       Object.values(value)?.map((hd)=>{
    //         console.log(hd.currency)
    //         localStorage.setItem("currency", hd.currency);

    //       })
    //     }
    // });

    // main()
    return dispatch(getJamaatList(jamaatName)).then((body) => {
      return body.map((user) => ({
        label: `${user.name}`,
        value: user.id,
      }));
    });
  }

  async function nocHandler(e) {
  }

  return (
    <>
      <Form
        scrollToFirstError
        form={form1}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrappercol={{
          span: 24,
        }}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="approval-form-wrapper"
      >
        <div className="project-title">
          <Title title={title} />
        </div>
        <div className="section-wrapper">
          <Title title={categoryTitle} />
          <Row style={{ flexDirection: "row" }}>
            <Col span={width24to8}>
              <Select
                rules={PROJECT_CATEGORY}
                style={{ color: "gray" }}
                name="category"
                disabled={showDeptInput}
                className="select-category"
                placeholder="Select project category"
                optionFilterProp="children"
                spinLoader={categoryList?.length === 0}
                options={categoryListOptions}
              />
            </Col>

            <Col span={width24to8}>
              <Select
                style={{ color: "gray" }}
                name="tier1"
                rules={TIER_1_CATEGORY}
                disabled={showDeptInput}
                className="select-category"
                placeholder="Tier I"
                spinLoader={TierIListLoader}
                options={tierIListOptions}
              />
            </Col>

            <Col span={width24to8}>
              <Select
                style={{ color: "gray" }}
                name="tier2"
                rules={TIER_2_CATEGORY}
                disabled={showDeptInput}
                className="select-category"
                placeholder="Tier II"
                options={tierIIListOptions}
              />
            </Col>
            <When condition={isDepartment}>
              <>
                <Col span={width12to8}>
                  <Select
                    rules={nocRequired ? STAKEHOLDER : []}
                    label="Stakeholder departments:"
                    name="stakeholder_departments"
                    className="select-category"
                    placeholder="Select Stakeholder departments"
                    spinLoader={StackHolderLoader}
                    mode="multiple"
                    options={stackHolderListOptions}
                  />
                </Col>
                <Col span={width12to8}>
                  <DebounceSelect
                    rules={JAMAAT}
                    label="Select Jamaat"
                    name="jamaat_id"
                    className="select-category "
                    placeholder="Type your jamaat name here..."
                    fetchOptions={fetchUserList}
                    showSearch={true}
                    style={{
                      width: "100%",
                    }}
                  />
                </Col>
                <Col span={width24to8} wrappercol={{ span: 24 }}>
                  <Checkbox
                    labelCol={{ span: 24 }}
                    label="NOC required ?"
                    value={nocRequired}
                    onChange={(e) => setNocRequired(e.target.checked)}
                  ></Checkbox>
                </Col>
              </>
            </When>
          </Row>
        </div>

        <div className="section-wrapper">
          <Title title={generalProjectInfo.title} />
          <Row>
            <Col span={12}>
              <Input
                rules={PROJECT_NAME}
                label="Project Name: "
                name="name"
                readOnly={showDeptInput}
                className="inputField"
                placeholder="Project name"
              />
            </Col>
            <Col span={12}>
              <Input
                label="Property ID"
                name="property_id"
                readOnly={showDeptInput}
                className="inputField"
                maxLength={10}
              />
            </Col>
            <Col span={width < 500 ? 24 : 12}>
              <Select
                rules={PROJECT_RANKING}
                label="Project Ranking "
                name="ranking"
                disabled={showDeptInput}
                className="select-category"
                placeholder="Select project ranking"
                options={rankingListOptions}
              />
            </Col>
            <Col span={width < 500 ? 24 : 12}>
              <Select
                rules={UMOOR_IMPACTED}
                label="Umoor Impacted (can be only one)"
                name="impacted_umoor"
                disabled={showDeptInput}
                className="select-category"
                placeholder="Select umoor impacted"
                options={umoorListOptions}
              />
            </Col>
          </Row>
        </div>

        <div className="section-wrapper">
          <Title title={projectScope.title} />
          <Row>
            <Col span={24}>
              <Input
                isTextArea={true}
                rules={PROJECT_PURPOSE}
                label={projectScope.purpose.label}
                name="purpose"
                readOnly={showDeptInput}
                rows={4}
              />
            </Col>
            <Col span={24}>
              <Input
                isTextArea={true}
                rules={DELIVERABLES}
                label={projectScope.deliverables.label}
                name="deliverables"
                readOnly={showDeptInput}
                rows={4}
              />
            </Col>
            <Col span={24}>
              <Input
                isTextArea={true}
                rules={SCOPE_EXCLUSIONS}
                label={projectScope.scope_exclusions.label}
                name="scope_exclusions"
                readOnly={showDeptInput}
                rows={4}
              />
            </Col>
            <Col span={24}>
              <Input
                isTextArea={true}
                rules={PEOPLE_IMPACTED}
                label={projectScope.people_impacted.label}
                name="people_impacted"
                readOnly={showDeptInput}
                rows={4}
              />
            </Col>
            <Col span={24}>
              <Input
                isTextArea={true}
                rules={SITE_ASSESSMENT}
                label={projectScope.site_assessment.label}
                name="site_assessment"
                readOnly={showDeptInput}
                rows={4}
              />
            </Col>
          </Row>
        </div>

        <Form.Item
          wrappercol={{
            offset: 0,
            span: 24,
          }}
        >
          <div className="btn">
            <Button type="primary" htmlType="submit" className="submitbtn  ">
              Next
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default ProcessA;
