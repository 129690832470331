import { Button, Col, Form, notification, Row } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { InputNumber, When } from "../../components/common";
import { sources } from "../../constants";
import { updateProjectPartial } from "../../redux/action/projectForm";
import { renderAmount } from "../../utils/commonFunction";
import { SPLIT_AMOUNT_VALIDATIONS } from "../../utils/validator";
const SplitForm = ({
  splitForm,
  years,
  splitList,
  split_form_watch,
  setSplitAmountData,
  setIsSplitModalOpen,
  partial = false,
  editId,
  timePeriod,
  viewFormHandler,
  currency
}) => {
  const [api, contextHolder] = notification.useNotification();

  const dispatch = useDispatch();
  const [yearsList, setYearsList] = useState([]);

  const { UMMAL_AMOUNT_VALIDATION, YEAR_AMOUNT_VALIDATION } =
    SPLIT_AMOUNT_VALIDATIONS;
  const totalCost = useCallback(
    (key) => {
      return (
        (split_form_watch &&
          Object.values(split_form_watch).reduce(
            (accumulator = 0, curValue) => {
              return accumulator + Number(curValue[key] ?? 0);
            },
            0
          )) ||
        0
      );
    },
    [split_form_watch]
  );

  const rowSum = ({ ummal_amount, ...objToSum }) => {
    return Object.values(objToSum).reduce((accumulator = 0, curValue) => {
      return accumulator + Number(curValue) || 0;
    }, 0);
  };

  const errorArr = useMemo(() => {
    const errObj = {};
    split_form_watch &&
      Object.values(split_form_watch).map((curValue, i) => {
        errObj[Object?.keys(split_form_watch)[i]] =
          Number(curValue?.ummal_amount) !== Number(rowSum(curValue));
      });
    return errObj;
  }, [split_form_watch]);

  const formValid = useMemo(() => {
    return Object?.values(errorArr)?.some((el) => el === true);
  }, [errorArr]);

  const onFinish = async (values) => {
    if (formValid) {
      return;
    }
    setSplitAmountData(values?.split_form);
    const data = {
      time_period: {
        ...timePeriod,
        breakpoints: values?.split_form,
      },
    };
    partial &&
      dispatch(updateProjectPartial(data, editId)).then(() => {
        viewFormHandler(editId);
        setIsSplitModalOpen(false);
      });
    !partial && setIsSplitModalOpen(false);
  };

  useEffect(() => {
    const arr = Array.from({ length: years }, (_, i) => "year_" + (i + 1));
    setYearsList(arr);
  }, [years]);

  return (
    <>
      <div>
        {contextHolder}
        <Form
          className="splitForm"
          scrollToFirstError
          form={splitForm}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          style={{ overflow: "auto", width: "100%" }}
        >
          <Row className="flex-row-2">
            <div style={{ width: 218 }}>
              <Col>
                {" "}
                <h6 className="text-center py-2">
                  <b>Sources</b>
                </h6>
              </Col>
            </div>
            <div style={{ width: 150 }}>
              <Col>
                <h6 className="text-center py-2">
                  <b>Amil araz</b>
                </h6>
              </Col>
            </div>

            {yearsList?.map((el, i, arr) => {
              return (
                <div style={{ width: 150 }} key={i}>
                  <Col>
                    <h6 className="text-center py-2">
                      <b>{el.replace("_", "   ")}</b>
                    </h6>
                  </Col>
                </div>
              );
            })}
          </Row>

          <Form.List name="split_form">
            {(fields, { add, remove }) => {
              return (
                <>
                  {splitList &&
                    splitList?.map(({ id, value, ...restField }, index) => (
                      <>
                        <Row key={id} className="flex-row-2">
                          <div style={{ width: 240 }} className="sources">
                            <Col style={{ padding: "0 0 0 14px" }}>
                              {" "}
                              <b>{sources[value ?? id]}</b>{" "}
                            </Col>
                          </div>
                          <div style={{ width: 150 }}>
                            <Col>
                              <InputNumber
                                rules={UMMAL_AMOUNT_VALIDATION("")}
                                name={[id, "ummal_amount"]}
                                readOnly
                                curr={currency}
                              />
                            </Col>
                          </div>
                          {yearsList?.map((el, i, arr) => {
                            return (
                              <div style={{ width: 150 }} key={i}>
                                <Col>
                                  <InputNumber
                                    rules={YEAR_AMOUNT_VALIDATION("")}
                                    name={[id, el]}
                                    curr={currency}
                                    className={
                                      errorArr[id] &&
                                      "ant-input-number-affix-wrapper-status-error"
                                    }
                                  />
                                </Col>
                              </div>
                            );
                          })}
                        </Row>
                        {splitList.length === index + 1 && (
                          <Row className="flex-row-2">
                            <div style={{ width: 240 }} className="sources">
                              <Col style={{ padding: "0 0 0 14px" }}>
                                {" "}
                                <b>{"Total project cost"}</b>{" "}
                              </Col>
                            </div>
                            <div className="Total" style={{ width: 250 }}>
                              <Col>
                                <h4>
                                  <b>
                                    {renderAmount(totalCost("ummal_amount"),currency)}
                                  </b>
                                </h4>
                              </Col>
                            </div>
                            {yearsList?.map((el, i, arr) => {
                              return (
                                <div
                                  className="Total"
                                  style={{ width: 144, marginLeft: 5 }}
                                >
                                  <Col>
                                    <h4>
                                      <b>{renderAmount(totalCost(el),currency)}</b>
                                    </h4>
                                  </Col>
                                </div>
                              );
                            })}
                          </Row>
                        )}
                      </>
                    ))}
                </>
              );
            }}
          </Form.List>

          <Form.Item style={{ textAlign: "left", marginTop: 5 }}>
            <div className="btn">
              <Button
                type="primary"
                htmlType="submit"
                className="submitbtn  save-btn"
              >
                Save
              </Button>
            </div>
            <div className="error-message">
              <When condition={formValid}>
                Total splitted amount of each source should be equals to Total
                source amount
              </When>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default SplitForm;
