// loader
export const LOADER_START = "LOADER_START";
export const LOADER_STOP = "LOADER_STOP";

// auth type
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

//lookups
export const UMOOR_LIST = "UMOOR_LIST";
export const DEPARTMENT_LIST = "DEPARTMENT_LIST";
export const PROJECT_CATEGORIES = "PROJECT_CATEGORIES";
export const SUB_CATEGORIES = "SUB_CATEGORIES";
//project form

export const PROJECT_PRE_DATA = "PROJECT_PRE_DATA";
export const PROJECT_LIST = "PROJECT_LIST";
export const CURRENT_PAGE = "CURRENT_PAGE";

//dashboard
export const DASHBOARD_DATA = "DASHBOARD_DATA";
