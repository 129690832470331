import { Col, Form, Row } from "antd";
import React from "react";
import { Button, Select, Title, When } from "../../../components/common";
import { BUTTON_DETAILS, projectViewConstants } from "../../../constants";
import { PROJECT_VIEW_VALIDATIONS } from "../../../utils/validator";

function NOCForm({
  nocForm,
  stackDeptDropdown,
  stackHolderListNOC,
  status_watch,
  department_watch,
  setIsModalOpen,
}) {
  const { NOC_REQUIRED } = PROJECT_VIEW_VALIDATIONS;
  const { button } = projectViewConstants;
  const nocList = ["Issued", "Declined", "N/A"];

  const nocListOptions =
    nocList &&
    nocList?.map((el) => {
      return {
        label: el,
        value: el,
      };
    });

  const stackHolderListNOCtOptions =
    stackHolderListNOC &&
    stackHolderListNOC?.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });
  return (
    <>
      <Title title="NOC Details" />
      <Form
        scrollToFirstError
        form={nocForm}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrappercol={{
          span: 24,
        }}
        layout="vertical"
        autoComplete="off"
      >
        <Row className="bg-white">
          <Col span={10}>
            <Select
              rules={NOC_REQUIRED}
              label="NOC status"
              name="status"
              options={nocListOptions}
              className="select-category"
              placeholder="Select Status"
            />
          </Col>
          <When condition={stackDeptDropdown}>
            <Col span={10} offset={2}>
              <Select
                // rules={STAKEHOLDER}
                label="Stakeholder departments:"
                name="department"
                className="select-category"
                placeholder="Select Stakeholder departments"
                // style={{ width: '100%' }}
                options={stackHolderListNOCtOptions}
              />
            </Col>
          </When>
        </Row>
        <Row className="bg-white">
          <Col span={3} style={{ textAlign: "start" }}>
            <Button
              name={button.save}
              size="large"
              htmlType="submit"
              className="primary-button"
              onClick={() =>
                status_watch &&
                (stackDeptDropdown ? department_watch : true) &&
                setIsModalOpen(BUTTON_DETAILS.NOC)
              }
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default NOCForm;
