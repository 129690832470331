import { Button } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../customHook/useAuth";
import LoginForm from "./LoginForm";
import "./login.scss";

export function Login() {
  const { loggedIn: authUser, isRoleSelected } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser) {
      // if (isRoleSelected) {
      navigate("/dashboard");
      // } else {
      //   navigate("/Profile");
      // }
    } else {
      localStorage.clear();
    }
  }, []);

  const loginHandler = (id) => {
    window.location.replace(
      `${
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000"
          : "https://pms.alvazarat.org"
      }/login/?id=${id}/`
    );
  };
  return (
    <>
      <div className="login-form-container">
        {process.env.NODE_ENV === "development" && (
          <div className="login-btn-warpper">
            <Button
              className="login-button"
              style={{ position: "" }}
              onClick={() => loginHandler("J67ClCEO7/xFypJRqcJcyQ==")}
            >
              <b>Login ummal</b>
            </Button>
            <Button
              className="login-button"
              style={{ position: "" }}
              onClick={() => loginHandler("HSfNBt19j+RFypJRqcJcyQ==")}
            >
              <b>Login massol</b>
            </Button>
            <Button
              className="login-button"
              style={{ position: "" }}
              onClick={() => loginHandler("P69yDNDhET1FypJRqcJcyQ==")}
            >
              <b>Login Admin</b>
            </Button>
            <Button
              className="login-button"
              style={{ position: "" }}
              onClick={() => loginHandler("PE+MAHZMY0BFypJRqcJcyQ==")}
            >
              <b>Owner Dept</b>
            </Button>
            <Button
              className="login-button"
              style={{ position: "" }}
              onClick={() => loginHandler("J67ClCEO7/xFypJRqcJcyQ==")}
            >
              <b>Stake Dept</b>
            </Button>
            <Button
              className="login-button"
              style={{ position: "" }}
              onClick={() => loginHandler("OC2RBPv/t2FFypJRqcJcyQ==")}
            >
              <b>Login manzuri</b>
            </Button>
            <Button
              className="login-button"
              style={{ position: "" }}
              onClick={() => loginHandler("sA2/AvkrgYZFypJRqcJcyQ==")}
            >
              <b>Login Board B</b>
            </Button>
            <Button
              className="login-button"
              style={{ position: "" }}
              onClick={() => loginHandler("+vkLmZTUbwBFypJRqcJcyQ==")}
            >
              <b>Login Board A</b>
            </Button>
            <Button
              className="login-button"
              style={{ position: "" }}
              onClick={() => loginHandler("sA2/AvkrgYZFypJRqcJcyQ==")}
            >
              <b>Login SU</b>
            </Button>
          </div>
        )}
        <div className="login-form-wrapper">
          <LoginForm />
        </div>
      </div>
    </>
  );
}

export default Login;
