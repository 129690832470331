import { CaretRightOutlined } from "@ant-design/icons";
import { Col, Collapse, Row } from "antd";
import React, { useMemo } from "react";
import ChatPage from "../../../components/Chat/ChatPage";
import { Tag, When } from "../../../components/common";
import FilesListView from "../../../components/FileManagment/FilesListView";
import { panelStyle, projectViewConstants, sources } from "../../../constants";
import {
  calculateSums,
  renderAmount,
  totalAmountBoard,
  totalAmountDept,
  totalAmountUmmal,
} from "../../../utils/commonFunction";
import SectionView from "../SectionView";

const { Panel } = Collapse;
function PanelSection({ data, width, totalProjectCost, totalDepartmentCost }) {
  const {
    qardan,
    mozeInfo,
    generalInfo,
    sourceOfFunding,
    projectScope,
    risk,
    remarks,
    total,
  } = projectViewConstants;

  const currency = data?.jamaat?.currency
  const showBoard = useMemo(
    () =>
      data?.funding_sources &&
      (data?.funding_sources[0]?.board_amount === 0 ||
        data?.funding_sources[0]?.board_amount > 0)
  );

  const showDept = useMemo(
    () =>
      data?.funding_sources &&
      (data?.funding_sources[0]?.department_amount === 0 ||
        data?.funding_sources[0]?.department_amount > 0)
  );

  const showdeptQardan = useMemo(
    () =>
      data?.qardan_hasanah_details?.qardan_financial[0]?.department_amount ===
        0 ||
      data?.qardan_hasanah_details?.qardan_financial[0]?.department_amount > 0
  );

  const showBoardQardan = useMemo(
    () =>
      data?.qardan_hasanah_details?.qardan_financial[0]?.board_amount === 0 ||
      data?.qardan_hasanah_details?.qardan_financial[0]?.board_amount > 0
  );

  const mozeData = [
    {
      label: mozeInfo.moze,
      value: data?.jamaat?.name,
      span: width < 500 ? 24 : 12,
    },
    {
      label: mozeInfo.jamiat,
      value: data?.jamaat?.jamiat,
      span: width < 500 ? 24 : 12,
    },
    {
      label: mozeInfo.gents,
      value: data?.jamaat?.mumineen_gents_count,
      span: width < 500 ? 12 : 6,
    },
    {
      label: mozeInfo.ladies,
      value: data?.jamaat?.mumineen_ladies_count,
      span: width < 500 ? 12 : 6,
    },
    {
      label: mozeInfo.children,
      value: data?.jamaat?.mumineen_children_count,
      span: width < 500 ? 12 : 6,
    },
    {
      label: mozeInfo.total,
      value: data?.jamaat?.mumineen_house_count,
      span: width < 500 ? 12 : 6,
    },
  ];

  const generalInfoData = [
    {
      label: generalInfo.name,
      value: data?.name,
      span: width < 500 ? 24 : 12,
    },
    {
      label: generalInfo.sponser,
      value:
        data?.created_by === data?.jamaat?.ummal ? (
          <Tag>{data?.created_by}</Tag>
        ) : (
          <>
            <Tag>{data?.created_by}</Tag>
            <Tag>{data?.jamaat?.ummal}</Tag>
          </>
        ),
      span: width < 500 ? 24 : 12,
    },
    {
      label: generalInfo.category,
      value: data?.category?.name,
      span: width < 500 ? 24 : 12,
    },
    {
      label: generalInfo?.tier1,
      value: data?.sub_category_1?.name,
      span: width < 500 ? 24 : 6,
    },
    {
      label: generalInfo?.tier2,
      value: data?.sub_category_2?.name,
      span: width < 500 ? 24 : 6,
    },
    {
      label: generalInfo.owner,
      value: (
        <div className="general-info__owner-list">
          <span className="box-view view-2">
            {data?.owner_department_obj && data?.owner_department_obj.name}
          </span>
        </div>
      ),
      span: width < 500 ? 24 : 12,
    },
    {
      label: generalInfo.stake,
      value: (
        <div className="general-info__stakeholder-list">
          {data?.stakeholder_department_objs &&
            data?.stakeholder_department_objs?.map((el) => (
              <span className="box-view view-2">{el?.name}</span>
            ))}
        </div>
      ),
      span: width < 500 ? 24 : 12,
    },
    {
      label: generalInfo.rank,
      value: data?.ranking,
      span: 12,
    },
    {
      label: generalInfo.umoor,
      value: data?.impacted_umoor_obj && data?.impacted_umoor_obj?.name,
      span: 12,
    },
    {
      label: generalInfo.deptRating,
      value: data?.evaluation_rating,
      span: 24,
      view: data?.evaluation_rating > 0,
    },
  ];

  const projectScopeData = [
    {
      label: projectScope.purpose,
      value: data?.purpose,
    },
    {
      label: projectScope.deliverables,
      value: data?.deliverables,
    },
    {
      label: projectScope.scope,
      value: data?.scope_exclusions,
    },
    {
      label: projectScope.people,
      value: data?.people_impacted,
    },
    {
      label: projectScope.site,
      value: data?.site_assessment,
    },
  ];
  const riskData = [
    {
      label: risk.type,
      key: "type",
      span: width < 500 ? 6 : 4,
    },
    {
      label: risk.knownRisk,
      key: "risk",
      span: width < 500 ? 10 : 12,
    },
    {
      label: risk.mitigation,
      key: "mitigation_plan",
      span: 8,
    },
  ];
  const timePeriodData = [
    {
      label: risk.years,
      key: "years",
      span: 12,
    },
    {
      label: risk.months,
      key: "months",
      span: 12,
    },
  ];
  return (
    <Collapse
      defaultActiveKey={["4"]}
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{
        backgroundColor: "rgb(234, 245, 255)",
        marginTop: 0,
        // padding:'10px'
      }}
    >
      <SectionView data={mozeData} title={mozeInfo.title} />
      <SectionView data={generalInfoData} title={generalInfo.title} />

      <Panel header={projectScope.title} key="3" style={panelStyle}>
        <SectionView data={projectScopeData} separateView={true} />
      </Panel>
      {data?.can_view_finance && (
        <Panel header={sourceOfFunding.title} key="4" style={panelStyle}>
          <div
            className="row tab-brd-1"
            style={width < 500 ? { overflow: "auto", width: 520 } : {}}
          >
            <Row className="margin-btm">
              <Col span={width < 500 ? 24 : showDept ? 24 : 12}>
                <Row>
                  <Col
                    span={width < 500 ? (showDept ? 6 : 8) : showDept ? 6 : 16}
                  >
                    <h4>
                      <b className="phoneTitle">{sourceOfFunding.title}</b>
                    </h4>
                  </Col>
                  {!data?.is_department_created && (
                    <Col
                      span={width < 500 ? (showDept ? 6 : 8) : showDept ? 4 : 8}
                    >
                      <h4>
                        <b className="phoneTitle">{sourceOfFunding.amilAraz}</b>
                      </h4>
                    </Col>
                  )}
                  {showDept && (
                    <Col span={width < 500 ? 6 : showDept ? 4 : 6}>
                      <h4>
                        <b className="phoneTitle">{sourceOfFunding.deptAraz}</b>
                      </h4>
                    </Col>
                  )}
                  {showBoard && (
                    <Col span={width < 500 ? 6 : showDept ? 4 : 6}>
                      <h4>
                        <b className="phoneTitle">
                          {sourceOfFunding.boardAraz}
                        </b>
                      </h4>
                    </Col>
                  )}
                </Row>
                {data?.funding_sources &&
                  data?.funding_sources?.map((el) => {
                    return (
                      <>
                        <div className="row merg">
                          <Row>
                            <Col
                              span={
                                width < 500
                                  ? showDept
                                    ? 6
                                    : 8
                                  : showDept
                                  ? 6
                                  : 16
                              }
                            >
                              <div
                                className={
                                  el.id === "Enayat"
                                    ? "red phoneTitle"
                                    : "phoneTitle"
                                }
                              >
                                {el?.id} :{" "}
                              </div>
                            </Col>
                            {!data?.is_department_created && (
                              <Col
                                span={
                                  width < 500
                                    ? showDept
                                      ? 6
                                      : 8
                                    : showDept
                                    ? 4
                                    : 8
                                }
                              >
                                <div
                                  className={
                                    el.id === "Enayat"
                                      ? "red phoneTitle"
                                      : "phoneTitle"
                                  }
                                >
                                  {renderAmount(el?.ummal_amount, currency)}
                                </div>
                              </Col>
                            )}
                            {(el.department_amount === 0 ||
                              el.department_amount > 0) && (
                              <Col span={width < 500 ? 6 : 4}>
                                <div>{renderAmount(el?.department_amount,currency)}</div>
                              </Col>
                            )}
                            {(el.board_amount === 0 || el.board_amount > 0) && (
                              <Col span={width < 500 ? 6 : 4}>
                                <div>{renderAmount(el?.board_amount,currency)}</div>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </>
                    );
                  })}
                {data?.funding_sources && data?.funding_sources?.length > 0 && (
                  <div className="row">
                    <Row>
                      <Col
                        span={
                          width < 500 ? (showDept ? 6 : 8) : showDept ? 6 : 16
                        }
                      >
                        <div className="totalAmount">
                          <h4>
                            <b className="phoneTitle">{total}</b>
                          </h4>
                        </div>
                      </Col>
                      {!data?.is_department_created && (
                        <Col
                          span={
                            width < 500 ? (showDept ? 6 : 8) : showDept ? 4 : 8
                          }
                          className="text-blue mb-0"
                        >
                          <div>
                            <b className="phoneTitle">
                              {renderAmount(
                                totalAmountUmmal(
                                  data?.funding_sources && data?.funding_sources
                                ),currency
                              )}
                            </b>
                          </div>
                        </Col>
                      )}
                      {showDept && (
                        <Col
                          span={width < 500 ? 6 : 4}
                          className="text-blue mb-0"
                        >
                          <div>
                            <b className="phoneTitle">
                              {" "}
                              {renderAmount(
                                totalAmountDept(
                                  data?.funding_sources && data?.funding_sources
                                ),currency
                              )}
                            </b>
                          </div>
                        </Col>
                      )}
                      {showBoard && (
                        <Col
                          span={width < 500 ? 6 : 4}
                          className="text-blue mb-0"
                        >
                          <div>
                            <b className="phoneTitle">
                              {" "}
                              {renderAmount(
                                totalAmountBoard(
                                  data?.funding_sources && data?.funding_sources
                                ),currency
                              )}
                            </b>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                )}
              </Col>

              {data?.qardan_hasanah_details?.qardan_financial?.length > 0 && (
                <Col span={width < 500 ? 24 : showdeptQardan ? 24 : 12}>
                  <Row>
                    <Col
                      span={
                        width < 500 ? (showDept ? 6 : 8) : showDept ? 6 : 16
                      }
                    >
                      <h4>
                        <b className="phoneTitle">{qardan.qardan}</b>
                      </h4>
                    </Col>
                    {!data?.is_department_created && (
                      <Col
                        span={
                          width < 500
                            ? showDept
                              ? 6
                              : 8
                            : showdeptQardan
                            ? 4
                            : 8
                        }
                      >
                        <h4>
                          <b className="phoneTitle">{qardan.amilAraz}</b>
                        </h4>
                      </Col>
                    )}
                    {showdeptQardan && (
                      <Col span={width < 500 ? 6 : 4}>
                        <h4>
                          <b className="phoneTitle">{qardan.deptAraz}</b>
                        </h4>
                      </Col>
                    )}
                    {showBoardQardan && (
                      <Col span={width < 500 ? 6 : 4}>
                        <h4>
                          <b className="phoneTitle">{qardan.boardAraz}</b>
                        </h4>
                      </Col>
                    )}
                  </Row>
                  {data?.qardan_hasanah_details &&
                    data?.qardan_hasanah_details?.qardan_financial &&
                    data?.qardan_hasanah_details?.qardan_financial?.map(
                      (el) => {
                        return (
                          <>
                            <div className="row merg">
                              <Row className="">
                                <Col
                                  span={
                                    width < 500
                                      ? showDept
                                        ? 6
                                        : 8
                                      : showdeptQardan
                                      ? 6
                                      : 16
                                  }
                                >
                                  <div>{el?.id} : </div>
                                </Col>
                                {!data?.is_department_created && (
                                  <Col
                                    span={
                                      width < 500
                                        ? showDept
                                          ? 6
                                          : 8
                                        : showdeptQardan
                                        ? 4
                                        : 8
                                    }
                                  >
                                    <div> {renderAmount(el?.ummal_amount,currency)}</div>
                                  </Col>
                                )}
                                {(el.department_amount === 0 ||
                                  el.department_amount > 0) && (
                                  <Col span={width < 500 ? 6 : 4}>
                                    <div>
                                      {" "}
                                      {renderAmount(el?.department_amount,currency)}
                                    </div>
                                  </Col>
                                )}

                                {(el.board_amount === 0 ||
                                  el.board_amount > 0) && (
                                  <Col span={width < 500 ? 6 : 4}>
                                    <div> {renderAmount(el?.board_amount,currency)}</div>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </>
                        );
                      }
                    )}

                  {data?.qardan_hasanah_details &&
                    data?.qardan_hasanah_details?.qardan_financial?.length >
                      0 && (
                      <div className="row">
                        <Row>
                          <Col
                            span={
                              width < 500
                                ? showDept
                                  ? 6
                                  : 8
                                : showdeptQardan
                                ? 6
                                : 16
                            }
                          >
                            <div className="totalAmount">
                              <h4>
                                <b className="phoneTitle">
                                  {qardan.totalQardan}
                                </b>
                              </h4>
                            </div>
                          </Col>
                          {!data?.is_department_created && (
                            <Col
                              span={
                                width < 500
                                  ? showDept
                                    ? 6
                                    : 8
                                  : showdeptQardan
                                  ? 4
                                  : 8
                              }
                              className="text-blue mb-0"
                            >
                              <div>
                                <b>
                                  {" "}
                                  {renderAmount(
                                    totalAmountUmmal(
                                      data?.qardan_hasanah_details &&
                                        data?.qardan_hasanah_details
                                          ?.qardan_financial
                                    ),currency
                                  )}
                                </b>
                              </div>
                            </Col>
                          )}

                          {showdeptQardan && (
                            <Col
                              span={width < 500 ? 6 : 4}
                              className="text-blue mb-0"
                            >
                              <div>
                                <b className="phoneTitle">
                                  {renderAmount(
                                    totalAmountDept(
                                      data?.qardan_hasanah_details &&
                                        data?.qardan_hasanah_details
                                          ?.qardan_financial
                                    ),currency
                                  )}
                                </b>
                              </div>
                            </Col>
                          )}
                          <When condition={showBoardQardan}>
                            <Col
                              span={width < 500 ? 6 : 4}
                              className="text-blue mb-0"
                            >
                              <div>
                                <b className="phoneTitle">
                                  {" "}
                                  {renderAmount(
                                    totalAmountBoard(
                                      data?.qardan_hasanah_details &&
                                        data?.qardan_hasanah_details
                                          ?.qardan_financial
                                    ),currency
                                  )}
                                </b>
                              </div>
                            </Col>
                          </When>
                        </Row>
                      </div>
                    )}
                </Col>
              )}
            </Row>
          </div>

          <div className="row tab-brd">
            <Row>
              <Col span={width < 500 ? 16 : 8}>
                <div className="totalAmount" style={{ width: 140 }}>
                  <h4>
                    <b className="phoneTitle">Total Project Cost</b>
                  </h4>
                </div>
              </Col>
              <Col span={width < 500 ? 8 : 4} className="text-blue mb-0">
                <div>
                  {!data?.is_department_created ? (
                    <b>{renderAmount(totalProjectCost,currency)}</b>
                  ) : (
                    <b>{renderAmount(totalDepartmentCost,currency)}</b>
                  )}
                </div>
              </Col>
            </Row>
          </div>

          {data?.qardan_hasanah_details &&
            data?.qardan_hasanah_details?.repayment_plan?.length > 0 && (
              <>
                <div className="row tab-brd margin-top">
                  <Row>
                    <Col span={width < 500 ? 16 : 8}>
                      <h4>
                        <b className="phoneTitle">{qardan.repayment}</b>
                      </h4>
                    </Col>
                    <Col span={width < 500 ? 8 : 4}>
                      <h4>
                        <b className="phoneTitle">Amount</b>
                      </h4>
                    </Col>
                  </Row>
                </div>
                {data?.qardan_hasanah_details &&
                  data?.qardan_hasanah_details?.repayment_plan &&
                  data?.qardan_hasanah_details?.repayment_plan?.map((el) => {
                    return (
                      <>
                        <div className="row tab-brd">
                          <Row>
                            <Col span={width < 500 ? 16 : 8}>
                              <div>{el?.id} : </div>
                            </Col>
                            <Col span={width < 500 ? 8 : 4}>
                              <div> {renderAmount(el?.ummal_amount,currency)}</div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    );
                  })}
                <div className="row tab-brd">
                  <Row>
                    <Col span={width < 500 ? 16 : 8}>
                      <div className="totalAmount">
                        <h4>
                          <b className="phoneTitle">{qardan.totalRepayment}</b>
                        </h4>
                      </div>
                    </Col>
                    <Col span={width < 500 ? 8 : 4} className="text-blue mb-0">
                      <div>
                        <b>
                          {" "}
                          {renderAmount(
                            totalAmountUmmal(
                              data?.qardan_hasanah_details &&
                                data?.qardan_hasanah_details?.repayment_plan
                            ),currency
                          )}
                        </b>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
        </Panel>
      )}
      <Panel header={risk.title} key="5" style={panelStyle}>
        <SectionView data={riskData} vertical={true} childList={data?.risks} />
        <SectionView
          data={timePeriodData}
          title={risk.timePeriod}
          vertical={true}
          childList={[
            {
              years: data?.time_period?.years,
              months: data?.time_period?.months,
            },
          ]}
        />
      </Panel>
      <Panel header={remarks} key="6" style={panelStyle}>
        <ChatPage remarksArray={data?.remarks} />
      </Panel>
      {data?.time_period?.breakpoints &&
        data?.time_period?.years &&
        Number(data?.time_period?.years) > 1 && (
          <Panel header="Split Amount" key="7" style={panelStyle}>
            <div
              className="row tab-brd-1"
              style={width < 500 ? { width: 577, overflow: "auto" } : {}}
            >
              <Row className="margin-btm">
                <Col span={24}>
                  <Row>
                    <Col span={6}>
                      <h4>
                        <b className="phoneTitle">Sources</b>
                      </h4>
                    </Col>
                    {data?.time_period &&
                      data?.time_period?.breakpoints &&
                      Object.values(data?.time_period?.breakpoints)[0] &&
                      Object.keys(
                        Object.values(data?.time_period?.breakpoints)[0]
                      )?.map((hd, i) => {
                        if (hd === "ummal_amount") {
                          return <></>;
                        }
                        return (
                          <Col span={3} key={i}>
                            <h4>
                              <b className="phoneTitle">
                                {hd?.replace("_", " ") ?? ""}
                              </b>
                            </h4>
                          </Col>
                        );
                      })}
                  </Row>
                  {data?.time_period?.breakpoints &&
                    Object.values(data?.time_period?.breakpoints)?.map(
                      (el, i) => {
                        delete el?.ummal_amount;
                        return (
                          <Row>
                            <Col span={6}>
                              <h4>
                                <b className="phoneTitle">
                                  {
                                    sources[
                                      Object.keys(
                                        data?.time_period?.breakpoints
                                      )[i]
                                    ]
                                  }
                                </b>
                              </h4>
                            </Col>
                            {el &&
                              Object.values(el)?.map((vle) => {
                                return (
                                  <Col span={3} key={i}>
                                    <h4>
                                      <b className="phoneTitle">
                                        {renderAmount(vle ?? "",currency)}
                                      </b>
                                    </h4>
                                  </Col>
                                );
                              })}
                          </Row>
                        );
                      }
                    )}
                  <Row>
                    <Col span={6}>
                      <h4>
                        <b className="phoneTitle">Total yearly cost</b>
                      </h4>
                    </Col>
                    {calculateSums(
                      Object.values(data?.time_period?.breakpoints)
                    )?.yearWise?.map((item, i) => {
                      return (
                        <Col span={3} key={i}>
                          <h4>
                            <b className="phoneTitle">
                              {renderAmount(item ?? "",currency)}
                            </b>
                          </h4>
                        </Col>
                      );
                    })}
                  </Row>
                  {/* <Row>
              <Col span={6}>
                <h4>
                  <b className="phoneTitle">Total Cost </b>
                </h4>
              </Col>

              <Col
                span={
                  calculateSums(
                    Object.values(data?.time_period?.breakpoints)
                  )?.yearWise?.length * 3
                }
                style={{ textAlign: "center" }}
              >
                <h4>
                  <b className="phoneTitle">
                    {renderAmount(
                      calculateSums(
                        Object.values(data?.time_period?.breakpoints)
                      )?.total
                    )}
                  </b>
                </h4>
              </Col>
            </Row> */}
                </Col>
              </Row>
            </div>
          </Panel>
        )}
      <Panel header="Attachments" key="8" style={panelStyle}>
      <p style={{marginLeft: '1vw', color: 'red' }}>
            Kindly upload the required documents before the final submission:
          <ol>
            <li>Aamilsaheb Araz</li>
            <li>Jamaat resolution(if required)</li>
            <li>Khidmat karnar araz (if required)</li>
            <li>Existing photos</li>
          </ol>
          </p>
        {data &&
        data?.attachment_objs &&
        Array.isArray(data?.attachment_objs) &&
        data?.attachment_objs?.length > 0 ? (
          <FilesListView data={data?.attachment_objs} />
        ) : (
          <Tag>No Files Found</Tag>
        )}
      </Panel>
      {data && data?.stakeholder_department_objs && Array.isArray(data?.stakeholder_department_objs) &&
      data?.stakeholder_department_objs?.length > 0 ? 
        (
          <Panel header="NOC Details" key="9" style={panelStyle}>
            <div className="container">
              <div className="row tab-brd">
                <Row>
                {["Department Name", "Status", "Updated On"]?.map(
                      (item, i) => {
                        return (
                          <>
                            <Col Key={i} span={width < 500 ? 24 : 6}>
                              <h4>
                                <b className="phoneTitle">
                                  {item}
                                </b>
                              </h4>
                            </Col>
                          </>
                        );
                      }
                    )}

                </Row>
                {Object.values(data?.stakeholder_department_objs || {})?.map((department, index) => {
                  var dept_noc_obj = data?.noc_details[department.id] || undefined;
                  return (
                    <Row Key={index}>
                      <>
                        <Col span={width < 500 ? 24 : 6}>
                          <div className="phoneTitle">{dept_noc_obj ? dept_noc_obj?.department_name : department.name}</div> 
                        </Col>
                        <Col span={width < 500 ? 24 : 6}>
                          <div className="phoneTitle">{dept_noc_obj ? dept_noc_obj?.status : "Pending"}</div> 
                        </Col>
                        <Col span={width < 500 ? 24 : 6}>
                          <div className="phoneTitle">{dept_noc_obj ? dept_noc_obj?.updated_on : "NA"}</div> 
                        </Col>
                      </>
                    </Row>
                  );
                })}
              </div>
            </div>
          </Panel>
        ) : ""}
    </Collapse>
  );
}

export default PanelSection;
