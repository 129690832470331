import { apiClient } from "../service/ApiConfig";
import { PATH } from "../service/apiConstant";
import { DASHBOARD_DATA } from "../service/types";
import { loadingStart, loadingStop } from "./loader";

export const getDashboardData = () => (dispatch) => {
  dispatch(loadingStart());
  return new Promise((resolve, reject) => {
    apiClient({
      method: "GET",
      url: PATH.Dashboard.dashboardCounts(),
      headers: { "Content-type": "application/json" },
    })
      .then(async (response) => {
        dispatch({
          type: DASHBOARD_DATA,
          payload: response && response.data,
        });
        resolve(response && response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(loadingStop()));
  });
};
