import { FieldTimeOutlined } from "@ant-design/icons";
import { Avatar, Steps } from "antd";
import moment from "moment";
import React from "react";
import { APPLICATION_STATUS } from "../../constants";

function StatusHistory({ data }) {
  return (
    <div className="section-wrapper mt-3">
      <div className="section-wrapper">
        <Steps
          direction="vertical"
          // size={width<500?'small':'default'}
          items={data?.status_obj?.history?.map((item, index, arr) => {
            const description = (
              <div style={{ position: "relative" }}>
                {item?.reason ? (
                  <p>
                    <strong>Reason : </strong>
                    {item?.reason}
                  </p>
                ) : (
                  ""
                )}
                <div
                  style={{
                    position: "absolute",
                    top: "-27px",
                    right: 0,
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  {item?.stage}
                </div>
                <div
                  className="userName"
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  {" "}
                  <i>by </i>
                  {item?.user}
                </div>
                <div
                  className="userName"
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  {" "}
                  <i>on </i>{" "}
                  {moment(item?.updated_at).format("DD-MM-yyyy  h:mma")}
                </div>
              </div>
            );

            const stepIcon = item?.status === APPLICATION_STATUS.PENDING && (
              <Avatar
                shape="circle"
                style={{
                  backgroundColor: "#fcba03",
                  lineHeight: "normal",
                }}
                icon={<FieldTimeOutlined style={{ color: "" }} />}
              />
            );

            const stepStatus =
              item?.status === APPLICATION_STATUS.PENDING
                ? "process"
                : item?.status === APPLICATION_STATUS.REJECTED
                ? "error"
                : "finish";

            return {
              key: index + 1,
              title: item?.status,
              description: description,
              icon: stepIcon,
              status: stepStatus,
            };
          })}
        />
      </div>
    </div>
  );
}

export default StatusHistory;
