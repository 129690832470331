export const ROLE_TYPE = {
  UMMAL: 1 || "1",
  MASOOL: 2 || "2",
  MUSAID: 3 || "3",
  ADMIN: 4 || "4",
  DEPARTMENT: 5 || "5",
  MANZURI: 6 || "6",
  BOARD_B: 7 || "7",
  BOARD_A: 8 || "8",
  SUPER_USER: 9 || "9",
};

export const ROLE_NAME = {
  UMMAL: "ummal",
  MASOOL: "masool",
  MUSAID: "musaid",
  DEPARTMENT: "department",
  BOARD: "board",
  ADMIN: "admin",
};

export const APPLICATION_STATUS = {
  APPROVE: "Approved",
  PENDING: "Pending",
  REJECTED: "Rejected",
  REVIEW: "Review",
  CHANGE: "Change",
  HOLD: "Hold",
  ON_GOING: "On Going/Manzuri Done",
  ALREADY_COMPLETE: "Already Complete",
  FORWARDED_TO_DEPARTMENT: "Forwarded to Department",
};

export const BUTTON_COLOR = {
  [APPLICATION_STATUS.APPROVE]: "btn-success",
  [APPLICATION_STATUS.FORWARDED_TO_DEPARTMENT]: "btn-basic",
  [APPLICATION_STATUS.REJECTED]: "btn-reject",
  [APPLICATION_STATUS.REVIEW]: "btn-basic",
  [APPLICATION_STATUS.CHANGE]: "btn-basic",
  [APPLICATION_STATUS.HOLD]: "btn-basic",
  [APPLICATION_STATUS.PENDING]: "btn-basic",
  NOC: "btn-basic",
  Update: "btn-basic",
};

export const APPLICATION_STAGES = {
  PENDING_APPROVAL: "Pending Approval",
  MASOOL_APPROVAL: "SA Masool",
  DATA_VERIFICATION: "Data Verification",
  DEPARTMENT_APPROVAL: "Department Approval",
  BOARD_APPROVAL: "Board Approval",
  COMPLETE: "Complete",
};

export const REPORT_TYPES = {
  BOARD_STATISTICS: "board_statistics_report",
  DEPARTMENT_STATISTICS: "department_statistics_report",
  BOARD_APPROVED: "board_approved_report",
  ENAYAT_DISPATCHED: "enayat_dispatched_report",
};

export const PERMISSION_TYPES = {
  APPROVE_PROJECTS: "application.approve_project",
  ADD_PROJECTS: "application.add_project",
};
export const BUTTON_DETAILS = {
  APPROVE: {
    message: "Would you like to approve this application?",
    type: APPLICATION_STATUS.APPROVE,
    status: APPLICATION_STATUS.APPROVE,
    label: "Remarks",
    name: "Approve",
  },
  REJECT: {
    message: "Are you sure you want to reject this application?",
    type: APPLICATION_STATUS.REJECTED,
    status: APPLICATION_STATUS.REJECTED,
    label: "Reason",
    name: "Reject",
  },
  REVIEW: {
    message: "Are you sure you want to review this application?",
    type: APPLICATION_STATUS.REVIEW,
    status: APPLICATION_STATUS.REVIEW,
    label: "Reason",
    name: "Review",
  },
  CHANGE: {
    message: "Are you sure you want to change this application?",
    type: APPLICATION_STATUS.CHANGE,
    status: APPLICATION_STATUS.CHANGE,
    label: "Reason",
    name: "Change",
  },
  HOLD: {
    message: "Are you sure you want to hold this application?",
    type: APPLICATION_STATUS.HOLD,
    status: APPLICATION_STATUS.HOLD,
    label: "Reason",
    name: "Hold",
  },
  UN_HOLD: {
    message: "Are you sure you want to un-hold this application?",
    type: APPLICATION_STATUS.PENDING,
    status: APPLICATION_STATUS.PENDING,
    label: "Reason",
    name: "Un Hold",
  },
  FORWARD: {
    message: "Are you sure you want to forward this application?",
    type: APPLICATION_STATUS.FORWARDED_TO_DEPARTMENT,
    status: APPLICATION_STATUS.FORWARDED_TO_DEPARTMENT,
    label: "Reason",
    name: "Forward",
  },
  NOC: {
    message: "Would you like to save this details?",
    type: "Update",
    nocDept: true,
    label: "Remarks",
    name: "Update",
  },
  ADD_REMARKS: {
    message: "Would you like to add a remark?",
    type: "Update",
    remarks: true,
    label: "Remarks",
    name: "Save",
  },
};

export const MANAGE_BUTTON_DETAILS = {
  APPROVE: { ...BUTTON_DETAILS.APPROVE, manageDept: true },
  REVIEW: { ...BUTTON_DETAILS.REVIEW, manageDept: true },
  CHANGE: { ...BUTTON_DETAILS.CHANGE, manageDept: true },
  FORWARD: { ...BUTTON_DETAILS.FORWARD, manageDept: true },
};

export const sources = {
  Others: "Qardan Hasanah from Others",
  Dawat: "Qardan Hasanah from Dawat",
  Mumineen: "Donation from Mumineen",
  Sale: "Sale of Property, Rent Deposit, etc",
  "Operation Income": "Operation Income from Jamaat",
  "Other Income": "Other Source of Income",
  Enayat: "Enayat from Dawat e Hadiyah",
};

export const panelStyle = {
  marginBottom: 24,
  backgroundColor: "white",
  borderRadius: "16px",
  border: "none",
  fontWeight: "bold",
};
