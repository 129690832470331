import { Form } from "antd";
import TypedInputNumber from "antd/es/input-number";
import React from "react";
import { parsedLocalStorage } from "../../../utils/commonFunction";
const InputNumber = (props) => {
  const {
    name,
    value,
    label,
    onChange,
    placeholder,
    rules,
    validateTrigger,
    className,
    disabled,
    readOnly,
    created_by_dept,
    formatterValue,
    curr,
    ...rest
  } = props;


  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      validateTrigger={validateTrigger}
    >
      <TypedInputNumber
        className={`${className || ""} commonInput commonInputNumber`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        prefix={curr}
        formatter={(value) =>
          `${formatterValue ? formatterValue : value}`.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")
        }
        parser={(value) => Number(value.replace(/\$\s?|(,*)/g, ""))}
        min={0}
        {...rest}
      />
    </Form.Item>
  );
};

export default InputNumber;
