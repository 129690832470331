import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./Loader.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

// Return value should be component
const Loader = () => (
  <div className="main-spinner">
    <Spin indicator={antIcon} />
  </div>
);

export default Loader;
