import React from "react";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";

const CountsDashboard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="row">
        {data?.map((el, i) => {
          return (
            <>
              <div
                onClick={() => {
                  el?.filter && navigate(`/project-view?${el?.filter}`);
                }}
                style={{
                  cursor: "pointer",
                }}
                key={el?.title}
                className={`section col-md-${12 / data?.length} count c1`}
              >
                <CountUp end={el?.count} duration={2} />
                <h5>{el?.title}</h5>
              </div>
            </>
          );
        })}
      </div>{" "}
    </>
  );
};

export default CountsDashboard;
