import { PATH } from "../redux/service/apiConstant";

export function parsedLocalStorage(item) {
  return localStorage.getItem(item) && JSON.parse(localStorage.getItem(item));
}

export const permissionCheck = (permission) => {
  const permission_list = parsedLocalStorage("permissions");
  return (
    (permission_list && permission_list?.find((el) => el === permission)) ||
    false
  );
};

export const fileTypeCheck = (file) => {
  if (file) {
    if (
      file?.split(".")[file?.split(".")?.length - 1].includes("png") ||
      file?.split(".")[file?.split(".")?.length - 1].includes("jpg") ||
      file?.split(".")[file?.split(".")?.length - 1].includes("jpeg") ||
      file?.split(".")[file?.split(".")?.length - 1].includes("svg") ||
      file?.split(".")[file?.split(".").length - 1].includes("gif")
    ) {
      return "image";
    } else if (file?.split(".")[file?.split(".")?.length - 1].includes("pdf")) {
      return "pdf";
    } else if (
      file?.split(".")[file?.split(".").length - 1].includes("csv") ||
      file?.split(".")[file?.split(".").length - 1].includes("xslx") ||
      file?.split(".")[file?.split(".").length - 1].includes("xls") ||
      file?.split(".")[file?.split(".").length - 1].includes("docx") ||
      file?.split(".")[file?.split(".").length - 1].includes("mp4") ||
      file?.split(".")[file?.split(".").length - 1].includes("webm") ||
      file?.split(".")[file?.split(".").length - 1].includes("mp3")
    ) {
      return "doc";
    }
  }
};

export const getFileName = (file) => {
  const fileName_arr = file?.split("/")[file?.split("/")?.length - 1];
  return fileName_arr?.split(".")[0];
};

export function renderAmount(amount, currency) {
  return !isNaN(amount) 
    ? currency !== null && currency !== undefined && currency !== ""? `${currency}${
      Number(amount)?.toLocaleString("en-IN", {
        minimumFractionDigits: 0,
      }) || 0
    }`: `₹${
        Number(amount)?.toLocaleString("en-IN", {
          minimumFractionDigits: 0,
        }) || 0
      }`
    : currency !== null && currency !== undefined && currency !== ""? `${currency}0`:`₹0`;
}

export function countAmountHandler(arr, key) {
  return (
    arr &&
    Object?.values(arr) &&
    Object?.values(arr).length > 0 &&
    Object?.values(arr)?.reduce((accumulator = 0, curValue) => {
      return accumulator + Number(curValue[key] ?? 0);
    }, 0)
  );
}
export function divideAmount(total, years) {
  let amountPerYear = Math.floor(total / years);
  let remainingAmount = total ? total % years : 0;
  return [amountPerYear, remainingAmount];
}

export const YearMapper = (years_watch, total) => {
  const arr = Array.from({ length: years_watch }, (_, i) => "year_" + (i + 1));
  const year_obj = {};
  const splittedAmount = divideAmount(total, years_watch)[0];
  const remainingAmount = divideAmount(total, years_watch)[1];
  arr?.forEach((el, index) => {
    year_obj[el] = splittedAmount + (index ? 0 : remainingAmount);
  });

  return year_obj;
};
export const revertFundingHandler = (value = []) => {
  const obj = {};
  value?.map((el) => {
    obj[el?.id] = { ummal_amount: el?.ummal_amount };
    return el;
  });

  return obj;
};

export function compareArraysOfObjects(array1, array2) {
  if (array1 && array2 && Array.isArray(array1) && Array.isArray(array2)) {
    // Check if the arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }

    // Iterate over each object in the first array
    for (let i = 0; i < array1.length; i++) {
      const obj1 = array1[i];
      const obj2 = array2[i];

      // Iterate over each key in the object
      for (const key in obj1) {
        // Check if the corresponding key in the second object exists and has the same value
        if (!(key in obj2) || obj1[key] !== obj2[key]) {
          return false;
        }
      }

      // Iterate over each key in the second object to check for any missing keys
      for (const key in obj2) {
        if (!(key in obj1)) {
          return false;
        }
      }
    }

    // If we make it this far, the arrays are equal
    return true;
  }
}

export function isMultipleRoles() {
  const role_arr = parsedLocalStorage("roles");
  return Array.isArray(role_arr) && role_arr?.length > 1;
}

export function roleIdFind(roleType) {
  const role_arr = parsedLocalStorage("roles");
  const roleId =
    (Array.isArray(role_arr) && role_arr?.length > 0 && role_arr) || "";

  if (Array.isArray(role_arr) && role_arr?.length === 1) {
    return roleId?.some((el) => el?.id === roleType);
  } else {
    return localStorage.getItem("rid") === String(roleType);
  }
}

export function checkAllObjectsWithValidValue(arr, key) {
  for (var i = 0; i < arr.length; i++) {
    if (
      !arr[i].hasOwnProperty(key) ||
      arr[i][key] === undefined ||
      arr[i][key] === null ||
      arr[i][key] === ""
    ) {
      return false;
    }
  }
  return true;
}

export function findCommonElements(array1, array2) {
  // Create an empty array to store common elements
  const commonElements = [];

  for (let i = 0; i < array1.length; i++) {
    const element = array1[i];

    // Check if the element is also in array2
    if (array2?.includes(element)) {
      // If it is, add it to the commonElements array
      commonElements?.push(element);
    }
  }

  // Return the commonElements array
  return commonElements;
}

export const generateEncodedSearchURI = (search) => {
  return !search.length ? "" : "&search=" + encodeURIComponent(search);
};

export const generateEncodedStageURI = (stage) => {
  return !stage.length ? "" : "&project_stage=" + encodeURIComponent(stage);
};

export const reportHandler = (data) => {
  let headers = new Headers();
  const accessToken = localStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + accessToken);
  fetch(PATH.Project.getDownloadReport(data?.id), { headers })
    .then((response) => response.blob())
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);
      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = data?.name + ".pdf";
      link.click();

      window.URL.revokeObjectURL(objectUrl);
    });
};

export const  ummalKagazHandler = (data) => {
  let headers = new Headers();
  const accessToken = localStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + accessToken);
  fetch(PATH.Project.getUmmalKagaz(data?.id), { headers })
  .then((response) => response.blob())
  .then((blobby) => {
    let objectUrl = window.URL.createObjectURL(blobby);
    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = data?.name + ".pdf";
    link.click();

    window.URL.revokeObjectURL(objectUrl);
  });
}

export const download = (file, fileName, roleId = true) => {
  let headers = new Headers();
  const accessToken = localStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + accessToken);
  if (isMultipleRoles() && roleId) {
    const role_id = localStorage.getItem("rid");
    file = file + `&role_id=${role_id}`;
  }
  fetch(file, { headers }).then((response) => {
    response.blob().then((blob) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = fileName + ".xlsx" ;
      alink.click();
    });
  });
};

export const totalAmountUmmal = (arr) => {
  return (
    (arr &&
      arr?.reduce((accumulator = 0, curValue) => {
        return accumulator + Number(curValue?.ummal_amount);
      }, 0)) ||
    0
  );
};

export const totalAmountBoard = (arr) => {
  return (
    (arr &&
      arr?.reduce((accumulator = 0, curValue) => {
        return accumulator + Number(curValue?.board_amount);
      }, 0)) ||
    0
  );
};
export const totalAmountDept = (arr) => {
  return (
    (arr &&
      arr?.reduce((accumulator = 0, curValue) => {
        return accumulator + Number(curValue?.department_amount);
      }, 0)) ||
    0
  );
};

export function checkProjectStage(stage, data) {
  return data?.status_obj?.status === stage;
}

export function calculateSums(array) {
  const sums = {};
  array.forEach((obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        sums[key] = (sums[key] || 0) + obj[key];
      }
    }
  });
  const total = Object.values(sums)?.reduce((acc, curr) => acc + curr, 0) || 0;
  return { yearWise: Object?.values(sums) || [], total };
}
