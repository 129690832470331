import { PERMISSION_TYPES } from "../constants";
import CreateProject from "../pages/CreateProject";
import Dashboard from "../pages/Dashboard/Dashboard";
import NotFound from "../pages/DefaultPage/NotFound";
import Login from "../pages/Login/Login";
import ProjectList from "../pages/ProjectList/ProjectList";
import Reports from "../pages/Reports";
import UserProfileSelection from "../pages/UserProfileSelection";
export const routes = [
  {
    path: "/",
    element: Dashboard,
    isPrivate: true,
    exact: true,
    viewLabel: true,
  },
  {
    path: "/login/:id?",
    element: Login,
    isPrivate: false,
    exact: true,
    viewLabel: true,
  },
  {
    path: "/dashboard",
    element: Dashboard,
    isPrivate: true,
    exact: true,
    viewLabel: true,
  },
  {
    path: "/project-view",
    element: ProjectList,
    isPrivate: true,
    exact: true,
    viewLabel: true,
  },
  {
    path: "/create-project",
    element: CreateProject,
    isPrivate: true,
    exact: true,
    viewLabel: PERMISSION_TYPES.ADD_PROJECTS,
  },
  {
    path: "/edit-project/",
    element: CreateProject,
    isPrivate: true,
    exact: true,
    viewLabel: true,
  },
  {
    path: "/reports/:type",
    element: Reports,
    isPrivate: true,
    exact: true,
    viewLabel: "reports",
  },
  {
    path: "/profile",
    element: UserProfileSelection,
    isPrivate: true,
    exact: true,
    viewLabel: true,
    isLayout: false,
    isRolePage: true,
  },
  {
    path: "*",
    element: NotFound,
    isPrivate: false,
    exact: false,
    viewLabel: true,
  },
];
