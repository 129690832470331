import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
// import DocViewer from "react-doc-viewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Button, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { fileTypeCheck, getFileName } from "../../utils/commonFunction";
import { When, Button as CommonButton } from "../common";
import "./DisplayFiles.scss";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DisplayFiles = (props) => {
  const { currentFile, open, setOpen } = props;
  const extension =
    currentFile &&
    currentFile?.file?.split(".")[currentFile?.file?.split(".")?.length - 1];

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const onLoadError = (err) => {};
  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const download = () => {
    fetch(currentFile?.file).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = currentFile?.file_name;
        alink.click();
      });
    });
  };

  useEffect(() => {
    if (
      currentFile &&
      (currentFile?.file === "" ||
        !currentFile?.file?.includes(".jpg") ||
        !currentFile?.file?.includes(".png") ||
        !currentFile?.file?.includes(".jpeg") ||
        !currentFile?.file?.includes(".doc") ||
        !currentFile?.file?.includes(".ppt") ||
        !currentFile?.file?.includes(".pptx") ||
        !currentFile?.file?.includes(".xls") ||
        !currentFile?.file?.includes(".rar"))
    ) {
      // setData(currentFile?.data);
      // setPrevData(currentFile?.data);
    }
  }, [currentFile]);

  const isImage = fileTypeCheck(currentFile?.file) === "image";
  const isPDF = fileTypeCheck(currentFile?.file) === "pdf";
  const isDOC = fileTypeCheck(currentFile?.file) === "doc";

  return (
    <>
      {" "}
      <Modal
        title={getFileName(currentFile?.file)}
        centered
        open={open}
        // onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={"auto"}
        footer=""
      >
        <CommonButton
          name={"Download"}
          type="primary"
          className={"primary-button"}
          icon={<DownloadOutlined />}
          onClick={() => download()}
        />
        <When condition={isImage}>
          <Row style={{ width: "100%" }}>
            <img
              style={{ width: "auto", height: "65vh", margin: "auto" }}
              src={currentFile?.file}
              alt={currentFile?.file}
            />
          </Row>
        </When>
        <When condition={isDOC}>
          <Row>
            <DocViewer
              className="docs-viewer"
              prefetchMethod="GET"
              style={{ width: "60vw", minHeight: "60vh" }}
              pluginRenderers={DocViewerRenderers}
              documents={[
                {
                  uri: currentFile?.file,
                  fileType: extension,
                },
              ]}
            />
          </Row>
        </When>
        <Row>
          <When condition={isPDF}>
            <div>
              <div className="displaybtn">
                <Button
                  style={{ color: "#fff" }}
                  type="primary"
                  shape="circle"
                  className="ant-custom-btn"
                  icon={<ArrowLeftOutlined />}
                  onClick={goToPrevPage}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    style={{ color: "#fff" }}
                    type="primary"
                    shape="circle"
                    className="ant-custom-btn"
                    icon={<ArrowRightOutlined />}
                    onClick={goToNextPage}
                  />
                </div>
              </div>
              <p style={{ textAlign: "center" }}>
                Page {pageNumber} of {numPages}
              </p>

              <Document
                className="docs-viewer"
                file={currentFile?.file}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onLoadError}
              >
                <Page pageNumber={pageNumber}> </Page>
              </Document>
            </div>
          </When>
          <When condition={isDOC}>
            <a
              class="btn btn-primary mx-auto mt-5"
              target={"_blank"}
              href={currentFile?.file}
              rel="noreferrer"
            >
              &nbsp; View {extension} file
            </a>
          </When>
        </Row>
      </Modal>
    </>
  );
};

export default DisplayFiles;
