import { Form, Input as CommonInput } from "antd";
import React from "react";

const { TextArea } = CommonInput;

const Input = ({
  type,
  style,
  name,
  value,
  label,
  onChange,
  placeholder,
  rules,
  validateTrigger,
  className,
  disabled,
  readOnly,
  defaultValue,
  formatter,
  parser,
  maxLength,
  onBlur,
  isTextArea,
  isPassword,
  onKeyPress,
  prefix,
  iconRender,
  size,
  autoComplete,
  rows,
  ...rest
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      validateTrigger={validateTrigger}
      style={style}
    >
      {isTextArea ? (
        <TextArea
          onBlur={onBlur}
          className={`${className || ""} commonInput`}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          defaultValue={defaultValue}
          formatter={formatter}
          parser={parser}
          onKeyUp={onKeyPress}
          rows={rows}
          {...rest}
        />
      ) : isPassword ? (
        <CommonInput.Password
          iconRender={iconRender}
          prefix={prefix}
          type={type}
          placeholder={placeholder}
          size={size}
          name={name}
          disabled={disabled}
          autoComplete={autoComplete}
          value={value}
          {...rest}
        />
      ) : (
        <CommonInput
          onBlur={onBlur}
          type={type}
          className={`${className || ""} commonInput`}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          defaultValue={defaultValue}
          formatter={formatter}
          parser={parser}
          onKeyUp={onKeyPress}
          {...rest}
        />
      )}
    </Form.Item>
  );
};

export default Input;
