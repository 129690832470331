import { Input, Modal, Table } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { viewReportByName } from "../../redux/action/projectForm";
import ViewReports from "./ViewReports";
import { Button } from "../../components/common";
import "./Reports.scss";

const Reports = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);
  const [openReportViewModal, setOpenReportViewModal] = React.useState(false);
  const [reportListData, setReportListData] = React.useState([]);
  const [reportList, setReportList] = React.useState([]);
  const [columnViewReport, setColumnViewReport] = React.useState([]);

  function onCloseModal() {
    setOpenReportViewModal(false);
    setReportData([]);
  }

  function onViewReport(data) {
    const columnData = data?.headers?.map((item) => {
      return {
        title: item,
        dataIndex: item,
        width: "10%",
        align: "center",
      };
    });

    setColumnViewReport(columnData);
    const tableData = data?.data?.map((item) => {
      const obj = {};
      data?.headers.map((header, i, arr) => {
        if (arr.length - 1 === i && String(item[i])?.includes("href")) {
          obj[header] = (
            <div
              dangerouslySetInnerHTML={{ __html: item[i] }}
              className="link-to-print"
            ></div>
          );
        } else {
          obj[header] = item[i];
        }
      });
      return obj;
    });
    setColumnViewReport(columnData);
    setReportData({ title: data.title, data: tableData });
    setOpenReportViewModal(true);
  }

  const column = [
    {
      title: "Title",
      dataIndex: "title",
      align: "center",
      sorter: (a, b) => a?.title.localeCompare(b?.title),
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "20%",
      align: "center",
      render: (id, data) => {
        return (
          <Button
            size="small"
            type="primary"
            onClick={() => onViewReport(data)}
            name={"view"}
            className={"m-auto"}
          />
        );
      },
    },
  ];
  useEffect(() => {
    params?.type && setLoading(true);
    params?.type &&
      dispatch(viewReportByName(params?.type))
        .then((res) => {
          setReportListData(res);
          setReportList(res);
          setLoading(false);
        })
        .catch(() => {
          setReportListData([]);
          setReportList([]);
          setLoading(false);
        });
  }, [params?.type]);

  function searchList(searchTerm) {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filterData = reportList?.filter((obj) => {
      return obj?.title?.toLowerCase().includes(lowerCaseSearchTerm);
    });
    setReportListData(filterData);
  }
  return (
    <div>
      <Modal
        title={reportData?.title}
        open={openReportViewModal}
        onCancel={onCloseModal}
        footer={null}
        centered
        width={"auto"}
      >
        <ViewReports
          columnViewReport={columnViewReport}
          data={reportData?.data}
        />
      </Modal>
      <div className="create-search">
        <div className="search">
          <Input
            type={"search"}
            placeholder="Search...."
            onChange={(e) => searchList(e.target.value)}
          />
        </div>
      </div>
      <Table
        className="project-view-list"
        columns={column}
        rowKey={(record) => record.id}
        dataSource={reportListData}
        loading={loading}
      />
    </div>
  );
};

export default Reports;
