import { Tag, Typography } from "antd";
import "./chips.scss";

const { Text } = Typography;

const getSelectedLabels = (items, selectedKeys) => {
  const temp = [];

  selectedKeys.forEach((element) => {
    const parent = element.split("-")[0];
    const parentItem = items.find((item) => item.key === parent);
    const selectedParentItem = temp.find((item) => item.key === parent);
    if (
      !selectedParentItem ||
      (selectedParentItem && selectedParentItem.type === "date")
    ) {
      temp.push({
        key: parent,
        label: parentItem.label,
        kids: [element],
        type: parentItem.type,
        kidsLabel:
          parentItem.type === "date"
            ? [element.replace(`${parentItem.key}-`, "").replace(",", " to ")]
            : [parentItem?.children.find((item) => item.key === element).label],
      });
    } else {
      selectedParentItem.kidsLabel = [
        ...selectedParentItem.kidsLabel,
        parentItem.children.find((item) => item.key === element).label,
      ];
      selectedParentItem.kids = [...selectedParentItem.kids, element];
    }
  });
  return temp;
};

function Chips({ selectedKeys, items, removeKey }) {
  const selectedLabels = getSelectedLabels(items, selectedKeys);
  return (
    <div className="tag-cnt">
      {selectedLabels.map((item) => {
        return (
          <Tag className="tag" color="#1127FF">
            {
              <Typography.Text
                ellipsis={{
                  tooltip: item?.label + " is " + item.kidsLabel.join(", "),
                }}
                className="chip"
              >
                {item.label + " is " + item.kidsLabel.join(", ")}
              </Typography.Text>
            }
            <i
              onClick={() => removeKey(item.kids)}
              class="material-icons close-icon"
            >
              cancel
            </i>
          </Tag>
        );
      })}
    </div>
  );
}

export default Chips;
