import { Table } from "antd";
import React from "react";

function ViewReports({ data, columnViewReport = [] }) {
  return (
    <div>
      {" "}
      <Table
        className="project-view-list"
        columns={columnViewReport}
        rowKey={(record) => record.id}
        dataSource={data}
      />
    </div>
  );
}

export default ViewReports;
