import React from "react";
import { Empty, Form, Select as CommonSelect, Spin } from "antd";
import "./selector.scss";
const { Option } = CommonSelect;

const Select = (props) => {
  const {
    defaultValue,
    name,
    onBlur,
    value,
    onSelect,
    label,
    mode,
    placeholder,
    options,
    onChange,
    rules,
    className,
    validateTrigger,
    disabled = false,
    onSearch,
    wrapperClass,
    getPopupContainer,
    initialValue,
    disabledOptionArray,
    onDropdownVisibleChange,
    children,
    spinLoader,
    ...rest
  } = props;

  return (
    <div className={wrapperClass}>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        validateTrigger={validateTrigger}
        initialValue={initialValue}
      >
        <CommonSelect
          className={`${className || ""} commonSelect`}
          showArrow
          showSearch
          size="large"
          mode={mode}
          name={name}
          value={value}
          getPopupContainer={getPopupContainer}
          placeholder={placeholder}
          onChange={onChange}
          allowClear
          disabled={disabled}
          onSelect={onSelect}
          onSearch={onSearch}
          onBlur={onBlur}
          defaultValue={defaultValue}
          onDropdownVisibleChange={onDropdownVisibleChange}
          optionFilterProp="children"
          // filterOption={(input = "", option) => {
          //   return (option?.children ?? "")
          //     ?.toLowerCase()
          //     ?.includes(input?.toLowerCase());
          // }}
          notFoundContent={
            spinLoader ? (
              <Spin size="small" />
            ) : (
              <Empty style={{ margin: "auto" }} />
            )
          }
          {...rest}
        >
          {children
            ? children
            : options &&
              options.length &&
              options.map((option, index) => (
                <Option
                  key={index}
                  value={option.value}
                  disabled={
                    disabledOptionArray &&
                    disabledOptionArray?.includes(option.value)
                  }
                >
                  {option.label}
                </Option>
              ))}
        </CommonSelect>
      </Form.Item>
    </div>
  );
};

export default Select;
