import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserProfileSVG from "../../components/UserProfileSVG";
import "./UserProfileSelection.scss";

const ProfileSelection = () => {
  const current_role_id = localStorage.getItem("rid");
  const [selectedRole, setSelectedRole] = useState("");

  const roles = localStorage.getItem("roles")
    ? JSON.parse(localStorage.getItem("roles"))
    : [];
  const navigate = useNavigate();
  const handleProfileSelect = (id) => {
    localStorage.setItem("rid", String(id));
    setSelectedRole(id);
    navigate("/dashboard");
  };

  useEffect(() => {
    if (current_role_id) {
      setSelectedRole(current_role_id);
    }
  }, [current_role_id]);

  return (
    <div className="profile-selection">
      <h2>Select a Profile</h2>
      <div className="profiles">
        {roles?.map((profile) => (
          <div
            key={profile.id}
            className={`profile-card  ${
              selectedRole == profile?.id ? "selected" : ""
            }`}
            onClick={() => handleProfileSelect(profile.id)}
          >
            <UserProfileSVG
              className={"user-svg-icon"}
              fillCircle={"#003366"}
            />

            <h3 className="profile-name">{profile.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileSelection;
