import React, { memo, Suspense, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import Loader from "../components/common/Loader";
import { ROLE_TYPE } from "../constants";
import { useAuth } from "../customHook/useAuth";
import NotFound from "../pages/DefaultPage/NotFound";
import { permissionCheck, roleIdFind } from "../utils/commonFunction";
import PrivateRoute from "./PrivateRoute";
import { routes } from "./routes";
const RouterList = () => {
  const { loader } = useSelector((state) => state);
  const { pathname } = useLocation();
  const isDepartment = roleIdFind(ROLE_TYPE.DEPARTMENT);
  const isAdmin = roleIdFind(ROLE_TYPE.ADMIN);

  const checkReportsSubmenu = useCallback(() => {
    const currentPathPermission = "application." + pathname?.split("/")[2];
    return permissionCheck(currentPathPermission) && (isDepartment || isAdmin);
  }, [isAdmin, isDepartment, pathname]);

  const { isRoleSelected } = useAuth();

  function validRoutes(el) {
    return el?.viewLabel === "reports"
      ? checkReportsSubmenu()
      : permissionCheck(el?.viewLabel);
  }

  const newRoutes = useMemo(() => {
    const data = routes?.map((el) => {
      return {
        ...el,
        isView: el.viewLabel === true ? true : validRoutes(el),
      };
    });
    return data;
  }, [JSON.parse(localStorage.getItem("permissions")), pathname]);

  useEffect(() => {
    if (loader) {
      const element = document.getElementsByTagName("body")[0];
      element.classList.add("loader");
    } else {
      const element = document.getElementsByTagName("body")[0];
      element.classList.remove("loader");
      setTimeout(() => {
        const RouterList = document.getElementById("RouterList");
        RouterList?.classList?.remove?.("hide-RouterList");
        const loader = document.getElementById("appLoader");
        loader?.classList?.remove?.("apploader");
      }, 3000);
    }
  }, [loader]);

  return (
    <div className="RouterList hide-RouterList" id="RouterList">
      {loader && <Loader />}
      <Suspense fallback={<Loader />}>
        <Routes>
          {newRoutes &&
            newRoutes?.map((route, index) => {
              return route.isView ? (
                <Route
                  path={route.path}
                  key={index}
                  exact={route.exact}
                  element={
                    <>
                      {route.isPrivate ? (
                        <PrivateRoute
                          isLayout={route.isLayout}
                          isView={route.viewLabel}
                          isRolePage={route.isRolePage}
                        >
                          <route.element />
                        </PrivateRoute>
                      ) : (
                        <route.element />
                      )}
                    </>
                  }
                />
              ) : (
                <Route
                  path={route.path}
                  key={index}
                  exact={true}
                  element={<NotFound />}
                />
              );
            })}
        </Routes>
      </Suspense>
    </div>
  );
};
export default memo(RouterList);
