import { Button, Card } from "antd";
import React, { useState, useEffect } from "react";
import xlsImage from "../../assets/image/excelSvg.svg";
import { fileTypeCheck, getFileName } from "../../utils/commonFunction";
import { When } from "../common";
import Carousel from "./Carousel";
import DisplayFiles from "./DisplayFiles";
import "./FileListView.scss";

const FilesListView = ({ data, deleteHandler }) => {
  const [open, setOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState("");
  const extension =
    currentFile &&
    currentFile?.file?.split(".")[currentFile?.file?.split(".")?.length - 1];
  const pdf = "https://img.icons8.com/office/450/null/pdf.png";

  const [width, setWidth] = React.useState(window.innerWidth);
  const show = width > 1024 ? 4 : width > 768 ? 3 : width > 480 ? 2 : 1;
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  return (
    <div
      className="file-list-carousal-wrapper"
      style={{
        padding: `${data?.length > 0 && "10px"}`,
      }}
    >
      <Carousel show={show}>
        {data &&
          data?.map((el, i) => {
            return (
              <div className="card-warrper">
                <Card
                  className="file-card"
                  hoverable
                  onClick={() => {
                    setCurrentFile(el);
                    setOpen(true);
                  }}
                  cover={
                    <img
                      height="200"
                      alt="example"
                      src={
                        fileTypeCheck(el?.file) === "image"
                          ? el?.file
                          : fileTypeCheck(el?.file) === "pdf"
                          ? pdf
                          : xlsImage
                      }
                    />
                  }
                  bodyStyle={{ padding: "20px", overflow: "hidden" }}
                >
                  <p>{getFileName(el?.file)}</p>
                  <When condition={deleteHandler}>
                    <Button
                      className="image-delete-button"
                      style={{}}
                      type="text"
                      onClick={(e) => deleteHandler(el?.id, e)}
                    >
                      Delete
                    </Button>
                  </When>
                </Card>
              </div>
            );
          })}
      </Carousel>
      <DisplayFiles currentFile={currentFile} open={open} setOpen={setOpen} />
    </div>
  );
};

export default FilesListView;
