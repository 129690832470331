import { LOADER_START, LOADER_STOP } from "../service/types"

export const loadingStart = () =>(dispatch) =>{
    return dispatch({
        type: LOADER_START,
        payload: true
    })
}
export const loadingStop = () =>(dispatch) =>{
    return dispatch({
        type: LOADER_STOP,
        payload: false
    })
}