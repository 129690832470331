import { Button as ActionButton } from "antd";
import React from "react";
import "./Button.scss";
export default function Button({
  onClick,
  name,
  icon,
  className,
  type,
  htmlType,
  size,
  shape,
  ...rest
}) {
  return (
    <ActionButton
      size={size}
      htmlType={htmlType}
      type={type}
      shape={shape}
      icon={icon}
      className={`common-button ${className}`}
      onClick={onClick}
      {...rest}
    >
      {name}
    </ActionButton>
  );
}
