import { Button, Form, Modal, notification } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { APPLICATION_STATUS, BUTTON_COLOR } from "../../constants";
import {
  addRemarks,
  manageDepartmentStatus,
  updateProjectPartial,
  updateProjectStatus,
} from "../../redux/action/projectForm";
import { requiredRule } from "../../utils/validator";
import { Input } from "../common";

const ConfirmModal = ({
  confirm,
  setConfirm,
  appId,
  setActive,
  data,
  reloadCurrentProject,
  listCall,
}) => {
  const [formModal] = Form.useForm();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const {
    APPROVE,
    REJECTED,
    REVIEW,
    CHANGE,
    HOLD,
    FORWARDED_TO_DEPARTMENT,
    PENDING,
  } = APPLICATION_STATUS;

  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description,
    });
  };

  const onCancel = () => {
    // setActive("ProjectList");
    listCall();
    formModal.resetFields();
    setConfirm(false);
  };

  const manageDepartmentHandler = (value) => {
    const formData = new FormData();
    const obj = {
      owner_department: data?.owner_department,
      stakeholder_departments: data?.stakeholder_departments,
      status: confirm?.status,
    };
    formData.append("status", confirm?.status);
    formData.append("owner_department", data?.owner_department);
    formData.append("stakeholder_departments", data?.stakeholder_departments);
    switch (confirm?.type) {
      case APPROVE:
        obj["remarks"] = value?.Remarks;
        break;
      case FORWARDED_TO_DEPARTMENT:
        obj["reason"] = value?.Reason;
        break;
      case REVIEW:
        obj["reason"] = value?.Reason;
        break;
      case CHANGE:
        obj["reason"] = value?.Reason;
        break;
      default:
        break;
    }

    dispatch(manageDepartmentStatus(obj, appId))
      .then((res) => {
        openNotificationWithIcon("success", res?.messageText);
        reloadCurrentProject(appId);
        setTimeout(() => {
          onCancel();
        }, 1000);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          err?.response?.data?.messageText ?? "Error"
        );
      });
  };

  const manageNOCHandler = (value) => {
    const formData = new FormData();
    formData.append("status", data?.status);
    formData.append("remarks", value?.Remarks);
    formData.append("department", data?.department);
    dispatch(updateProjectPartial(formData, appId))
      .then((res) => {
        openNotificationWithIcon("success", res?.messageText);
        reloadCurrentProject(appId);
        setTimeout(() => {
          onCancel();
        }, 1000);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          err?.response?.data?.messageText ?? "Error"
        );
      });
  };
  const addRemarksHandler = (value) => {
    const formData = new FormData();
    formData.append("remarks", value?.Remarks);
    const roleId = localStorage.getItem("rid");
    dispatch(addRemarks(formData, appId, roleId))
      .then((res) => {
        openNotificationWithIcon("success", `Remark added successfully`);
        reloadCurrentProject(appId);
        onCancel();
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          err?.response?.data?.messageText ?? "Error"
        );
      });
  };
  const onFinish = (value) => {
    if (confirm?.remarks) {
      // console.log(value);
      addRemarksHandler(value);
      return;
    }
    if (confirm?.manageDept) {
      manageDepartmentHandler(value);
      return;
    }
    if (confirm?.nocDept) {
      manageNOCHandler(value);
      return;
    }
    const formData = new FormData();
    formData.append("status", confirm?.status);
    switch (confirm?.type) {
      case APPROVE:
        formData.append("remarks", value?.Remarks);
        break;
      case REVIEW:
        formData.append("reason", value?.Reason);
        break;
      case FORWARDED_TO_DEPARTMENT:
        formData.append("reason", value?.Reason);
        break;
      case HOLD:
        formData.append("reason", value?.Reason);
        break;
      case PENDING:
        formData.append("reason", value?.Reason);
        break;
      case CHANGE:
        formData.append("reason", value?.Reason);
        break;
      case REJECTED:
        formData.append("reason", value?.Reason);
        break;

      default:
        break;
    }
    dispatch(updateProjectStatus(formData, appId))
      .then((res) => {
        openNotificationWithIcon("success", res?.messageText);
        reloadCurrentProject(appId);
        setTimeout(() => {
          onCancel();
        }, 1000);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          err?.response?.data?.messageText ?? "Error"
        );
      });
  };
  return (
    <>
      {contextHolder}
      <Modal
        title={confirm.message}
        open={confirm.message}
        onCancel={() => setConfirm(false)}
        footer={[
          <div>
            <Button
              className={BUTTON_COLOR[REJECTED]}
              onClick={() => setConfirm(false)}
            >
              Cancel
            </Button>
            ,
            <Button
              form="myForm"
              key="submit"
              htmlType="submit"
              className={BUTTON_COLOR[confirm?.type]}
            >
              {confirm?.name}
            </Button>
          </div>,
        ]}
      >
        <Form
          scrollToFirstError
          form={formModal}
          name="myForm"
          labelCol={{
            span: 24,
          }}
          wrappercol={{
            span: 24,
          }}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Input
            isTextArea={true}
            rules={[requiredRule(confirm?.label)]}
            style={{ color: "gray" }}
            label={confirm?.label}
            name={confirm?.label}
            rows={4}
          />
        </Form>
      </Modal>
    </>
  );
};

export default ConfirmModal;
