import { DASHBOARD_DATA } from "../service/types";

const initialState = {
  counts: [],
};

export default function dashboardDataReducer(state = initialState, action) {

  switch (action.type) {
    case DASHBOARD_DATA:
      return {...state,counts:action.payload.counters};
    default:
      return state;
  }
}
