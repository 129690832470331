import {
  ArrowRightOutlined,
  EyeOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Select, When } from "../../../components/common";
import {
  BUTTON_DETAILS,
  MANAGE_BUTTON_DETAILS,
  projectViewConstants,
  PROJECT_VIEW_BUTTONS,
} from "../../../constants";
import { getDepartmentList } from "../../../redux/action/lookups";
import { PROJECT_VIEW_VALIDATIONS } from "../../../utils/validator";

function ActionContainer({
  formView,
  data,
  setIsModalOpen,
  stakeholder_departments_watch,
  owner_departments_watch,
  forwardButtonCondition,
  changeButtonCondition,
  approveButtonCondition,
  isBoardB,
  reviewButtonCondition,
  rejectButtonCondition,
  holdButtonCondition,
  unHoldButtonCondition,
  ownerList,
  approveButtonTextCondition
}) {
  const { OWNER_DEPARTMENT } = PROJECT_VIEW_VALIDATIONS;
  const {
    CHANGE_BUTTON,
    FORWARD_BUTTON,
    APPROVE_BUTTON,
    REVIEW_BUTTON,
    REJECT_BUTTON,
    HOLD_BUTTON,
    UN_HOLD_BUTTON,
  } = PROJECT_VIEW_BUTTONS;

  const dispatch = useDispatch();
  const [activeOwner, setActiveOwner] = useState(false);
  const [activeStakeholder, setActiveStakeholder] = useState(false);
  const [stackHolderList, setStakeholderList] = useState([]);
  const [width, setWidth] = React.useState(window.innerWidth);
  const { button } = projectViewConstants;
  const spanWidth6To3 = width < 500 ? 6 : 3;
  const ownerStakeholderForm = changeButtonCondition || forwardButtonCondition;

  const StackHolder_department = (id1, id2) => {
    // if (data?.sub_category_1?.id && data?.sub_category_2?.id) {
    dispatch(getDepartmentList())
      .then((res) => {
        setStakeholderList(res ?? []);
      })
      .catch((err) => {
        setStakeholderList([]);
      });
    // }
  };

  useEffect(() => {
    formView.setFieldsValue({
      stakeholder_departments: data?.stakeholder_department_objs?.map((el) => {
        return el?.id;
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.stakeholder_department_objs, stackHolderList]);

  useEffect(() => {
    StackHolder_department(
      data?.sub_category_1?.id + "," + data?.sub_category_2?.id,
      owner_departments_watch
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.sub_category_1?.id, data?.sub_category_2?.id]);

  useEffect(() => {
    formView.setFieldsValue({
      stakeholder_departments: data?.stakeholder_department_objs?.map((el) => {
        return el?.id;
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.stakeholder_department_objs, stackHolderList]);

  const ownerListOptions =
    ownerList &&
    ownerList?.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });

  const stackHolderListOptions =
    stackHolderList &&
    stackHolderList?.map(({ id, name }) => {
      return {
        label: name,
        value: id,
      };
    });

  const OwnerDepartmentLabel = (
    <div className="owner-stakeholder-label-wrapper">
      <span className="phoneTitle">Department Sponsor (OD):</span>
      <Button
        name={button.changeDept}
        className="depa-btn"
        onClick={() => setActiveOwner(true)}
      />
    </div>
  );

  const stackHolderDepartmentLabel = (
    <div className="owner-stakeholder-label-wrapper">
      <span className="phoneTitle">Stakeholder departments:</span>
      <Button
        onClick={() => setActiveStakeholder(true)}
        name={button.assignStake}
        className="depa-btn"
      />
    </div>
  );

  return (
    <Form
      scrollToFirstError
      form={formView}
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrappercol={{
        span: 24,
      }}
      layout="vertical"
      autoComplete="off"
      className={ownerStakeholderForm ? "form-wrapper" : ""}
    >
      <When condition={ownerStakeholderForm}>
        <Row className="select-container">
          <Col span={width < 500 ? 24 : 10}>
            <Select
              rules={OWNER_DEPARTMENT}
              label={OwnerDepartmentLabel}
              name="owner_department"
              className="select-category"
              placeholder="Select Department"
              aria-readonly={!activeOwner}
              onDropdownVisibleChange={() => setActiveOwner(false)}
              open={activeOwner}
              options={ownerListOptions}
              disabledOptionArray={stakeholder_departments_watch}
            />
          </Col>
          <Col span={width < 500 ? 24 : 10} offset={width < 500 ? 0 : 2}>
            <Select
              // rules={STAKEHOLDER}
              label={stackHolderDepartmentLabel}
              name="stakeholder_departments"
              className="select-category"
              placeholder="Select Stakeholder departments"
              mode="multiple"
              showSearch="false"
              aria-readonly={!activeStakeholder}
              onDropdownVisibleChange={() => setActiveStakeholder(false)}
              open={activeStakeholder}
              options={stackHolderListOptions}
              disabledOptionArray={[owner_departments_watch]}
            />
          </Col>
        </Row>
      </When>
      <Row className="departmentSection">
        <When condition={reviewButtonCondition}>
          <Col span={spanWidth6To3} style={{ textAlign: "start" }}>
            <Button
              {...REVIEW_BUTTON}
              onClick={() => setIsModalOpen(BUTTON_DETAILS.REVIEW)}
              icon={<EyeOutlined />}
            />
          </Col>
        </When>
        <When condition={rejectButtonCondition}>
          <Col span={spanWidth6To3} style={{ textAlign: "start" }}>
            <Button
              {...REJECT_BUTTON}
              name={isBoardB ? "Decline" : "Reject"}
              onClick={() => setIsModalOpen(BUTTON_DETAILS.REJECT)}
              icon={<StopOutlined />}
            />
          </Col>
        </When>
        <When condition={changeButtonCondition}>
          <Col span={width < 500 ? 12 : 4} style={{ textAlign: "start" }}>
            <Button
              {...CHANGE_BUTTON}
              htmlType="submit"
              onClick={() =>
                owner_departments_watch &&
                setIsModalOpen(MANAGE_BUTTON_DETAILS.CHANGE)
              }
            />
          </Col>
        </When>
        <When condition={approveButtonCondition}>
          <Col span={width < 500 ? 12 : 4} style={{ textAlign: "start" }}>
            <Button
              {...APPROVE_BUTTON}
              name={changeButtonCondition || isBoardB ? "Accept" : approveButtonTextCondition ? "Approve to Board" : "Approve"}
              htmlType={ownerStakeholderForm ? "submit" : "button"}
              onClick={() =>
                ownerStakeholderForm
                  ? owner_departments_watch &&
                    setIsModalOpen({
                      ...MANAGE_BUTTON_DETAILS.APPROVE,
                      name: changeButtonCondition ? "Accept" : "Approve",
                    })
                  : setIsModalOpen({
                      ...BUTTON_DETAILS.APPROVE,
                      name: changeButtonCondition ? "Accept" : "Approve",
                    })
              }
            />
          </Col>
          <When condition={ownerStakeholderForm}>
            <Col span={24}>
              <div className="change">
                If you don't want to become owner of this Project click on
                change Request
              </div>
            </Col>
          </When>
        </When>
        <When condition={forwardButtonCondition}>
          <Col
            span={width < 500 ? 12 : 5}
            className={
              width < 500 ? "forward-button-mobile" : "forward-button-web"
            }
          >
            <Button
              size={FORWARD_BUTTON.size(width < 500)}
              {...FORWARD_BUTTON}
              icon={<ArrowRightOutlined />}
              onClick={() =>
                owner_departments_watch &&
                setIsModalOpen(MANAGE_BUTTON_DETAILS.FORWARD)
              }
            />
          </Col>
        </When>
      </Row>
      <Row>
        <When condition={holdButtonCondition}>
          <Col
            span={width < 500 ? 6 : 4}
            className={
              width < 500
                ? "hold-button-warper-mobile"
                : "hold-button-warper-web"
            }
          >
            <Button
              size={HOLD_BUTTON.size(width < 500)}
              {...HOLD_BUTTON}
              block
              onClick={() => setIsModalOpen(BUTTON_DETAILS.HOLD)}
            ></Button>
          </Col>
        </When>
        <When condition={unHoldButtonCondition}>
          <Col
            span={width < 500 ? 6 : 4}
            className={
              width < 500
                ? "hold-button-warper-mobile"
                : "hold-button-warper-web"
            }
          >
            <Button
              size={UN_HOLD_BUTTON.size(width < 500)}
              {...UN_HOLD_BUTTON}
              block
              onClick={() => setIsModalOpen(BUTTON_DETAILS.UN_HOLD)}
            ></Button>
          </Col>
        </When>
      </Row>
    </Form>
  );
}

export default ActionContainer;
