import Search from "antd/lib/transfer/search";
import { memo } from "react";
import "./SearchInput.scss";

function SearchInput({
  placeholder,
  enterButtonText = "Search",
  onChange,
  handleClear,
  className,
}) {
  return (
    <Search
      className={className}
      prefixCls="pms-search-input"
      placeholder={placeholder}
      enterButton={enterButtonText}
      // prefix={Search_icon}
      onChange={onChange}
      handleClear={handleClear}
    />
  );
}

export default memo(SearchInput);
