import React from "react";
import { memo } from "react";
import { Navigate } from "react-router-dom";
import LayoutMain from "../components/LayoutMain/LayoutMain";
import { useAuth } from "../customHook/useAuth";

const PrivateRoute = ({ isLayout = true, isRolePage = false, children }) => {
  const { loggedIn: validUser, isRoleSelected } = useAuth();
  return validUser ? (
    isRoleSelected || isRolePage ? (
      isLayout ? (
        <LayoutMain>{children}</LayoutMain>
      ) : (
        children
      )
    ) : (
      <Navigate to="/Profile" />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default memo(PrivateRoute);
