import { LOGIN_USER, LOGOUT_USER } from "../service/types";

const initialState = {
  access_token: localStorage.getItem("access_token") || null,
  name: localStorage.getItem("name") || null,
  email: localStorage.getItem("email") || null,
  its_id: localStorage.getItem("its_id") || null,
  roles: JSON.parse(localStorage.getItem("role")) || null,
  permissions: JSON.parse(localStorage.getItem("permissions")) || null,
  jamaat: localStorage.getItem("jamaat") || null,
  departments: JSON.parse(localStorage.getItem("departments")) || null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      action &&
        action?.payload &&
        Object.keys(action?.payload)
          .filter((key) => action?.payload[key])
          .map((key) => {
            if (
              key === "roles" ||
              key === "permissions" ||
              key === "departments" ||
              key === "jamaat"
            ) {
              localStorage.setItem(key, JSON.stringify(action?.payload[key]));
            } else {
              localStorage.setItem(key, action?.payload[key]);
            }
          });
      return action.payload;
    case LOGOUT_USER:
      localStorage.clear();
      return action.payload;
    default:
      return state;
  }
}
